import { useQuery } from '@apollo/client';
import type { ErrorHandler } from '../useErrorHandler.tsx';
import type { CurrentUserPlanQuery } from './queries.generated.ts';
import { CurrentUserPlanDocument } from './queries.generated.ts';

type UserPlan = NonNullable<CurrentUserPlanQuery['me']>['plan'];

type CurrentUserPlan = {
  userPlan: UserPlan | null;
  loading: boolean;
};

export const useCurrentUserPlan = (
  planId: string,
  onError: ErrorHandler['onError'],
): CurrentUserPlan => {
  const { data, loading } = useQuery(CurrentUserPlanDocument, {
    onError,
    variables: { planId },
  });

  return {
    userPlan: data?.me?.plan ?? null,
    loading,
  };
};
