/// <reference path="./window.d.ts" />
import { ApolloProvider } from '@apollo/client';
import { DirectionProvider } from '@radix-ui/react-direction';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { trim } from 'lodash-es';
import type { FunctionComponent } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { apolloClient } from './apollo-client.ts';
import { CurrentSiteProvider } from './components/CurrentSiteProvider/CurrentSiteProvider.tsx';
import { NotFound } from './components/NotFound/NotFound.tsx';
import { Page } from './components/Page/Page.tsx';
import { PublicSitePageDocument } from './components/Page/queries.generated.ts';
import { ParseAndRenderComponents } from './components/ParseAndRenderComponents.tsx';
import { RethrowErrorBoundary } from './components/rethrow-error-boundary.tsx';
import { RootErrorBoundary } from './components/RootErrorBoundary/RootErrorBoundary.tsx';
import { SessionUserProvider } from './components/SessionUserProvider.tsx';
import { StripeElementsProvider } from './components/StripeElementsProvider.tsx';
import { ThemeProvider } from './components/theme-provider.tsx';
import { DialogProvider } from './contexts/DialogContext/DialogContext.tsx';
import { ErrorCollectorContextProvider } from './contexts/error-collector-context.tsx';
import { ProductAnalyticsContextProvider } from './contexts/ProductAnalyticsContext/ProductAnalyticsContext.tsx';
import { SessionStatusContextProvider } from './contexts/SessionStatusContext/session-status-context.tsx';
import { ToastProvider } from './contexts/ToastContext.tsx';
import { PublicSiteLayoutForPathDocument } from './queries.generated.ts';
import { previewPageTemplateBasePath } from './util/page-templates.ts';

import './css/primeflex.css';
import 'primeicons/primeicons.css';
import 'quill/dist/quill.snow.css';
import '@radix-ui/themes/styles.css';
import './css/theme.css';
import './css/index.css';

const { admin, siteId } = window.wirechunk;

// The Dialog provider needs to be rendered within the RouterProvider so that anything that's rendered within the
// dialog has access to the router context. Note that toasts do not have access to the router.
const GlobalLayout: FunctionComponent = () => (
  <DialogProvider>
    <Outlet />
  </DialogProvider>
);

// The layouts already set in the router.
const layoutsById = new Map<
  string,
  {
    id: string;
    pathPrefix: string;
    components: string;
  }
>();

const rootId = 'root';

const router = createBrowserRouter(
  [
    {
      id: rootId,
      path: '*',
      Component: GlobalLayout,
      ErrorBoundary: RethrowErrorBoundary,
      children: [
        {
          path: '*',
          Component: NotFound,
        },
      ],
    },
  ],
  {
    patchRoutesOnNavigation: async ({ path, patch }) => {
      if (admin && path.startsWith('/dashboard')) {
        const { adminRoutes } = await import('./routes/admin-dashboard.tsx');
        patch(rootId, adminRoutes);
        return;
      }
      if (path.startsWith(`/${previewPageTemplateBasePath}/`)) {
        const { previewPageTemplateRoutes } = await import('./routes/preview-page-template.ts');
        patch(rootId, previewPageTemplateRoutes);
        return;
      }
      const { data: pageData } = await apolloClient.query({
        query: PublicSitePageDocument,
        variables: { siteId, path },
      });
      const cleanedPath = trim(path, '/');
      const { page } = pageData.publicSite;
      let layout = page?.resolvedLayout;
      if (!page) {
        const { data: layoutData } = await apolloClient.query({
          query: PublicSiteLayoutForPathDocument,
          variables: { siteId, path: cleanedPath },
        });
        layout = layoutData.publicSite.layoutForPath;
      }
      const pathWithoutLayoutPrefix = layout
        ? // This second slashes trim is needed for pages that have a path following the layout prefix.
          trim(cleanedPath.slice(layout.pathPrefix.length), '/')
        : cleanedPath;
      if (layout && !layoutsById.has(layout.id)) {
        layoutsById.set(layout.id, layout);
        patch(rootId, [
          {
            id: layout.id,
            path: layout.pathPrefix ? `${layout.pathPrefix}/*` : '*',
            element: <ParseAndRenderComponents componentsJSON={layout.components} />,
            children: [
              {
                path: pathWithoutLayoutPrefix || undefined,
                index: !pathWithoutLayoutPrefix,
                Component: page ? Page : NotFound,
              },
            ],
          },
        ]);
        return;
      }
      patch(layout?.id ?? rootId, [
        {
          path: pathWithoutLayoutPrefix || undefined,
          index: !pathWithoutLayoutPrefix,
          Component: page ? Page : NotFound,
        },
      ]);
    },
  },
);

export const App: FunctionComponent = () => (
  <RootErrorBoundary>
    <HelmetProvider>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider>
          <TooltipPrimitive.Provider delayDuration={200}>
            <DirectionProvider dir="ltr">
              <SessionStatusContextProvider>
                <ErrorCollectorContextProvider>
                  <CurrentSiteProvider>
                    <StripeElementsProvider>
                      <ToastProvider>
                        <SessionUserProvider>
                          <ProductAnalyticsContextProvider>
                            <RouterProvider router={router} />
                          </ProductAnalyticsContextProvider>
                        </SessionUserProvider>
                      </ToastProvider>
                    </StripeElementsProvider>
                  </CurrentSiteProvider>
                </ErrorCollectorContextProvider>
              </SessionStatusContextProvider>
            </DirectionProvider>
          </TooltipPrimitive.Provider>
        </ThemeProvider>
      </ApolloProvider>
    </HelmetProvider>
  </RootErrorBoundary>
);
