import { useMutation } from '@apollo/client';
import { getUserTimezone } from '@wirechunk/lib/dates.ts';
import { useEffect } from 'react';
import type { ErrorHandler } from '../useErrorHandler.tsx';
import type { CreateStageMutation } from './mutations.generated.ts';
import { CreateStageDocument } from './mutations.generated.ts';

export type Stage = CreateStageMutation['createStage'];

type AutoCreateStage = {
  stage: Stage | null;
  loading: boolean;
};

export const useAutoCreateStage = (
  dateYMD: string,
  stageBlueprintId: string,
  onError: ErrorHandler['onError'],
): AutoCreateStage => {
  const [createStage, { loading, data }] = useMutation(CreateStageDocument, {
    onError,
  });

  useEffect(() => {
    void createStage({
      variables: {
        date: dateYMD,
        stageBlueprintId,
        userTimeZone: getUserTimezone(),
      },
    });
  }, [createStage, dateYMD, stageBlueprintId]);

  return {
    stage: data?.createStage ?? null,
    loading,
  };
};
