import { createContext, useContext } from 'react';

export enum ViewMode {
  Live,
  Preview,
}

export type PageContext =
  | {
      id: string;
      title: string;
      viewMode: ViewMode.Live;
    }
  | {
      title: string;
      viewMode: ViewMode.Preview;
    };

const context = createContext<PageContext>({
  id: '',
  title: '',
  viewMode: ViewMode.Live,
});

export const PageContextProvider = context.Provider;

export const usePageContext = (): PageContext => useContext(context);
