import { evaluateBooleanExpression } from '@wirechunk/lib/expression-builder/evaluator.ts';
import { conditionalStylesAttribute } from '@wirechunk/lib/mixer/styles.ts';
import type { ConditionalStyles } from '@wirechunk/lib/mixer/types/components.ts';
import { useMemo } from 'react';
import type { MixerContextData } from '../util/mixer/context-data.ts';

type ConditionalStylesAttribute = { [conditionalStylesAttribute]: string } | null;

// Returns the keys of the conditional styles that should be applied based on the context data.
// These keys are meant to be used whitespace-separated as the value of a data-conditional-styles attribute.
export const useConditionalStylesAttribute = (
  { conditionalStyles }: ConditionalStyles,
  contextData: MixerContextData,
): ConditionalStylesAttribute =>
  useMemo(() => {
    const keys: string[] = [];
    if (conditionalStyles) {
      for (const { key, condition } of conditionalStyles) {
        if (evaluateBooleanExpression(condition, contextData)) {
          keys.push(key);
        }
      }
    }
    return keys.length ? { [conditionalStylesAttribute]: keys.join(' ') } : null;
  }, [conditionalStyles, contextData]);
