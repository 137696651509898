import type { FunctionComponent } from 'react';
import { Fragment } from 'react';
import { useDialog } from '../../../contexts/DialogContext/DialogContext.tsx';
import type { SiteContext } from '../../../contexts/SiteContext/SiteContext.tsx';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { Spinner } from '../../spinner/spinner.tsx';
import { TemplateRow } from './TemplateRow.tsx';
import { usePagesData } from './usePagesData.ts';
import { usePageTemplatesData } from './usePageTemplatesData.ts';

type PageTemplatesViewProps = {
  site: SiteContext;
};

export const PageTemplatesView: FunctionComponent<PageTemplatesViewProps> = ({ site }) => {
  const dialog = useDialog();
  const { onError, ErrorMessage } = useErrorHandler();
  const { pageTemplates, loading, refetchPageTemplates } = usePageTemplatesData(
    site.platformId,
    site.id,
    onError,
  );
  const { refetchPages } = usePagesData(site.id, onError);

  return (
    <Fragment>
      <ErrorMessage />
      {loading && <Spinner py="3" />}
      {pageTemplates?.map((tmpl) => (
        <div key={tmpl.id} className="border-bottom-1 py-3 px-2">
          <TemplateRow
            site={site}
            pageTemplate={tmpl}
            onError={onError}
            onActivated={() => {
              void refetchPageTemplates();
              void refetchPages();
              dialog(null);
            }}
          />
        </div>
      ))}
    </Fragment>
  );
};
