import { Button } from '@radix-ui/themes';
import { booleanOrDefaultFalse } from '@wirechunk/lib/booleans.ts';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { CompletableVideoInputComponent } from '@wirechunk/lib/mixer/types/components.ts';
import type { ValidInputComponent } from '@wirechunk/lib/mixer/utils.ts';
import { SvgRadioButtonUnchecked } from '@wirechunk/material-symbols-react-400/20/outlined/radio-button-unchecked.tsx';
import { SvgTaskAlt } from '@wirechunk/material-symbols-react-400/20/outlined/task-alt.tsx';
import { clsx } from 'clsx';
import type { FunctionComponent } from 'react';
import { Fragment, useEffect, useState } from 'react';
import { useInputDataContext } from '../../../contexts/InputDataContext.tsx';
import { Play } from '../../icons/Play.tsx';
import { withValidInputComponent } from '../../mixer-hocs/with-valid-input-component.tsx';
import styles from './CompletableVideoInput.module.css';

const GuardedCompletableVideoInput: FunctionComponent<
  ValidInputComponent<CompletableVideoInputComponent>
> = (props) => {
  const { getValue, setValue } = useInputDataContext(props);
  const inputValue = booleanOrDefaultFalse(getValue(props));
  const [expanded, setExpanded] = useState(
    !props.initiallyCollapsed && (!props.collapseCompleted || !inputValue),
  );

  useEffect(() => {
    if (props.collapseCompleted && inputValue) {
      setExpanded(false);
    }
  }, [inputValue, props.collapseCompleted]);

  if (!props.src) {
    return null;
  }

  return (
    <div className={clsx(componentClassName(props), 'border-1 border-round overflow-hidden')}>
      {expanded ? (
        <Fragment>
          {props.label && props.showHeader && (
            <div
              className={clsx(
                styles.banner,
                'flex align-items-center surface-ground font-medium px-3',
                props.collapseCompleted && 'cursor-pointer',
              )}
              role={props.collapseCompleted ? 'button' : undefined}
              onClick={() => {
                if (props.collapseCompleted) {
                  setExpanded(false);
                }
              }}
            >
              {props.label}
            </div>
          )}
          <div className="video-embed-container ">
            <iframe src={props.src} allowFullScreen />
          </div>
          <div
            className={`${styles.banner} flex align-items-center surface-ground overflow-hidden px-2`}
          >
            <Button
              variant="soft"
              highContrast
              color="gray"
              onClick={() => {
                setValue(props, !inputValue);
              }}
            >
              {inputValue ? <SvgTaskAlt className="fill-green-9" /> : <SvgRadioButtonUnchecked />}
              <span>{inputValue ? 'Completed' : 'Mark complete'}</span>
            </Button>
          </div>
        </Fragment>
      ) : (
        <div className={`${styles.banner} flex gap-2 align-items-stretch surface-ground`}>
          <button
            type="button"
            className="w-full flex gap-2 align-items-center button-not-styled px-3"
            onClick={() => {
              setExpanded(true);
            }}
          >
            <Play width="9.6" height="10.8" />
            <span className={props.label ? undefined : 'font-medium'}>Watch</span>
            {props.label && <span className="font-medium">{props.label}</span>}
            {inputValue && <SvgTaskAlt className="fill-green-9 ml-1" />}
          </button>
        </div>
      )}
    </div>
  );
};

export const CompletableVideoInput = withValidInputComponent<CompletableVideoInputComponent>(
  GuardedCompletableVideoInput,
);
