import { createContext, useContext } from 'react';

export type FormContext = {
  id: string;
  currentStep: {
    id: string;
    components: string;
  } | null;
  confirmationMessageComponents: string | null | undefined;
  submitting: boolean;
  submitted: boolean;
};

const context = createContext<FormContext | null>(null);

export const FormContextProvider = context.Provider;

export const useFormContext = (): FormContext | null => useContext(context);
