import { Box, Flex, Text } from '@radix-ui/themes';
import { SvgChevronRight } from '@wirechunk/material-symbols-react-400/20/outlined/chevron-right.tsx';
import type { FunctionComponent } from 'react';
import { Fragment } from 'react';
import { defaultEmptyLessonNotes } from './course.tsx';
import type { CourseForUserQuery } from './queries.generated.ts';

const lessonHasNotes = (lesson: { lessonUser?: { notes?: string | null } | null }): boolean =>
  !!lesson.lessonUser?.notes;

type CourseUserNotesProps = {
  course: CourseForUserQuery['course'];
};

export const CourseUserNotes: FunctionComponent<CourseUserNotesProps> = ({ course }) => {
  const hasNotes = course.lessons.lessons.some(
    (l) => lessonHasNotes(l) || l.subLessons.lessons.some(lessonHasNotes),
  );

  return (
    <Flex direction="column" gap="2">
      {hasNotes ? (
        course.lessons.lessons.map((lesson) => {
          const notes = lesson.lessonUser?.notes;
          return (
            <Fragment key={lesson.id}>
              {notes ? (
                <Flex direction="column" className="border-1 border-round">
                  <div className="font-medium border-bottom-1 px-3 py-2">{lesson.title}</div>
                  <div className="px-3 py-2 overflow-x-hidden white-space-pre-line">{notes}</div>
                </Flex>
              ) : null}
              {lesson.subLessons.lessons.map((subLesson) => {
                const subNotes = subLesson.lessonUser?.notes;
                return (
                  <Fragment key={subLesson.id}>
                    {subNotes ? (
                      <Flex direction="column" className="border-1 border-round">
                        <Box px="3" py="2" className="border-bottom-1">
                          <Text size="2" weight="medium">
                            {lesson.title}
                          </Text>{' '}
                          <SvgChevronRight style={{ marginBottom: '-4px' }} />{' '}
                          <Text size="2" weight="medium">
                            {subLesson.title}
                          </Text>
                        </Box>
                        <Flex px="3" py="2">
                          <Text className="overflow-x-hidden white-space-pre-line">{subNotes}</Text>
                        </Flex>
                      </Flex>
                    ) : null}
                  </Fragment>
                );
              })}
            </Fragment>
          );
        })
      ) : (
        <div>{course.emptyNotesMessage || defaultEmptyLessonNotes}</div>
      )}
    </Flex>
  );
};
