import { useMutation } from '@apollo/client';
import { Role } from '@wirechunk/lib/api.ts';
import { SubscriptionTag } from '@wirechunk/lib/subscription-tag.ts';
import { clsx } from 'clsx';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import type { FunctionComponent } from 'react';
import { Fragment, useState } from 'react';
import { useCurrentUser } from '../../../contexts/CurrentUserContext/CurrentUserContext.tsx';
import { useSiteContext } from '../../../contexts/SiteContext/SiteContext.tsx';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { roleOptions } from '../../../util/roles.ts';
import { ActionCardTitle } from '../../admin/action-card-title.tsx';
import { CreateOrganizationMemberDocument } from './mutations.generated.ts';

type AddMemberProps = {
  className?: string;
  onAdded?: () => void;
  onCancel?: () => void;
};

export const AddMember: FunctionComponent<AddMemberProps> = ({ className, onAdded, onCancel }) => {
  const { platformId } = useSiteContext();
  const {
    user: { productItems },
  } = useCurrentUser();
  const { onError, ErrorMessage } = useErrorHandler();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState<Role>(Role.TeamMember);
  const [addMember, { loading: isSubmitting }] = useMutation(CreateOrganizationMemberDocument, {
    onError,
    onCompleted: onAdded,
  });

  const onAdd = () => {
    void addMember({
      variables: {
        platformId,
        email,
        firstName,
        lastName,
        role,
      },
    });
  };

  return (
    <div className={clsx('border-1 border-round p-3', className)}>
      <ActionCardTitle>Add a Team Member</ActionCardTitle>
      <ErrorMessage />
      <div className="block md:flex flex-column md:flex-row gap-4">
        <div className="input-field md:w-6">
          <label htmlFor="firstName">First name</label>
          <InputText
            id="firstName"
            className="w-full"
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />
        </div>
        <div className="input-field md:w-6">
          <label htmlFor="lastName">Last name</label>
          <InputText
            id="lastName"
            className="w-full"
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="block md:flex flex-column md:flex-row gap-4">
        <div className="input-field md:w-6">
          <label htmlFor="email">Email address (must be a company email address)</label>
          <InputText
            id="email"
            className="w-full"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div className="input-field md:w-6">
          {/* Note the parent div should always be rendered for the w-6 space. */}
          {(productItems.includes(SubscriptionTag.ReserveAgentRole) ||
            productItems.includes(SubscriptionTag.ProtegeRole)) && (
            <Fragment>
              <label htmlFor="addMemberRole">Role</label>
              <Dropdown
                inputId="addMemberRole"
                className="w-15rem max-w-full"
                options={roleOptions.filter(
                  ({ value }) =>
                    value !== Role.OrganizationOwner &&
                    (value !== Role.ReserveAgent ||
                      productItems.includes(SubscriptionTag.ReserveAgentRole)) &&
                    (value !== Role.Protege || productItems.includes(SubscriptionTag.ProtegeRole)),
                )}
                value={role}
                onChange={(e) => {
                  setRole(e.value as Role);
                }}
              />
            </Fragment>
          )}
        </div>
      </div>
      <div className="flex gap-3 mt-3 md:mt-2">
        <Button
          label="Add"
          disabled={!firstName.trim() || !lastName.trim() || !email.trim() || isSubmitting}
          onClick={onAdd}
        />
        <Button
          className="p-button-outlined"
          label="Cancel"
          disabled={isSubmitting}
          onClick={onCancel}
        />
      </div>
    </div>
  );
};
