import type { Role } from '@wirechunk/lib/api.ts';
import {
  CurrentTimeKey,
  UserEmailKey,
  UserProductItemsKey,
  UserRoleKey,
} from '@wirechunk/lib/expression-builder/evaluator.ts';
import type { CurrentUser } from '../../../contexts/CurrentUserContext/CurrentUserContext.tsx';

export type OrderFormContextData = {
  [CurrentTimeKey]: number;
  // The following user properties (user email, user product items, etc.) will be set if a user is signed in.
  [UserEmailKey]: string | null;
  [UserProductItemsKey]: string[] | null;
  [UserRoleKey]: Role | null;
};

export const buildContextData = ({
  // Destructure to closely control which fields are included in the context data.
  user,
}: {
  user: CurrentUser | null;
}): OrderFormContextData => ({
  [CurrentTimeKey]: Date.now(),
  [UserEmailKey]: user?.email ?? null,
  [UserProductItemsKey]: user?.productItems ?? null,
  [UserRoleKey]: user?.role ?? null,
});
