// TODO: Extract all fields from the form to take the label, not name, of each field.
export const extractFieldsFromEntries = (
  rows: Array<{ parsedFormData: Record<string, unknown> }>,
  rowsSample: number = 20,
  columns: number = 15,
): Array<{ label: string; name: string }> => {
  // fields is a mapping from field name to label.
  const fields = new Map<string, string>();
  rows.slice(0, rowsSample).forEach((row) => {
    Object.keys(row.parsedFormData).forEach((key) => {
      if (!fields.has(key)) {
        fields.set(key, key);
      }
    });
  });

  return Array.from(fields.entries())
    .slice(0, columns)
    .map(([name, label]) => ({ label, name }));
};
