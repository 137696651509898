import { ApolloError } from '@apollo/client';
import { ApiErrorCode } from '@wirechunk/lib/api-error-code.ts';
import type { Error as ApiError } from '@wirechunk/lib/api.ts';
import { genericErrorMessage, parseErrorMessage } from '@wirechunk/lib/errors.ts';
import type { GraphQLFormattedError } from 'graphql';
import { ignoredRequestCanceledError } from '../apollo-client.ts';
import type { SessionStatusContext } from '../contexts/SessionStatusContext/session-status-context.tsx';
import { SessionStatus } from '../contexts/SessionStatusContext/session-status-context.tsx';

// apolloErrorsMessage handles an array of GraphQL errors, not network errors.
const apolloErrorsMessage = (errors: readonly GraphQLFormattedError[]): string =>
  errors.map((err) => err.message).join('\n');

// Parses an error message, including checking if it's an ApolloError, and returns a string.
export const parseWebErrorMessage = (err: unknown): string => {
  const message = parseErrorMessage(err);
  if (message === genericErrorMessage) {
    if (err instanceof ApolloError) {
      if (err.graphQLErrors.length) {
        const message = apolloErrorsMessage(err.graphQLErrors);
        if (message) {
          return message;
        }
      }
      if (err.networkError?.message) {
        return err.networkError.message;
      }
      if (err.message) {
        return err.message;
      }
    }
  }

  return message;
};

// isOfflineError returns true if and only if the error we got signals that we are offline. Regardless of
// the error, this function will return true only if the user is currently offline.
const isOfflineError = (error: ApolloError | Error | ApiError | string): boolean => {
  if (import.meta.env.SSR || window.navigator.onLine) {
    return false;
  }
  if (error instanceof ApolloError && error.networkError) {
    return true;
  }
  return error instanceof Error && error.message === 'Failed to fetch';
};

const isUnauthenticatedError = (error: ApolloError | Error | ApiError | string): boolean => {
  if (error instanceof ApolloError) {
    return error.graphQLErrors.some((e) => e.extensions?.code === ApiErrorCode.Unauthenticated);
  }
  return false;
};

// Handles offline errors by showing a toast. Returns true if the error was an offline error, or false otherwise.
export const handleOfflineError = (
  error: ApolloError | Error | ApiError | string,
  showErrorToast: (message: string, heading?: string) => void,
): boolean => {
  if (isOfflineError(error)) {
    showErrorToast('It appears that you are offline.', 'Network error');
    return true;
  }
  return false;
};

// Returns true if the error was an ignored request canceled error, or false otherwise.
// Note that this is a proper error "handler" in that it doesn't do anything by design.
export const handleIgnoredRequestCanceledError = (
  error: ApolloError | ApiError | Error | string,
): boolean => error instanceof ApolloError && error.networkError === ignoredRequestCanceledError;

export const handleUnauthenticatedError = (
  error: ApolloError | Error | ApiError | string,
  sessionContext: SessionStatusContext,
): boolean => {
  if (isUnauthenticatedError(error)) {
    if (sessionContext.status === SessionStatus.Active) {
      sessionContext.setStatus(SessionStatus.Expired);
      sessionContext.setErrorMessage('Your session has expired. Please sign in again.');
    } else {
      let message: string = '';
      // Try to parse out the precise error message returned by the server.
      if (error instanceof ApolloError) {
        if (error.graphQLErrors.length) {
          message = apolloErrorsMessage(error.graphQLErrors);
        } else {
          message = error.message;
        }
      }
      message ||= 'You need to sign in to continue.';
      sessionContext.setStatus(SessionStatus.SignedOut);
      sessionContext.setErrorMessage(message);
    }
    return true;
  }
  return false;
};

// Applies the known error handlers and returns true if and only if the error has been handled (and can therefore be ignored).
export const handleKnownError = (
  error: ApolloError | Error | ApiError | string,
  sessionContext: SessionStatusContext,
  showErrorToast: (message: string, heading?: string) => void,
): boolean => {
  return (
    handleIgnoredRequestCanceledError(error) ||
    handleOfflineError(error, showErrorToast) ||
    handleUnauthenticatedError(error, sessionContext)
  );
};
