import { z } from 'zod';

const plainDateSchema = z.object({
  month: z.number().int().positive().max(12),
  day: z.number().int().positive().max(31),
  year: z.number().int().positive().max(9999),
});

export type PlainDate = z.infer<typeof plainDateSchema>;

export type WorkingPlainDate = {
  [P in keyof PlainDate]: PlainDate[P] | null;
};

const plainTimeSchema = z.object({
  hour: z.number().int().nonnegative().min(0).max(23),
  minute: z.number().int().nonnegative().min(0).max(59),
  second: z.number().int().nonnegative().min(0).max(59),
});

export type PlainTime = z.infer<typeof plainTimeSchema>;

const plainDateTimeSchema = z.object({
  date: plainDateSchema,
  time: plainTimeSchema,
});

export type PlainDateTime = z.infer<typeof plainDateTimeSchema>;

export type WorkingPlainDateTime = {
  [P in keyof PlainDateTime]: PlainDateTime[P] | null;
};

export const parsePlainDate = (value: unknown): z.SafeParseReturnType<unknown, PlainDate> =>
  plainDateSchema.safeParse(value);

export const parsePlainTime = (value: unknown): z.SafeParseReturnType<unknown, PlainTime> =>
  plainTimeSchema.safeParse(value);

export const parsePlainDateTime = (value: unknown): z.SafeParseReturnType<unknown, PlainDateTime> =>
  plainDateTimeSchema.safeParse(value);
