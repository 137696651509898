import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { OrganizationAgencyAddressStateComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { AddressStateTransform } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';
import { useSiteContext } from '../../contexts/SiteContext/SiteContext.tsx';
import { stateAbbreviationToFull } from '../../util/states.ts';

export const OrganizationAgencyAddressState: FunctionComponent<
  OrganizationAgencyAddressStateComponent
> = (props) => {
  const { addressState } = useSiteContext();

  let state = addressState || '';
  if (props.transform === AddressStateTransform.Full) {
    state = stateAbbreviationToFull(state);
  }

  return <span className={componentClassName(props)}>{state}</span>;
};
