/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.ts';

export type PagesQueryVariables = Types.Exact<{
  siteId: Types.Scalars['String']['input'];
}>;

export type PagesQuery = {
  site: {
    __typename: 'Site';
    id: string;
    pages: Array<{
      __typename: 'Page';
      id: string;
      path: string;
      title: string;
      metaTitle?: string | null;
      status: Types.PublishStatus;
      components: string;
      bodyStyles: string;
      props?: string | null;
      template?: {
        __typename: 'PageTemplate';
        id: string;
        description?: string | null;
        propsSetupComponents?: string | null;
      } | null;
    }>;
  };
};

export type PageTemplatesQueryVariables = Types.Exact<{
  platformId: Types.Scalars['String']['input'];
  siteId: Types.Scalars['String']['input'];
}>;

export type PageTemplatesQuery = {
  pageTemplates: Array<{
    __typename: 'PageTemplate';
    id: string;
    path: string;
    title: string;
    status: Types.PublishStatus;
    previewImageUrl?: string | null;
    description?: string | null;
    propsSetupComponents?: string | null;
    activatedAsPages: Array<{ __typename: 'Page'; id: string }>;
  }>;
};

export const PagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Pages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'siteId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'site' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'siteId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pages' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'specialPurposes' },
                      value: { kind: 'EnumValue', value: 'None' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'template' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'propsSetupComponents' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'metaTitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'components' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bodyStyles' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'props' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PagesQuery, PagesQueryVariables>;
export const PageTemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PageTemplates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'platformId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'siteId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageTemplates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'platformId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'platformId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'EnumValue', value: 'Published' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'specialPurpose' },
                value: { kind: 'EnumValue', value: 'None' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activatedAsPages' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'siteId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'siteId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'previewImageUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'propsSetupComponents' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PageTemplatesQuery, PageTemplatesQueryVariables>;
