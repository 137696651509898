import { renderStyles } from '@wirechunk/lib/mixer/styles.ts';
import { isStyles } from '@wirechunk/lib/mixer/utils.ts';
import type { FunctionComponent } from 'react';
import { Fragment, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { ParseAndRenderComponents } from './ParseAndRenderComponents.tsx';

type ParseAndRenderPageProps = {
  componentsJSON: string | undefined;
  bodyStylesJSON: string | undefined;
};

export const ParseAndRenderPage: FunctionComponent<ParseAndRenderPageProps> = ({
  componentsJSON,
  bodyStylesJSON,
}) => {
  const bodyCss = useMemo<string | null>(() => {
    if (bodyStylesJSON) {
      try {
        const bodyStyles = JSON.parse(bodyStylesJSON) as unknown;
        if (isStyles(bodyStyles)) {
          return renderStyles('body', bodyStyles);
        }
      } catch {
        // Todo: log this
      }
    }
    return null;
  }, [bodyStylesJSON]);

  return (
    <Fragment>
      {bodyCss && (
        <Helmet>
          <style>{bodyCss}</style>
        </Helmet>
      )}
      <ParseAndRenderComponents componentsJSON={componentsJSON} />
    </Fragment>
  );
};
