import type { FunctionComponent, SVGProps } from 'react';

type CircleProgressHalfProps = SVGProps<SVGSVGElement> & {
  fill1?: string;
  fill2?: string;
};

export const CircleProgressHalf: FunctionComponent<CircleProgressHalfProps> = ({
  fill1,
  fill2,
  ...props
}) => (
  <svg
    width="20"
    height="20"
    version="1.1"
    viewBox="0 -960 960 960"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill={fill1}
      d="m480-864v72c86.6666 0 160.302 30.3646 220.969 91.0312s91.0312 134.302 91.0312 220.969-30.3646 160.302-91.0312 220.969-134.302 91.0312-220.969 91.0312v72c0.09431 6.4e-5 0.18692 0 0.28125 0 52.8466 0 102.482-10 148.969-30 46.48-20 87.25-47.5 122.25-82.5s62.5-75.8646 82.5-122.531 30-96.3967 30-149.25c0-52.8466-10-102.482-30-148.969-20-46.48-47.5-87.25-82.5-122.25s-75.6355-62.5-121.969-82.5-96.0359-30-149.062-30c-0.15698 0-0.31182-1.8e-4 -0.46875 0z"
    />
    <path
      fill={fill2}
      d="m480-864v72c-86.6666 0-160.302 30.3647-220.969 91.0313-60.6666 60.6666-91.0312 134.302-91.0312 220.969s30.3646 160.302 91.0312 220.969 134.302 91.0312 220.969 91.0312v72c-0.0943 6e-5 -0.18692 0-0.28125 0-52.8466 0-102.482-10-148.969-30-46.48-20-87.25-47.5-122.25-82.5s-62.5-75.8646-82.5-122.531-30-96.3967-30-149.25c0-52.8466 10-102.482 30-148.969 20-46.48 47.5-87.25 82.5-122.25 35-34.9999 75.6355-62.5 121.969-82.5 46.3333-19.9999 96.0359-30 149.062-30 0.15698 0 0.31182-1e-4 0.46875 0z"
    />
  </svg>
);
