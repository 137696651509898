import { IconButton } from '@radix-ui/themes';
import type { FunctionComponent, MouseEventHandler, PropsWithChildren } from 'react';

// Pass in either icon or children.
type BasicIconButtonProps = PropsWithChildren<{
  icon?: string;
  className?: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}>;

export const BasicIconButton: FunctionComponent<BasicIconButtonProps> = ({
  icon,
  className,
  onClick,
  disabled,
  children,
}) => (
  <IconButton
    variant="ghost"
    color="gray"
    className={className}
    onClick={onClick}
    disabled={disabled || false}
  >
    {icon ? <i className={icon} /> : children}
  </IconButton>
);
