import { booleanOrDefaultFalse } from '@wirechunk/lib/booleans.ts';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { BooleanInputComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { MixerEventType } from '@wirechunk/lib/mixer/types/components.ts';
import type { ValidInputComponent } from '@wirechunk/lib/mixer/utils.ts';
import type { CheckboxChangeEvent } from 'primereact/checkbox';
import { Checkbox } from 'primereact/checkbox';
import type { FunctionComponent } from 'react';
import { useInputDataContext } from '../../../contexts/InputDataContext.tsx';
import { useInputId } from '../../../hooks/use-input-id.ts';
import type { MixerEventDetailForType } from '../../../util/mixer/events';
import { mixerQualifiedEventType } from '../../../util/mixer/events';
import { InputNotice, NoticeSeverity } from '../../InputNotice/InputNotice.tsx';
import { withValidInputComponent } from '../../mixer-hocs/with-valid-input-component.tsx';

export const GuardedBooleanInput: FunctionComponent<ValidInputComponent<BooleanInputComponent>> = (
  props,
) => {
  const { getValue, setValue, getValidationError } = useInputDataContext(props);
  const inputId = useInputId(props);

  const validationError = getValidationError(props);

  const inputValue = booleanOrDefaultFalse(getValue(props));

  const handleChange = (event: CheckboxChangeEvent) => {
    const value = !!event.checked;
    setValue(props, value);
    const mixerEvent = new CustomEvent<MixerEventDetailForType<MixerEventType.InputChange>>(
      mixerQualifiedEventType(MixerEventType.InputChange),
      {
        detail: {
          type: MixerEventType.InputChange,
          component: props,
          value,
        },
      },
    );
    document.dispatchEvent(mixerEvent);
  };

  return (
    <div className={componentClassName(props)}>
      <div className="flex align-items-center gap-3">
        <Checkbox inputId={inputId} checked={inputValue} onChange={handleChange} />
        {props.label && (
          <div className="flex flex-row gap-2">
            <label htmlFor={inputId} className="input-label">
              {props.label}
            </label>
          </div>
        )}
      </div>
      {validationError && (
        <InputNotice severity={NoticeSeverity.Error}>{validationError}</InputNotice>
      )}
    </div>
  );
};

export const BooleanInput = withValidInputComponent<BooleanInputComponent>(GuardedBooleanInput);
