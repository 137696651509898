export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    ApplyCustomPromoCodeResult: ['ApplyCustomPromoCodeSuccessResult', 'GenericInternalError'],
    ArchiveComponentResult: ['ArchiveComponentSuccessResult', 'GenericUserError'],
    CourseVersionOrLessonVersion: ['CourseVersion', 'LessonVersion'],
    CreateComponentResult: ['CreateComponentSuccessResult', 'GenericUserError'],
    CreateCourseResult: ['CreateCourseSuccessResult', 'GenericUserError'],
    CreateExtensionResult: [
      'CreateExtensionSuccessResult',
      'GenericInternalError',
      'GenericUserError',
    ],
    CreateExtensionVersionResult: [
      'CreateExtensionVersionSuccessResult',
      'GenericInternalError',
      'GenericUserError',
    ],
    CreateLayoutResult: ['CreateLayoutSuccessResult', 'GenericUserError'],
    CreateLessonResult: ['CreateLessonSuccessResult', 'GenericUserError'],
    CreateLessonUserResult: ['CreateLessonUserSuccessResult', 'GenericUserError'],
    CreateProductResult: ['CreateProductSuccessResult', 'GenericUserError'],
    CreateSequenceResult: ['CreateSequenceSuccessResult', 'GenericUserError'],
    CreateSiteResult: [
      'AuthorizationError',
      'CreateSiteSuccessResult',
      'GenericInternalError',
      'GenericUserError',
    ],
    DuplicateComponentResult: [
      'DuplicateComponentSuccessResult',
      'GenericInternalError',
      'GenericUserError',
    ],
    DuplicateCourseResult: ['DuplicateCourseSuccessResult', 'GenericUserError'],
    DuplicateLessonResult: ['DuplicateLessonSuccessResult', 'GenericUserError'],
    DuplicateSequenceResult: ['DuplicateSequenceSuccessResult', 'GenericUserError'],
    EditComponentResult: ['EditComponentSuccessResult', 'GenericUserError'],
    EditCourseResult: ['EditCourseSuccessResult', 'GenericUserError'],
    EditCustomTableResult: ['EditCustomTableSuccessResult', 'GenericUserError'],
    EditExtensionResult: ['AuthorizationError', 'EditExtensionSuccessResult', 'GenericUserError'],
    EditFormStepPositionResult: [
      'AuthorizationError',
      'EditFormStepPositionSuccessResult',
      'GenericInternalError',
      'GenericUserError',
    ],
    EditFormTemplateEmailTemplateResult: [
      'EditFormTemplateEmailTemplateSuccessResult',
      'GenericUserError',
    ],
    EditFormTemplateFormattedDataTemplateResult: [
      'EditFormTemplateFormattedDataTemplateSuccessResult',
      'GenericUserError',
    ],
    EditLayoutResult: ['EditLayoutSuccessResult', 'GenericUserError'],
    EditLessonResult: ['EditLessonSuccessResult', 'GenericUserError'],
    EditLessonUserResult: ['EditLessonUserSuccessResult', 'GenericUserError'],
    EditOrgPrimaryUserResult: ['EditOrgPrimaryUserSuccessResult', 'GenericUserError'],
    EditPageTemplateResult: ['EditPageTemplateSuccessResult', 'GenericUserError'],
    EditPlatformResult: ['EditPlatformSuccessResult', 'GenericUserError'],
    EditProductResult: ['EditProductSuccessResult', 'GenericUserError'],
    EditSequenceResult: ['EditSequenceSuccessResult', 'GenericUserError'],
    EditSiteResult: [
      'AuthorizationError',
      'EditSiteSuccessResult',
      'GenericInternalError',
      'GenericUserError',
    ],
    EditUserResult: ['EditUserSuccessResult', 'GenericUserError'],
    Error: ['AuthorizationError', 'GenericInternalError', 'GenericUserError'],
    FindOrCreateStripeSubscriptionResult: [
      'FindOrCreateStripeSubscriptionActiveResult',
      'FindOrCreateStripeSubscriptionErrorResult',
      'FindOrCreateStripeSubscriptionIncompleteResult',
      'FindOrCreateStripeSubscriptionUpdatedResult',
    ],
    InternalError: ['GenericInternalError'],
    ListResult: [
      'ActiveCampaignApiTagsList',
      'ApiTokensList',
      'ComponentsList',
      'CourseAndLessonLocationVersionsList',
      'CourseVersionsList',
      'CoursesList',
      'CustomFieldValuesList',
      'CustomFieldVersionsList',
      'CustomFieldsList',
      'CustomTableVersionFieldsList',
      'CustomTableVersionsList',
      'CustomTablesList',
      'DocumentsList',
      'ExtensionVersionsList',
      'ExtensionsList',
      'FormEntriesList',
      'LayoutsList',
      'LessonVersionsList',
      'LessonsList',
      'OrganizationsList',
      'PagePurposesList',
      'PageVersionsList',
      'PagesList',
      'ProductItemPicklist',
      'ProductsList',
      'PromoCodePicklist',
      'ReviewsList',
      'SequencesList',
      'SitesList',
      'SubscriptionAuditLogsList',
      'SubscriptionsList',
      'UserAuditLogsList',
      'UserCourseProgressLessonsList',
      'UserCourseProgressesList',
      'UsersList',
    ],
    MoveUserToOrgResult: ['GenericUserError', 'MoveUserToOrgSuccessResult'],
    MutationResult: ['CreateApiTokenResult', 'InvalidateApiTokenResult'],
    PreviewStripeUpcomingInvoiceResult: [
      'PreviewStripeUpcomingInvoiceErrorResult',
      'PreviewStripeUpcomingInvoiceSuccessResult',
    ],
    RestoreCourseVersionChangesResult: [
      'GenericInternalError',
      'GenericUserError',
      'RestoreCourseVersionChangesSuccessResult',
    ],
    RestoreLessonVersionChangesResult: [
      'GenericInternalError',
      'GenericUserError',
      'RestoreLessonVersionChangesSuccessResult',
    ],
    RestorePageOptionVersionResult: ['GenericUserError', 'RestorePageOptionVersionSuccessResult'],
    SplitUserToNewOrgResult: ['GenericUserError', 'SplitUserToNewOrgSuccessResult'],
    SyncPageTemplateToPagesResult: ['GenericUserError', 'SyncPageTemplateToPagesSuccessResult'],
    UserError: ['AuthorizationError', 'GenericUserError'],
  },
};
export default result;
