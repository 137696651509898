import { useLazyQuery } from '@apollo/client';
import { FileStatus, FileUploadFeature } from '@wirechunk/lib/api.ts';
import { formatDateTime } from '@wirechunk/lib/dates.ts';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { StageFileInputComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { FileTypeCategory } from '@wirechunk/lib/mixer/types/components.ts';
import type { ValidInputComponent } from '@wirechunk/lib/mixer/utils.ts';
import { isUploadedFile } from '@wirechunk/lib/mixer/utils.ts';
import { Button } from 'primereact/button';
import type { FunctionComponent } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useInputDataContext } from '../../../contexts/InputDataContext.tsx';
import { useStageContext } from '../../../contexts/StageContext/StageContext.ts';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { useFileDownload } from '../../../hooks/useFileDownload.ts';
import { useFileGetUrl } from '../../../hooks/useFileGetUrl/useFileGetUrl.ts';
import { usePollFileStatus } from '../../../hooks/usePollFileStatus/usePollFileStatus.ts';
import type { UploadVariables } from '../../../hooks/useUploadFile/useUploadFile.ts';
import {
  fileUploadTimeoutMs,
  isAudioMimeType,
  isVideoMimeType,
  mimeTypeToFileTypeCategory,
} from '../../../util/inputs.ts';
import { FileUpload } from '../../FileUpload/FileUpload.tsx';
import { Play } from '../../icons/Play.tsx';
import { withValidInputComponent } from '../../mixer-hocs/with-valid-input-component.tsx';
import { Spinner } from '../../spinner/spinner.tsx';
import { FileDetailsDocument } from './queries.generated.ts';

const defaultAllowedTypes: FileTypeCategory[] = Object.values(FileTypeCategory);

const GuardedStageFileInput: FunctionComponent<ValidInputComponent<StageFileInputComponent>> = (
  props,
) => {
  const stageContext = useStageContext();
  const { getValue } = useInputDataContext(props);
  const { onError, ErrorMessage } = useErrorHandler();
  const { getUrl, isLoading: isGettingUrl } = useFileGetUrl(onError);
  const { download } = useFileDownload();
  const { startPollingFile, isPolling } = usePollFileStatus(onError, fileUploadTimeoutMs);
  const [getFileDetails, { data: fileDetailsData, loading: isLoadingFileDetails }] = useLazyQuery(
    FileDetailsDocument,
    {
      onError,
    },
  );
  const [showUploadInput, setShowUploadInput] = useState(true);
  const [showPlayer, setShowPlayer] = useState(false);
  const [playerFileUrl, setPlayerFileUrl] = useState<string | null>(null);

  const value = getValue(props);
  const fileId = isUploadedFile(value) ? value.fileId : null;

  const file = stageContext?.files.find((f) => f.id === fileId);

  useEffect(() => {
    if (file) {
      if (
        (file.status === FileStatus.Uploaded && !fileDetailsData && !isLoadingFileDetails) ||
        (fileDetailsData && file.id !== fileDetailsData.file.id)
      ) {
        if (file.status === FileStatus.Uploaded) {
          setShowUploadInput(false);
        }
        setPlayerFileUrl(null);
        void getFileDetails({ variables: { id: file.id } });
      } else if (file.status === FileStatus.Uploading) {
        startPollingFile(file.id, (status) => {
          if (status === FileStatus.Uploaded) {
            setShowUploadInput(false);
            void getFileDetails({ variables: { id: file.id } });
          }
        });
      }
    }
  }, [file, startPollingFile, getFileDetails, fileDetailsData, isLoadingFileDetails]);

  const uploadVariables = useMemo<UploadVariables | null>(
    () =>
      stageContext?.id
        ? {
            feature: FileUploadFeature.StageState,
            stageId: stageContext.id,
            stageStateProperty: props.name,
          }
        : null,
    [stageContext?.id, props.name],
  );

  if (!uploadVariables) {
    return null;
  }

  let uploadedFileDescription: string | null = null;
  if (fileDetailsData) {
    const type = mimeTypeToFileTypeCategory(fileDetailsData.file.mimeType);
    if (type) {
      uploadedFileDescription = `${type} file`;
    } else {
      uploadedFileDescription = 'File';
    }
  }

  const isAudio = fileDetailsData && isAudioMimeType(fileDetailsData.file.mimeType);
  const isVideo = fileDetailsData && isVideoMimeType(fileDetailsData.file.mimeType);

  return (
    <div className={componentClassName(props)}>
      <ErrorMessage />
      {isPolling ? (
        <div className="flex align-items-center gap-2 mb-2">
          <i className="pi pi-spinner pi-spin" /> Uploading a file&hellip;
        </div>
      ) : isLoadingFileDetails ? (
        <Spinner py="3" />
      ) : (
        fileDetailsData && (
          <div>
            <div className="flex align-items-center gap-3">
              <div>
                {uploadedFileDescription} uploaded on{' '}
                {formatDateTime(fileDetailsData.file.createdAt)}
              </div>
              {(isAudio || isVideo) && (
                <Button
                  className="p-button-sm flex align-items-center gap-2 min-w-max w-max"
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={async () => {
                    setShowPlayer(true);
                    const url = await getUrl(fileDetailsData.file.id, false);
                    setPlayerFileUrl(url);
                  }}
                >
                  <span>Play</span>
                  <Play width="9.6" height="10.8" pathClassName="fill-white" />
                </Button>
              )}
              <Button
                label="Download"
                className="p-button-sm min-w-max w-max"
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={async () => {
                  const { file } = fileDetailsData;
                  const url = await getUrl(file.id, true);
                  download(file.id, url, file.downloadName);
                }}
              />
            </div>
            {showPlayer &&
              (playerFileUrl ? (
                isAudio ? (
                  <audio
                    autoPlay
                    controls
                    controlsList="nodownload noplaybackrate"
                    src={playerFileUrl}
                  />
                ) : (
                  <video
                    autoPlay
                    controls
                    controlsList="nodownload noplaybackrate"
                    src={playerFileUrl}
                    className="w-full mt-3"
                  />
                )
              ) : (
                isGettingUrl && <Spinner py="3" />
              ))}
          </div>
        )
      )}
      {showUploadInput ? (
        <div className={fileDetailsData ? 'mt-3' : undefined}>
          <FileUpload
            uploadVariables={uploadVariables}
            types={props.allowedTypes || defaultAllowedTypes}
            showClose={!!file}
            onClose={() => {
              setShowUploadInput(false);
            }}
          />
        </div>
      ) : (
        file && (
          <Button
            label="Replace file"
            className="p-button-sm p-button-secondary mt-3"
            onClick={() => {
              setShowUploadInput(true);
              setShowPlayer(false);
            }}
          />
        )
      )}
    </div>
  );
};

export const StageFileInput =
  withValidInputComponent<StageFileInputComponent>(GuardedStageFileInput);
