import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { PlainTextComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { DataSource } from '@wirechunk/lib/mixer/types/components.ts';
import { stringOrDefaultNull } from '@wirechunk/lib/strings.ts';
import { isString } from 'lodash-es';
import type { FunctionComponent, JSX } from 'react';
import { useMemo } from 'react';
import { usePageContext } from '../../contexts/PageContext/PageContext.ts';
import { usePropsContext } from '../../contexts/props-context.ts';

export const plainTextDefaultTag: keyof JSX.IntrinsicElements = 'span';

export const PlainText: FunctionComponent<PlainTextComponent> = (props) => {
  const pageContext = usePageContext();
  const propsContext = usePropsContext();

  const text = useMemo(() => {
    if (!props.text || isString(props.text)) {
      return props.text;
    }
    if (props.text.type === DataSource.Prop) {
      return props.text.name ? stringOrDefaultNull(propsContext[props.text.name]) : null;
    }
    if (props.text.type === 'PageTitle') {
      return pageContext.title;
    }
    return props.text.text;
  }, [props.text, propsContext, pageContext.title]);

  const Tag = (props.tag || plainTextDefaultTag) as keyof JSX.IntrinsicElements;

  return <Tag className={componentClassName(props)}>{text}</Tag>;
};
