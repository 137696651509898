import { HelpTicketStatus } from '@wirechunk/lib/api.ts';
import { HelpTicketType } from '@wirechunk/lib/help-tickets.ts';

export const helpTicketTypeToHumanReadable = (type: HelpTicketType | string): string => {
  switch (type as never) {
    case HelpTicketType.Billing:
      return 'Billing';
    case HelpTicketType.FeatureRequest:
      return 'Feature request';
    case HelpTicketType.SiteCustomCode:
      return 'Custom site code installation';
    case HelpTicketType.SiteCustomDomain:
      return 'Custom domain setup';
    case HelpTicketType.Other:
    default:
      return 'Other';
  }
};

export const helpTicketStatusOptions: Array<{
  label: string;
  value: HelpTicketStatus;
}> = Object.values(HelpTicketStatus).map((stat) => ({
  label: stat,
  value: stat,
}));
