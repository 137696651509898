import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { AppPageTilesComponent } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { usePublicSitePages } from '../../../hooks/use-public-site-pages/use-public-site-pages.tsx';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { Spinner } from '../../spinner/spinner.tsx';
import styles from './AppPageTiles.module.css';

export const defaultFeaturedImageUrl =
  'https://storage.googleapis.com/wirechunk-core-public/blue-gradient-800x450.png';

export const AppPageTiles: FunctionComponent<AppPageTilesComponent> = (props) => {
  const { onError, ErrorMessage } = useErrorHandler();
  const { pages, loading } = usePublicSitePages({
    pathPrefix: props.pathPrefix || undefined,
    onError,
  });

  return (
    <div className={componentClassName(props)}>
      <ErrorMessage />
      {loading ? (
        <Spinner py="3" />
      ) : (
        pages && (
          <div className={styles.cardsWrapper}>
            {pages.map(({ caption, featuredImageUrl, path, title }) => (
              // The overflow-hidden class is needed so that the top corners of the card are rounded.
              <div key={path} className="border-round border-1 overflow-hidden">
                {featuredImageUrl && (
                  <Link to={`/${path}`} className="flex">
                    <img
                      className="w-full"
                      alt={title}
                      src={featuredImageUrl || defaultFeaturedImageUrl}
                    />
                  </Link>
                )}
                <div className="p-4">
                  <div className="line-height-3">
                    <Link to={`/${path}`} className="text-xl font-bold">
                      {title}
                    </Link>
                  </div>
                  {caption}
                </div>
              </div>
            ))}
          </div>
        )
      )}
    </div>
  );
};
