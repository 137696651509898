import type { FunctionComponent } from 'react';
import { useSiteContext } from '../contexts/SiteContext/SiteContext.tsx';

const defaultStyle = {
  height: '38px',
  width: 'auto',
};

type LogoProps = Record<string, never>;

export const Logo: FunctionComponent<LogoProps> = () => {
  const { logoUrl } = useSiteContext();
  return logoUrl ? <img src={logoUrl} alt="logo" style={defaultStyle} /> : null;
};
