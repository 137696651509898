import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { OrganizationBillingComponent } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { UserSubscriptions } from './user-subscriptions.tsx';

export const OrganizationBilling: FunctionComponent<OrganizationBillingComponent> = (props) => {
  const { onError, ErrorMessage } = useErrorHandler();

  return (
    <div className={componentClassName(props)}>
      <ErrorMessage />
      <UserSubscriptions onError={onError} />
    </div>
  );
};
