import type { Menu } from 'primereact/menu';
import type { MouseEvent, RefObject } from 'react';
import { useRef, useState } from 'react';

type MenuSetup<RowT> = {
  menu: RefObject<Menu>;
  menuRow: RowT | null;
  onSelectMenuRow: (row: RowT) => (event: MouseEvent) => void;
};

export const useMenuSetup = <RowT>(): MenuSetup<RowT> => {
  const menu = useRef<Menu>(null);
  const [menuRow, setMenuRow] = useState<RowT | null>(null);

  return {
    menu,
    menuRow,
    onSelectMenuRow: (row: RowT) => (event: MouseEvent) => {
      setMenuRow(row);
      menu.current?.show(event);
    },
  };
};
