import { clsx } from 'clsx';
import type { ForwardRefRenderFunction, PropsWithChildren, ReactNode } from 'react';
import { forwardRef } from 'react';
import styles from './PaddedContent.module.css';

type PaddedContentProps = {
  // Defaults to false.
  noBorder?: boolean;
  className?: string;
  children: ReactNode;
};

const PaddedContentBody: ForwardRefRenderFunction<HTMLDivElement, PaddedContentProps> = (
  { noBorder = false, className, children },
  ref,
) => (
  <div
    ref={ref}
    className={clsx(styles.paddedContent, !noBorder && 'border-1 border-round', className)}
  >
    {children}
  </div>
);

export const PaddedContent = forwardRef<HTMLDivElement, PropsWithChildren<PaddedContentProps>>(
  PaddedContentBody,
);
