import { isPlainObject } from 'lodash-es';

export const tryParseObject = (value: string): Record<string, unknown> => {
  try {
    const obj: unknown = JSON.parse(value);
    if (isPlainObject(obj)) {
      return obj as Record<string, unknown>;
    }
  } catch {
    // Ignore.
  }
  return {};
};
