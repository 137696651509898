/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.ts';

export type ActivateSpecialPageMutationVariables = Types.Exact<{
  siteId: Types.Scalars['String']['input'];
  specialPurpose: Types.PageSpecialPurpose;
}>;

export type ActivateSpecialPageMutation = {
  activateSpecialPage: {
    __typename: 'Page';
    id: string;
    specialPurpose: Types.PageSpecialPurpose;
    title: string;
    path: string;
  };
};

export const ActivateSpecialPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ActivateSpecialPage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'siteId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'specialPurpose' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageSpecialPurpose' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activateSpecialPage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'siteId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'siteId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'specialPurpose' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'specialPurpose' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'specialPurpose' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'path' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActivateSpecialPageMutation, ActivateSpecialPageMutationVariables>;
