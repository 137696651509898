import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { CourseSubSectionComponent } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';
import { RenderComponent } from '../../RenderComponent.tsx';

export const CourseSubSection: FunctionComponent<CourseSubSectionComponent> = (props) => (
  <div className={componentClassName(props)}>
    {props.children?.map((child) => <RenderComponent key={child.id} {...child} />)}
  </div>
);
