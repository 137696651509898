import { useQuery } from '@apollo/client';
import type { QueryOptions } from '@apollo/client/core/watchQueryOptions.ts';
import type { ErrorHandler } from '../useErrorHandler.tsx';
import type { FormSubmissionActionFieldsFragment } from './fragments.generated.ts';
import type {
  FormSubmissionActionsQuery,
  FormSubmissionActionsQueryVariables,
} from './queries.generated.ts';
import { FormSubmissionActionsDocument } from './queries.generated.ts';

type FormSubmissionActions = {
  formSubmissionActions: FormSubmissionActionFieldsFragment[] | undefined;
  loadingFormSubmissionActions: boolean;
  formSubmissionActionsQuery: QueryOptions<
    FormSubmissionActionsQueryVariables,
    FormSubmissionActionsQuery
  >;
};

export const useFormSubmissionActions = (
  formId: string,
  onError: ErrorHandler['onError'],
): FormSubmissionActions => {
  const formSubmissionActionsQuery: FormSubmissionActions['formSubmissionActionsQuery'] = {
    query: FormSubmissionActionsDocument,
    variables: { id: formId },
  };
  const { data, loading } = useQuery(FormSubmissionActionsDocument, {
    ...formSubmissionActionsQuery,
    onError,
    fetchPolicy: 'cache-and-network',
  });

  return {
    formSubmissionActions: data?.form.submissionActions,
    loadingFormSubmissionActions: loading,
    formSubmissionActionsQuery,
  };
};
