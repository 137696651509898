import { roundToDayEnd, roundToDayStart } from '@wirechunk/lib/dates.ts';
import { isDate } from 'lodash-es';
import { Calendar } from 'primereact/calendar';
import type { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { Fragment } from 'react';

type DateRangeFiltersProps = {
  fromDate: Date | null;
  toDate: Date | null;
  setFromDate: Dispatch<SetStateAction<Date | null>>;
  setToDate: Dispatch<SetStateAction<Date | null>>;
};

export const DateRangeFilters: FunctionComponent<DateRangeFiltersProps> = ({
  fromDate,
  toDate,
  setFromDate,
  setToDate,
}) => (
  <Fragment>
    <Calendar
      className="w-10rem"
      placeholder="Filter start date"
      inputId="fromDate"
      value={fromDate}
      showButtonBar
      formatDateTime={(date) => `From ${date.toLocaleDateString()}`}
      onChange={({ value }) => {
        if (isDate(value)) {
          if (toDate && toDate.getTime() < value.getTime()) {
            setToDate(roundToDayEnd(value));
          }
          setFromDate(roundToDayStart(value));
        }
      }}
      onClearButtonClick={() => {
        setFromDate(null);
      }}
    />
    <Calendar
      className="w-10rem"
      placeholder="Filter end date"
      inputId="toDate"
      value={toDate}
      showButtonBar
      formatDateTime={(date) => `To ${date.toLocaleDateString()}`}
      onChange={({ value }) => {
        if (isDate(value)) {
          if (fromDate && fromDate.getTime() > value.getTime()) {
            setFromDate(roundToDayStart(value));
          }
          setToDate(roundToDayEnd(value));
        }
      }}
      onClearButtonClick={() => {
        setToDate(null);
      }}
    />
  </Fragment>
);
