import { Tooltip } from '@radix-ui/themes';
import { SvgHelp } from '@wirechunk/material-symbols-react-400/20/outlined/help.tsx';
import type { FunctionComponent, ReactNode } from 'react';
import styles from './QuestionHelpTooltip.module.css';

type QuestionHelpTooltipProps = {
  helpText: ReactNode;
};

export const QuestionHelpTooltip: FunctionComponent<QuestionHelpTooltipProps> = ({ helpText }) => (
  <Tooltip content={helpText}>
    {/* Needed for element to be cloned by Tooltip. */}
    <div className={styles.container}>
      <SvgHelp width="17px" height="17px" fill="var(--gray-a11)" />
    </div>
  </Tooltip>
);
