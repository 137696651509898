import { booleanOrDefaultFalse } from '@wirechunk/lib/booleans.ts';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { MultipleChoiceInputComponent } from '@wirechunk/lib/mixer/types/components.ts';
import type { ValidInputComponent } from '@wirechunk/lib/mixer/utils.ts';
import { SvgCheck } from '@wirechunk/material-symbols-react-400/20/outlined/check.tsx';
import { clsx } from 'clsx';
import { PrimeIcons } from 'primereact/api';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { useInputDataContext } from '../../../contexts/InputDataContext.tsx';
import { useInputId } from '../../../hooks/use-input-id.ts';
import { withValidInputComponent } from '../../mixer-hocs/with-valid-input-component.tsx';
import styles from './MultipleChoiceInput.module.css';

const GuardedMultipleChoiceInput: FunctionComponent<
  ValidInputComponent<MultipleChoiceInputComponent>
> = (props) => {
  const { getValue, setValue } = useInputDataContext(props);
  const inputIdRoot = useInputId(props);
  const inputValue = booleanOrDefaultFalse(getValue(props));
  const { question, choices, answerIndex } = props;

  const [selection, setSelection] = useState<number | null>(null);

  const cursorClassName = inputValue ? undefined : 'cursor-pointer';

  const onClick = (index: number) => () => {
    if (!inputValue) {
      setSelection(index);
      if (index === answerIndex) {
        setValue(props, true);
      }
    }
  };

  // We can't use the PrimeReact RadioButton component here because on Chrome and Safari it includes an absolutely
  // positioned child that screws up scrolling when it appears inside a grid item that scrolls.
  return (
    <div className={componentClassName(props)}>
      <div className="flex gap-1">
        {question && <div className="font-medium">{question}</div>}
        {inputValue ? (
          <SvgCheck className="fill-green-9" width="21px" height="21px" />
        ) : (
          selection !== null && <i className={`${PrimeIcons.TIMES} text-red-500`} />
        )}
      </div>
      <div className="flex flex-column gap-2 mt-3 ml-2" role="radiogroup">
        {choices?.map((choice, index) => (
          <div key={index} className="flex gap-2 align-items-center">
            <div
              className={clsx(
                styles.radio,
                cursorClassName,
                (selection === index || inputValue) && answerIndex === index
                  ? styles.selectedCorrect
                  : selection === index
                    ? styles.selectedIncorrect
                    : undefined,
              )}
              role="radio"
              aria-labelledby={`${inputIdRoot}-${index}`}
              onClick={onClick(index)}
            />
            <label
              id={`${inputIdRoot}-${index}`}
              className={cursorClassName}
              onClick={onClick(index)}
            >
              {choice}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export const MultipleChoiceInput = withValidInputComponent<MultipleChoiceInputComponent>(
  GuardedMultipleChoiceInput,
);
