import { useMutation } from '@apollo/client';
import { PublishStatus } from '@wirechunk/lib/api.ts';
import { PrimeIcons } from 'primereact/api';
import { Menu } from 'primereact/menu';
import type { FunctionComponent } from 'react';
import { Fragment, useCallback, useRef } from 'react';
import {
  CurrentUserProvider,
  useCurrentUser,
} from '../../../contexts/CurrentUserContext/CurrentUserContext.tsx';
import { useDialog } from '../../../contexts/DialogContext/DialogContext.tsx';
import type { SiteContext } from '../../../contexts/SiteContext/SiteContext.tsx';
import { SiteContextProvider } from '../../../contexts/SiteContext/SiteContext.tsx';
import { useToast } from '../../../contexts/ToastContext.tsx';
import type { ErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { sitePageUrl } from '../../../util/site-page-url.ts';
import { BasicIconButton } from '../../basic-icon-button/basic-icon-button.tsx';
import { CustomizePageDesign } from './customize-page-design.tsx';
import { EditPagePath } from './edit-page-path.tsx';
import { EditPageTitle } from './edit-page-title.tsx';
import { EditPageStatusDocument } from './mutations.generated.ts';
import type { PagesQuery } from './queries.generated.ts';

type PageRowProps = {
  site: SiteContext;
  page: PagesQuery['site']['pages'][number];
  onError: ErrorHandler['onError'];
  onDeleted: () => void;
  allowEdit: boolean;
};

export const PageRow: FunctionComponent<PageRowProps> = ({
  site,
  page,
  onError,
  onDeleted,
  allowEdit,
}) => {
  const { toastSuccess } = useToast();
  const dialog = useDialog();
  const menu = useRef<Menu>(null);
  const { user } = useCurrentUser();

  const [editPageStatus, { loading: isEditingPageStatus }] = useMutation(EditPageStatusDocument, {
    onError,
  });

  const closeDialog = useCallback(() => {
    dialog(null);
  }, [dialog]);

  const onPageEdited = useCallback(() => {
    dialog(null);
    toastSuccess('Page updated.');
  }, [dialog, toastSuccess]);

  const { id, path, title, template } = page;

  return (
    <Fragment>
      <div>
        <div className="flex justify-content-between mb-1">
          <div className="font-medium">{title}</div>
          {allowEdit && (
            <BasicIconButton
              icon={PrimeIcons.ELLIPSIS_V}
              className="button-not-styled p-1"
              disabled={isEditingPageStatus}
              onClick={(event) => {
                menu.current?.show(event);
              }}
            />
          )}
        </div>
        <div className="flex align-items-stretch border-1 border-gray-100 border-round w-max max-w-full mb-2 overflow-hidden">
          <button
            className="surface-ground border-none text-sm min-w-max py-2 px-3 cursor-pointer flex-align-items-center gap-2"
            onClick={() => {
              void navigator.clipboard.writeText(sitePageUrl(site.domain, path));
              toastSuccess('Page link copied to clipboard.');
            }}
          >
            <span className="font-normal pr-2">{sitePageUrl(site.domain, path)}</span>
            <i className={PrimeIcons.COPY} />
          </button>
          <a
            className="bg-gray-100 border-noround-left min-w-max py-1 text-color-body text-sm px-3 flex align-items-center gap-2"
            href={sitePageUrl(site.domain, path)}
            target="_blank"
            rel="noreferrer"
          >
            <span className="font-medium">View</span>
            <i className={PrimeIcons.EXTERNAL_LINK} />
          </a>
        </div>
        <p className="text-sm">{template?.description || ''}</p>
      </div>
      <Menu
        ref={menu}
        model={[
          {
            label: 'Edit title',
            command: () => {
              dialog({
                content: (
                  <EditPageTitle page={page} onEdited={onPageEdited} onCancel={closeDialog} />
                ),
                props: {
                  header: `Edit ${page.title}`,
                },
              });
            },
          },
          {
            label: 'Edit URL path',
            command: () => {
              dialog({
                content: (
                  <EditPagePath
                    site={site}
                    page={page}
                    onEdited={onPageEdited}
                    onCancel={closeDialog}
                  />
                ),
                props: {
                  header: `Edit ${page.title}`,
                },
              });
            },
          },
          ...(template?.propsSetupComponents && template.propsSetupComponents !== '[]'
            ? [
                {
                  label: 'Customize design',
                  command: () => {
                    if (template.propsSetupComponents) {
                      dialog({
                        content: (
                          <SiteContextProvider value={site}>
                            <CurrentUserProvider user={user} loadingUser={false}>
                              <CustomizePageDesign
                                pageId={page.id}
                                props={page.props}
                                propsSetupComponents={template.propsSetupComponents}
                                onEdited={onPageEdited}
                                onCancel={closeDialog}
                              />
                            </CurrentUserProvider>
                          </SiteContextProvider>
                        ),
                        props: {
                          header: `Customize ${page.title} design`,
                          className: 'dialog-width-lg',
                        },
                      });
                    }
                  },
                },
              ]
            : []),
          {
            label: 'Delete',
            command: () => {
              dialog({
                confirm: `Are you sure you want to delete the page “${page.title}”?`,
                props: {
                  onAccept: () => {
                    void editPageStatus({
                      variables: {
                        pageId: id,
                        status: PublishStatus.Deleted,
                      },
                      onCompleted: () => {
                        onDeleted();
                      },
                    });
                  },
                  rejectLabel: 'Cancel',
                  rejectClassName: 'p-button-text text-color-body',
                  acceptLabel: 'Delete',
                  acceptClassName: 'p-button-danger',
                },
              });
            },
          },
        ]}
        popup
      />
    </Fragment>
  );
};
