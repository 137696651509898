/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.ts';

export type ApplyCustomPromoCodeQueryVariables = Types.Exact<{
  subscriptionPlanId: Types.Scalars['String']['input'];
  promoCode: Types.Scalars['String']['input'];
}>;

export type ApplyCustomPromoCodeQuery = {
  applyCustomPromoCode:
    | {
        __typename: 'ApplyCustomPromoCodeSuccessResult';
        subscriptionPlanId: string;
        promoCode: string;
        valid: boolean;
        successMessage?: { __typename: 'Delta'; delta: string } | null;
      }
    | { __typename: 'GenericInternalError'; message: string };
};

export type SubscriptionCheckoutOptionsQueryVariables = Types.Exact<{
  subscriptionPlanIds: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;

export type SubscriptionCheckoutOptionsQuery = {
  subscriptionCheckoutOptions: Array<{
    __typename: 'SubscriptionCheckoutOption';
    id: string;
    displayName: string;
    price: string;
    invoiceFrequency?: Types.InvoiceFrequency | null;
  }>;
};

export type MeProductItemsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MeProductItemsQuery = {
  me?: { __typename: 'User'; id: string; productItems: Array<string> } | null;
};

export type PreviewStripeUpcomingInvoiceQueryVariables = Types.Exact<{
  subscriptionPlanId: Types.Scalars['String']['input'];
  promoCode?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type PreviewStripeUpcomingInvoiceQuery = {
  previewStripeUpcomingInvoice:
    | { __typename: 'PreviewStripeUpcomingInvoiceErrorResult'; message: string }
    | {
        __typename: 'PreviewStripeUpcomingInvoiceSuccessResult';
        subtotal: string;
        total: string;
        promoCode?: {
          __typename: 'PreviewStripePromoCode';
          valid: boolean;
          coupon: {
            __typename: 'PreviewStripeCoupon';
            duration: Types.StripeCouponDuration;
            durationMonths?: number | null;
            amountOff?: string | null;
            percentOff?: number | null;
            valid: boolean;
          };
        } | null;
      };
};

export const ApplyCustomPromoCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ApplyCustomPromoCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionPlanId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'promoCode' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applyCustomPromoCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionPlanId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionPlanId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'promoCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'promoCode' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Error' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ApplyCustomPromoCodeSuccessResult' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'subscriptionPlanId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'promoCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'successMessage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'delta' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApplyCustomPromoCodeQuery, ApplyCustomPromoCodeQueryVariables>;
export const SubscriptionCheckoutOptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubscriptionCheckoutOptions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionPlanIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptionCheckoutOptions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionPlanIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionPlanIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invoiceFrequency' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubscriptionCheckoutOptionsQuery,
  SubscriptionCheckoutOptionsQueryVariables
>;
export const MeProductItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MeProductItems' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productItems' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MeProductItemsQuery, MeProductItemsQueryVariables>;
export const PreviewStripeUpcomingInvoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PreviewStripeUpcomingInvoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionPlanId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'promoCode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewStripeUpcomingInvoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionPlanId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionPlanId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'promoCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'promoCode' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PreviewStripeUpcomingInvoiceErrorResult' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PreviewStripeUpcomingInvoiceSuccessResult' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'promoCode' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'coupon' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'durationMonths' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'amountOff' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'percentOff' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PreviewStripeUpcomingInvoiceQuery,
  PreviewStripeUpcomingInvoiceQueryVariables
>;
