import type { ApolloQueryResult } from '@apollo/client';
import { useMutation, useQuery } from '@apollo/client';
import { serializeAddress } from '@wirechunk/lib/addresses.ts';
import { FileUploadFeature, PageSpecialPurpose } from '@wirechunk/lib/api.ts';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { CustomizeOrganizationSiteComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { SiteOptionKey, TempSiteCustomFieldKey } from '@wirechunk/lib/site-options.ts';
import { isBoolean, isNumber } from 'lodash-es';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Tooltip } from 'primereact/tooltip';
import type Delta from 'quill-delta';
import type { FunctionComponent } from 'react';
import { Fragment, useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useCurrentUser } from '../../../contexts/CurrentUserContext/CurrentUserContext.tsx';
import type { ErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { ActivateSpecialPageDocument } from '../../../hooks/useSpecialPages/mutations.generated.ts';
import { useSpecialPages } from '../../../hooks/useSpecialPages/use-special-pages.ts';
import { useUploadFile } from '../../../hooks/useUploadFile/useUploadFile.ts';
import { sitePageUrl } from '../../../util/site-page-url.ts';
import { QuillEditor } from '../../quill-editor.tsx';
import type { SelectGooglePlaceProps } from '../../SelectGooglePlace/SelectGooglePlace.tsx';
import { SelectGooglePlace } from '../../SelectGooglePlace/SelectGooglePlace.tsx';
import { placeToDescription } from '../../SelectGooglePlace/utils.ts';
import { Spinner } from '../../spinner/spinner.tsx';
import { EditSiteDocument } from './mutations.generated.ts';
import type { OrganizationSiteSettingsQuery } from './queries.generated.ts';
import { OrganizationSiteSettingsDocument } from './queries.generated.ts';

const specialPagesQuery: PageSpecialPurpose[] = [
  PageSpecialPurpose.PrivacyPolicy,
  PageSpecialPurpose.TermsOfUse,
];

type GuardedCustomizeOrganizationSiteProps = {
  site: NonNullable<NonNullable<OrganizationSiteSettingsQuery['organization']>['site']>;
  refetchSite: () => Promise<ApolloQueryResult<OrganizationSiteSettingsQuery>>;
  onError: ErrorHandler['onError'];
  clearMessages: ErrorHandler['clearMessages'];
};

const GuardedCustomizeOrganizationSite: FunctionComponent<
  GuardedCustomizeOrganizationSiteProps
> = ({ site, refetchSite, onError, clearMessages }) => {
  const {
    pages: specialPages,
    loading: loadingSpecialPages,
    refetch: refetchSpecialPages,
  } = useSpecialPages(site.id, specialPagesQuery, onError);

  const [activateSpecialPage, { loading: activatingSpecialPage }] = useMutation(
    ActivateSpecialPageDocument,
    {
      onError,
      onCompleted: () => {
        void refetchSpecialPages();
      },
    },
  );

  // Section 1
  const [name, setName] = useState(site.name);
  const [agentFirstName, setAgentFirstName] = useState(site.agentFirstName);
  const [agentLastName, setAgentLastName] = useState(site.agentLastName);
  const [agencyPrimaryPhone, setAgencyPrimaryPhone] = useState(site.agencyPrimaryPhone);
  const [addressLine1, setAddressLine1] = useState(site.addressLine1);
  const [addressLine2, setAddressLine2] = useState(site.addressLine2);
  const [addressCity, setAddressCity] = useState(site.addressCity);
  const [addressState, setAddressState] = useState(site.addressState);
  const [addressZip, setAddressZip] = useState(site.addressZip);
  const [googlePlaceId, setGooglePlaceId] = useState(site.googlePlaceId);
  const [agentPhotoUrl, setAgentPhotoUrl] = useState(site.agentPhotoUrl);
  const [logoUrl, setLogoUrl] = useState(site.logoUrl);

  const [agentPhotoFile, setAgentPhotoFile] = useState<File | null>(null);
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [googlePlaceDescription, setGooglePlaceDescription] = useState('');

  const setGooglePlace = useCallback<SelectGooglePlaceProps['setPlace']>((place) => {
    setGooglePlaceId(place.place_id);
    setGooglePlaceDescription(placeToDescription(place));
  }, []);

  const { upload: uploadAgentPhoto, isUploading: isUploadingAgentPhoto } = useUploadFile(onError);
  const { upload: uploadLogo, isUploading: isUploadingLogo } = useUploadFile(onError);

  const agentPhotoFileInput = useRef<HTMLInputElement>(null);
  const logoFileInput = useRef<HTMLInputElement>(null);

  // Note that section1Changed will be false if only images were added. But it will be true if an image was removed.
  // This is because image URLs on the site are set asynchronously after the image is uploaded.
  const section1Changed =
    name !== (site.name || '') ||
    agentFirstName !== (site.agentFirstName || '') ||
    agentLastName !== (site.agentLastName || '') ||
    agencyPrimaryPhone !== (site.agencyPrimaryPhone || '') ||
    addressLine1 !== (site.addressLine1 || '') ||
    addressLine2 !== (site.addressLine2 || '') ||
    addressCity !== (site.addressCity || '') ||
    addressState !== (site.addressState || '') ||
    addressZip !== (site.addressZip || '') ||
    (googlePlaceId || null) !== (site.googlePlaceId || null) ||
    (agentPhotoUrl || null) !== (site.agentPhotoUrl || null) ||
    (logoUrl || null) !== (site.logoUrl || null);

  // Section 2
  const [googleTagManagerId, setGoogleTagManagerId] = useState(site.googleTagManagerId || null);
  const [metaPixelId, setMetaPixelId] = useState(site.metaPixelId);

  const section2Changed =
    (googleTagManagerId || null) !== (site.googleTagManagerId || null) ||
    (metaPixelId || null) !== (site.metaPixelId || null);

  // Section 3
  // privacyPolicyContents is set only after changes.
  const [useCustomPrivacyPolicy, setUseCustomPrivacyPolicy] = useState(site.useCustomPrivacyPolicy);
  const [privacyPolicyContents, setPrivacyPolicyContents] = useState<Delta | null>(null);

  const section3Changed =
    useCustomPrivacyPolicy !== site.useCustomPrivacyPolicy || privacyPolicyContents !== null;

  // Section 4
  // termsOfUseContents is set only after changes.
  const [useCustomTermsOfUse, setUseCustomTermsOfUse] = useState(site.useCustomTermsOfUse);
  const [termsOfUseContents, setTermsOfUseContents] = useState<Delta | null>(null);

  const section4Changed =
    useCustomTermsOfUse !== site.useCustomTermsOfUse || termsOfUseContents !== null;

  const [editSite, { loading: editSiteLoading }] = useMutation(EditSiteDocument, {
    onError,
  });

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const saving = editSiteLoading || isUploadingAgentPhoto || isUploadingLogo;
  const isLoading = saving || loadingSpecialPages;

  const privacyPolicyPage = specialPages?.find(
    (page) => page.specialPurpose === PageSpecialPurpose.PrivacyPolicy,
  );
  const termsOfUsePage = specialPages?.find(
    (page) => page.specialPurpose === PageSpecialPurpose.TermsOfUse,
  );

  return (
    <Fragment>
      <p>
        Please fill in the details below to customize your website landing pages. The more
        information you provide about your agency, the more personalized and effective your pages
        will be.
      </p>
      <p>
        Need more help?{' '}
        <Link to="/contact" className="font-bold">
          Get in touch
        </Link>
      </p>
      <p>
        Your website:{' '}
        <a
          href={`https://${site.domain}`}
          target="_blank"
          className="ml-1 open-org-domain"
          rel="noopener noreferrer"
        >
          <i className={`${PrimeIcons.EXTERNAL_LINK} text-sm`} />
        </a>
        <Tooltip target=".open-org-domain" position="top" content="View website" />
      </p>
      <p className="mt-1">
        Want to change your website domain? Please{' '}
        <Link to="/contact" className="font-medium">
          get in touch with us.
        </Link>
      </p>
      <Accordion
        activeIndex={activeTabIndex}
        onTabChange={({ index }) => {
          if (isNumber(index)) {
            setActiveTabIndex(index);
            setPrivacyPolicyContents(null);
            setTermsOfUseContents(null);
          }
        }}
        onTabClose={() => {
          setPrivacyPolicyContents(null);
          setTermsOfUseContents(null);
        }}
      >
        <AccordionTab header="General options">
          <div className="input-field">
            <label htmlFor="orgAgentFirstName">Agent’s first name</label>
            <InputText
              id="orgAgentFirstName"
              className="w-full"
              value={agentFirstName || ''}
              onChange={(e) => {
                setAgentFirstName(e.target.value);
              }}
            />
          </div>
          <div className="input-field">
            <label htmlFor="orgAgentLastName">Agent’s last name</label>
            <InputText
              id="orgAgentLastName"
              className="w-full"
              value={agentLastName || ''}
              onChange={(e) => {
                setAgentLastName(e.target.value);
              }}
            />
          </div>
          <div className="input-field">
            <label htmlFor="orgAgencyName">Agency name</label>
            <InputText
              id="orgAgencyName"
              className="w-full"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="input-field">
            <label htmlFor="orgAgencyPrimaryPhone">Agency primary phone number</label>
            <InputText
              id="orgAgencyPrimaryPhone"
              className="w-full"
              value={agencyPrimaryPhone || ''}
              onChange={(e) => {
                setAgencyPrimaryPhone(e.target.value);
              }}
            />
          </div>
          <div className="input-field">
            <label htmlFor="orgAddressLine1">Agency address line 1</label>
            <InputText
              id="orgAddressLine1"
              className="w-full"
              value={addressLine1 || ''}
              onChange={(e) => {
                setAddressLine1(e.target.value);
              }}
            />
          </div>
          <div className="input-field">
            <label htmlFor="orgAddressLine2">Agency address line 2</label>
            <InputText
              id="orgAddressLine2"
              className="w-full"
              value={addressLine2 || ''}
              onChange={(e) => {
                setAddressLine2(e.target.value);
              }}
            />
          </div>
          <div className="input-field">
            <label htmlFor="orgAddressCity">Agency city</label>
            <InputText
              id="orgAddressCity"
              className="w-full"
              value={addressCity || ''}
              onChange={(e) => {
                setAddressCity(e.target.value);
              }}
            />
          </div>
          <div className="input-field">
            <label htmlFor="orgAddressState">Agency state</label>
            <InputText
              id="orgAddressState"
              className="w-full"
              value={addressState || ''}
              onChange={(e) => {
                setAddressState(e.target.value);
              }}
            />
          </div>
          <div className="input-field">
            <label htmlFor="orgAddressZip">Agency zip code</label>
            <InputText
              id="orgAddressZip"
              className="w-full"
              value={addressZip || ''}
              onChange={(e) => {
                setAddressZip(e.target.value);
              }}
            />
          </div>
          <div className="mb-3 mt-4">
            <p>
              We use your selected business on Google to send people who give you a 5-star review to
              your Google business page to add a review there.
            </p>
            <div className="flex flex-row align-items-center my-3 line-height-4">
              <span className="font-medium mr-2">Selected business on Google:</span>
              <span>{(googlePlaceId && googlePlaceDescription) || '(None)'}</span>
              {googlePlaceId && (
                <Button
                  className="ml-3 p-button-sm p-button-outlined min-w-max"
                  label="Clear"
                  onClick={() => {
                    setGooglePlaceId(null);
                    setGooglePlaceDescription('');
                  }}
                />
              )}
            </div>
            <SelectGooglePlace
              placeId={googlePlaceId}
              setPlace={setGooglePlace}
              initialFindPlaceAddress={serializeAddress({
                addressLine1: site.addressLine1,
                addressLine2: site.addressLine2,
                addressCity: site.addressCity,
                addressState: site.addressState,
                addressZip: site.addressZip,
              })}
            />
          </div>
          <div className="input-field">
            <div className="font-medium mb-2">Agent photo</div>
            {agentPhotoUrl && (
              <Fragment>
                <img src={agentPhotoUrl} alt="Agent" className="block w-16rem h-auto" />
                <Button
                  label="Remove photo"
                  className="block my-2 p-button-outlined p-button-sm"
                  disabled={isLoading}
                  onClick={() => {
                    setAgentPhotoFile(null);
                    setAgentPhotoUrl(null);
                    if (agentPhotoFileInput.current) {
                      agentPhotoFileInput.current.value = '';
                    }
                  }}
                />
              </Fragment>
            )}
            <label
              htmlFor="siteAgentPhoto"
              className="max-w-14rem mt-3 flex gap-2 p-button p-button-outlined"
            >
              <i className={PrimeIcons.UPLOAD} />
              <span>Select {site.agentPhotoUrl ? ' new ' : ''}photo</span>
            </label>
            {agentPhotoFile && (
              <Fragment>
                <div className="mt-2 text-gray-700 max-w-full overflow-x-hidden text-overflow-ellipsis">
                  Selected: {agentPhotoFile.name}
                </div>
                <Button
                  label="Remove new photo"
                  className="mt-1 py-1 p-button-outlined p-button-sm"
                  disabled={isLoading}
                  onClick={() => {
                    setAgentPhotoFile(null);
                    if (agentPhotoFileInput.current) {
                      agentPhotoFileInput.current.value = '';
                    }
                  }}
                />
              </Fragment>
            )}
            <input
              ref={agentPhotoFileInput}
              id="siteAgentPhoto"
              className="hidden"
              type="file"
              accept="image/*"
              disabled={isLoading}
              onChange={(e) => {
                setAgentPhotoFile(e.target.files?.[0] ?? null);
              }}
            />
          </div>
          <div className="input-field">
            <div className="font-medium mb-2">Agency logo</div>
            {logoUrl && (
              <Fragment>
                <img src={logoUrl} alt="Agency logo" className="block w-16rem h-auto" />
                <Button
                  label="Remove logo"
                  className="block my-2 p-button-outlined p-button-sm"
                  disabled={isLoading}
                  onClick={() => {
                    setLogoFile(null);
                    setLogoUrl(null);
                    if (logoFileInput.current) {
                      logoFileInput.current.value = '';
                    }
                  }}
                />
              </Fragment>
            )}
            <label
              htmlFor="siteLogo"
              className="max-w-14rem mt-3 flex gap-2 p-button p-button-outlined"
            >
              <i className={PrimeIcons.UPLOAD} />
              <span>Select {site.logoUrl ? ' new ' : ''}photo</span>
            </label>
            {logoFile && (
              <Fragment>
                <div className="mt-2 text-gray-700 max-w-full overflow-x-hidden text-overflow-ellipsis">
                  Selected: {logoFile.name}
                </div>
                <Button
                  label="Remove new photo"
                  className="mt-1 py-1 p-button-outlined p-button-sm"
                  disabled={isLoading}
                  onClick={() => {
                    setAgentPhotoFile(null);
                    if (logoFileInput.current) {
                      logoFileInput.current.value = '';
                    }
                  }}
                />
              </Fragment>
            )}
            <input
              ref={logoFileInput}
              id="siteLogo"
              className="hidden"
              type="file"
              accept="image/*"
              disabled={isLoading}
              onChange={(e) => {
                setLogoFile(e.target.files?.[0] ?? null);
              }}
            />
          </div>
          <Button
            label={saving ? 'Saving...' : 'Save'}
            className="mt-3 mb-1"
            disabled={isLoading || (!section1Changed && !agentPhotoFile && !logoFile)}
            onClick={() => {
              clearMessages();
              if (section1Changed) {
                void editSite({
                  variables: {
                    input: {
                      id: site.id,
                      name: { value: name },
                      customFields: [
                        {
                          key: TempSiteCustomFieldKey.AgentFirstName,
                          value: JSON.stringify(agentFirstName),
                        },
                        {
                          key: TempSiteCustomFieldKey.AgentLastName,
                          value: JSON.stringify(agentLastName),
                        },
                        {
                          key: TempSiteCustomFieldKey.PrimaryPhone,
                          value: JSON.stringify(agencyPrimaryPhone),
                        },
                        {
                          key: TempSiteCustomFieldKey.AddressLine1,
                          value: JSON.stringify(addressLine1),
                        },
                        {
                          key: TempSiteCustomFieldKey.AddressLine2,
                          value: JSON.stringify(addressLine2),
                        },
                        {
                          key: TempSiteCustomFieldKey.AddressCity,
                          value: JSON.stringify(addressCity),
                        },
                        {
                          key: TempSiteCustomFieldKey.AddressState,
                          value: JSON.stringify(addressState),
                        },
                        {
                          key: TempSiteCustomFieldKey.AddressZip,
                          value: JSON.stringify(addressZip),
                        },
                        {
                          key: TempSiteCustomFieldKey.GooglePlaceId,
                          value: JSON.stringify(googlePlaceId),
                        },
                        {
                          key: TempSiteCustomFieldKey.AgentPhotoUrl,
                          value: JSON.stringify(agentPhotoUrl),
                        },
                        { key: SiteOptionKey.LogoUrl, value: JSON.stringify(logoUrl) },
                      ],
                    },
                  },
                  onCompleted: (data) => {
                    if (data.editSite.__typename === 'EditSiteSuccessResult') {
                      const s = data.editSite.site;
                      setName(s.name);
                      setAgentFirstName(s.agentFirstName);
                      setAgentLastName(s.agentLastName);
                      setAgencyPrimaryPhone(s.agencyPrimaryPhone);
                      setAddressLine1(s.addressLine1);
                      setAddressLine2(s.addressLine2);
                      setAddressCity(s.addressCity);
                      setAddressState(s.addressState);
                      setAddressZip(s.addressZip);
                      setGooglePlaceId(s.googlePlaceId);
                      setAgentPhotoUrl(s.agentPhotoUrl);
                      setLogoUrl(s.logoUrl);
                    } else {
                      onError(data.editSite);
                    }
                  },
                });
              }
              if (agentPhotoFile) {
                void uploadAgentPhoto(
                  agentPhotoFile,
                  {
                    feature: FileUploadFeature.SiteAgentPhotoUrl,
                    siteId: site.id,
                  },
                  () => {
                    const { current: input } = agentPhotoFileInput;
                    if (input) {
                      input.value = '';
                    }
                    setAgentPhotoFile(null);
                  },
                  () => {
                    void refetchSite();
                  },
                );
              }
              if (logoFile) {
                void uploadLogo(
                  logoFile,
                  {
                    feature: FileUploadFeature.SiteLogoUrl,
                    siteId: site.id,
                  },
                  () => {
                    const { current: input } = logoFileInput;
                    if (input) {
                      input.value = '';
                    }
                    setLogoFile(null);
                  },
                  () => {
                    void refetchSite();
                  },
                );
              }
            }}
          />
        </AccordionTab>
        <AccordionTab header="Analytics integrations">
          <p>
            You can collect analytics through Meta (Facebook) and Google Tag Manager. This is useful
            for tracking page views and conversions, for example when a form is submitted. Simply
            provide either your Google Tag Manager container ID or your Meta pixel ID here. You can
            use both services, and both are optional.
          </p>
          <p>
            Need to track analytics using a different method? Please{' '}
            <Link to="/contact" className="font-medium">
              get in touch
            </Link>
            .
          </p>
          <div className="input-field">
            <label htmlFor="orgMetaPixelId">Meta Pixel ID</label>
            <InputText
              id="orgMetaPixelId"
              className="w-full"
              value={metaPixelId || ''}
              onChange={(e) => {
                setMetaPixelId(e.target.value);
              }}
            />
          </div>
          <div className="input-field">
            <label htmlFor="orgGoogleTagManagerId">Google Tag Manager container ID</label>
            <InputText
              id="orgGoogleTagManagerId"
              className="w-full"
              value={googleTagManagerId || ''}
              onChange={(e) => {
                setGoogleTagManagerId(e.target.value);
              }}
            />
          </div>
          <Button
            label={saving ? 'Saving...' : 'Save'}
            className="mt-3"
            disabled={isLoading || !section2Changed}
            onClick={() => {
              clearMessages();
              void editSite({
                variables: {
                  input: {
                    id: site.id,
                    googleTagManagerId: { value: googleTagManagerId },
                    metaPixelId: { value: metaPixelId },
                  },
                },
                onCompleted: (data) => {
                  if (data.editSite.__typename === 'EditSiteSuccessResult') {
                    setGoogleTagManagerId(data.editSite.site.googleTagManagerId || null);
                    setMetaPixelId(data.editSite.site.metaPixelId || null);
                  } else {
                    onError(data.editSite);
                  }
                },
              });
            }}
          />
        </AccordionTab>
        {site.headEndCode && (
          <AccordionTab header="Custom code snippet">
            <p>
              Need to add or customize a code snippet on your site?{' '}
              <Link to="/contact" className="font-medium">
                Get in touch with our team.
              </Link>
            </p>
            <div className="flex flex-column gap-3">
              <div>
                <div className="text-lg font-medium mb-2">Site {'<head>'} element code</div>
                <pre className="border-1 border-round-sm p-2 w-full overflow-y-auto">
                  <code>{site.headEndCode}</code>
                </pre>
              </div>
            </div>
          </AccordionTab>
        )}
        <AccordionTab header="Privacy Policy">
          <p>
            We provide a Privacy Policy page for your website by default, but if you’d like to
            customize it, you can do so here.
          </p>
          {privacyPolicyPage ? (
            <p>
              <a
                href={sitePageUrl(site.domain, privacyPolicyPage.path)}
                target="_blank"
                rel="noreferrer"
                className="flex align-items-center gap-2"
              >
                <span className="line-height-4">View Privacy Policy page</span>
                <i className={`${PrimeIcons.EXTERNAL_LINK} text-sm`} />
              </a>
            </p>
          ) : (
            // TODO: This should be done automatically.
            <Fragment>
              <p>You do not have a Privacy Policy page yet.</p>
              <Button
                label="Activate Privacy Policy"
                className="mb-3"
                disabled={activatingSpecialPage}
                onClick={() => {
                  clearMessages();
                  void activateSpecialPage({
                    variables: {
                      siteId: site.id,
                      specialPurpose: PageSpecialPurpose.PrivacyPolicy,
                    },
                  });
                }}
              />
            </Fragment>
          )}
          <div className="mb-3 flex gap-3">
            <div className="input-field flex gap-2 align-items-center mb-0">
              <RadioButton
                inputId="siteUseCustomPrivacyPolicyFalse"
                value={false}
                checked={!useCustomPrivacyPolicy}
                onChange={({ value }) => {
                  if (isBoolean(value)) {
                    setUseCustomPrivacyPolicy(value);
                  }
                }}
              />
              <label htmlFor="siteUseCustomPrivacyPolicyFalse" className="mb-0">
                Use default policy
              </label>
            </div>
            <div className="input-field flex gap-2 align-items-center mb-0">
              <RadioButton
                inputId="siteUseCustomPrivacyPolicyTrue"
                value
                checked={useCustomPrivacyPolicy}
                onChange={({ value }) => {
                  if (isBoolean(value)) {
                    setUseCustomPrivacyPolicy(value);
                  }
                }}
              />
              <label htmlFor="siteUseCustomPrivacyPolicyTrue" className="mb-0">
                Use custom policy
              </label>
            </div>
          </div>
          <QuillEditor
            // Force reset of editor when the saved privacyPolicy changes.
            key={site.privacyPolicy?.delta || ''}
            initialValue={site.privacyPolicy?.delta}
            onChange={setPrivacyPolicyContents}
          />
          <Button
            label={saving ? 'Saving...' : 'Save'}
            className="mt-3 mb-1"
            disabled={isLoading || !section3Changed}
            onClick={() => {
              clearMessages();
              void editSite({
                variables: {
                  input: {
                    id: site.id,
                    customFields: [
                      {
                        key: TempSiteCustomFieldKey.UseCustomPrivacyPolicy,
                        value: JSON.stringify(useCustomPrivacyPolicy),
                      },
                      ...(privacyPolicyContents
                        ? [
                            {
                              key: SiteOptionKey.PrivacyPolicy,
                              value: JSON.stringify({
                                delta: JSON.stringify(privacyPolicyContents),
                              }),
                            },
                          ]
                        : []),
                    ],
                  },
                },
                onCompleted: (data) => {
                  if (data.editSite.__typename === 'EditSiteSuccessResult') {
                    setPrivacyPolicyContents(null);
                    setUseCustomPrivacyPolicy(data.editSite.site.useCustomPrivacyPolicy);
                  } else {
                    onError(data.editSite);
                  }
                },
              });
            }}
          />
        </AccordionTab>
        <AccordionTab header="Terms of Use">
          <p>
            We provide a Terms of Use page for your website by default, but if you’d like to
            customize it, you can do so here.
          </p>
          {termsOfUsePage ? (
            <p>
              <a
                href={sitePageUrl(site.domain, termsOfUsePage.path)}
                target="_blank"
                rel="noreferrer"
                className="flex align-items-center gap-2"
              >
                <span className="line-height-4">View Terms of Use page</span>
                <i className={`${PrimeIcons.EXTERNAL_LINK} text-sm`} />
              </a>
            </p>
          ) : (
            // TODO: This should be done automatically.
            <Fragment>
              <p>You do not have a Terms of Use page yet.</p>
              <Button
                label="Activate Terms of Use"
                className="mb-3"
                disabled={activatingSpecialPage}
                onClick={() => {
                  clearMessages();
                  void activateSpecialPage({
                    variables: {
                      siteId: site.id,
                      specialPurpose: PageSpecialPurpose.TermsOfUse,
                    },
                  });
                }}
              />
            </Fragment>
          )}
          <div className="mb-3 flex gap-3">
            <div className="input-field flex gap-2 align-items-center mb-0">
              <RadioButton
                inputId="siteUseCustomTermsOfUseFalse"
                value={false}
                checked={!useCustomTermsOfUse}
                onChange={({ value }) => {
                  if (isBoolean(value)) {
                    setUseCustomTermsOfUse(value);
                  }
                }}
              />
              <label htmlFor="siteUseCustomTermsOfUseFalse" className="mb-0">
                Use default terms
              </label>
            </div>
            <div className="input-field flex gap-2 align-items-center mb-0">
              <RadioButton
                inputId="siteUseCustomTermsOfUseTrue"
                value
                checked={useCustomTermsOfUse}
                onChange={({ value }) => {
                  if (isBoolean(value)) {
                    setUseCustomTermsOfUse(value);
                  }
                }}
              />
              <label htmlFor="siteUseCustomTermsOfUseTrue" className="mb-0">
                Use custom terms
              </label>
            </div>
          </div>
          <QuillEditor
            // Force reset of editor when the saved termsOfUse changes.
            key={site.termsOfUse?.delta || ''}
            initialValue={site.termsOfUse?.delta}
            onChange={setTermsOfUseContents}
          />
          <Button
            label={saving ? 'Saving...' : 'Save'}
            className="mt-3 mb-1"
            disabled={isLoading || !section4Changed}
            onClick={() => {
              clearMessages();
              void editSite({
                variables: {
                  input: {
                    id: site.id,
                    customFields: [
                      {
                        key: TempSiteCustomFieldKey.UseCustomTermsOfUse,
                        value: JSON.stringify(useCustomTermsOfUse),
                      },
                      ...(termsOfUseContents
                        ? [
                            {
                              key: SiteOptionKey.TermsOfUse,
                              value: JSON.stringify({
                                delta: JSON.stringify(termsOfUseContents),
                              }),
                            },
                          ]
                        : []),
                    ],
                  },
                },
                onCompleted: (data) => {
                  if (data.editSite.__typename === 'EditSiteSuccessResult') {
                    setTermsOfUseContents(null);
                    setUseCustomTermsOfUse(data.editSite.site.useCustomTermsOfUse);
                  } else {
                    onError(data.editSite);
                  }
                },
              });
            }}
          />
        </AccordionTab>
      </Accordion>
    </Fragment>
  );
};

export const CustomizeOrganizationSite: FunctionComponent<CustomizeOrganizationSiteComponent> = (
  props,
) => {
  const { user } = useCurrentUser();
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();

  const {
    data: orgData,
    loading,
    refetch,
  } = useQuery(OrganizationSiteSettingsDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: { organizationId: user.org.id },
  });

  const site = orgData?.organization.site;

  return (
    <div className={componentClassName(props)}>
      <ErrorMessage />
      {loading && <Spinner py="3" />}
      {site && (
        <GuardedCustomizeOrganizationSite
          site={site}
          refetchSite={refetch}
          onError={onError}
          clearMessages={clearMessages}
        />
      )}
    </div>
  );
};
