import { useQuery } from '@apollo/client';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useErrorCollector } from '../../contexts/error-collector-context.tsx';
import { SiteContextProvider } from '../../contexts/SiteContext/SiteContext.tsx';
import { Spinner } from '../spinner/spinner.tsx';
import { PublicSiteDocument } from './queries.generated.ts';

const { siteId } = window.wirechunk;

export const CurrentSiteProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { onError } = useErrorCollector();
  const { data, loading } = useQuery(PublicSiteDocument, {
    onError,
    variables: { id: siteId },
  });

  const site = data?.publicSite;
  if (site) {
    return <SiteContextProvider value={site}>{children}</SiteContextProvider>;
  }

  return loading && <Spinner py="3" />;
};
