import { isString } from 'lodash-es';

// TODO: Add unit tests.
// Get a new array (without mutating the input array) with the specified item added at the specified index.
export const insertAtIndex = <T>(arr: T[], index: number, elem: T): T[] => {
  const newArr = [...arr];
  newArr.splice(index, 0, elem);
  return newArr;
};

export const isStringArray = (value: unknown): value is string[] =>
  Array.isArray(value) && value.every(isString);

export const arrayOrDefaultNull = (value: unknown): unknown[] | null =>
  Array.isArray(value) ? value : null;

export const joinListTruncate = (items: string[], limit: number): string => {
  if (items.length <= limit) {
    return items.join(', ');
  }
  return `${items.slice(0, limit).join(', ')}, ...`;
};

/**
 * Returns a new array with the specified element inserted in the correct position to maintain the array sorted,
 */
export const insertSorted = <T>(arr: T[], elem: T, compare: (a: T, b: T) => number): T[] => {
  const index = arr.findIndex((item) => compare(elem, item) < 0);
  if (index === -1) {
    return [...arr, elem];
  }
  return insertAtIndex(arr, index, elem);
};
