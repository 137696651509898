/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.ts';

export type RefreshSessionMutationVariables = Types.Exact<{ [key: string]: never }>;

export type RefreshSessionMutation = { refreshSession: boolean };

export const RefreshSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RefreshSession' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'refreshSession' } }],
      },
    },
  ],
} as unknown as DocumentNode<RefreshSessionMutation, RefreshSessionMutationVariables>;
