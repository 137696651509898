import { clsx } from 'clsx';
import type { FunctionComponent } from 'react';
import styles from './google-map.module.css';

// This is the "plus code" for Dallas, TX.
const defaultQ = '8645Q6G2+JW';

const { gMapsKey } = window.wirechunk;

type GoogleMapProps = {
  className?: string;
  googlePlaceId: string | null | undefined;
};

export const GoogleMap: FunctionComponent<GoogleMapProps> = ({ className, googlePlaceId }) => {
  const query = googlePlaceId ? `place_id:${googlePlaceId}` : defaultQ;

  const src = `https://www.google.com/maps/embed/v1/place?key=${gMapsKey}&q=${query}`;

  return (
    <div className={clsx(styles.map, className)}>
      <iframe
        loading="lazy"
        allowFullScreen
        referrerPolicy="no-referrer-when-downgrade"
        src={src}
      ></iframe>
    </div>
  );
};
