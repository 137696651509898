import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { PhoneLinkComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { DataSource } from '@wirechunk/lib/mixer/types/components.ts';
import { removeNonDigits } from '@wirechunk/lib/strings.ts';
import { isString } from 'lodash-es';
import type { FunctionComponent } from 'react';
import { useMemo } from 'react';
import { usePropsContext } from '../../contexts/props-context.ts';
import { useSiteContext } from '../../contexts/SiteContext/SiteContext.tsx';
import { RenderComponent } from '../RenderComponent.tsx';

export const PhoneLink: FunctionComponent<PhoneLinkComponent> = (props) => {
  const propsContext = usePropsContext();
  const { agencyPrimaryPhone } = useSiteContext();

  const phone = useMemo(() => {
    if (props.phoneNumber?.type === 'custom') {
      return removeNonDigits(props.phoneNumber.text);
    }
    if (props.phoneNumber?.type === 'agencyPrimaryPhone') {
      return removeNonDigits(agencyPrimaryPhone || '');
    }
    if (props.phoneNumber?.type === DataSource.Direct) {
      return removeNonDigits(props.phoneNumber.phoneNumber || '');
    }
    if (props.phoneNumber?.name) {
      const value = propsContext[props.phoneNumber.name];
      if (isString(value)) {
        return removeNonDigits(value);
      }
    }
    return '';
  }, [props.phoneNumber, agencyPrimaryPhone, propsContext]);

  return (
    <a className={componentClassName(props)} href={`tel:${phone}`}>
      {props.children?.map((child) => <RenderComponent key={child.id} {...child} />)}
    </a>
  );
};
