import type { Component } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';
import { AuthenticationGuard } from '../authentication-guard.tsx';
import { RenderMixerChildren } from '../RenderMixerChildren.tsx';

export const SignInGuard: FunctionComponent<Component> = ({ children }) => (
  <AuthenticationGuard>
    <RenderMixerChildren>{children}</RenderMixerChildren>
  </AuthenticationGuard>
);
