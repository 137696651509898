import { useMutation, useQuery } from '@apollo/client';
import { Button, Flex, Text } from '@radix-ui/themes';
import { LessonCompletionMode } from '@wirechunk/lib/api.ts';
import { SvgTaskAlt } from '@wirechunk/material-symbols-react-400/20/outlined/task-alt.tsx';
import type { FunctionComponent } from 'react';
import { useEffect, Fragment } from 'react';
import { useOptionalCurrentUser } from '../../../../contexts/CurrentUserContext/CurrentUserContext.tsx';
import { useErrorHandler } from '../../../../hooks/useErrorHandler.tsx';
import { ParseAndRenderComponents } from '../../../ParseAndRenderComponents.tsx';
import { Spinner } from '../../../spinner/spinner.tsx';
import { LessonComponentsDocument } from '../queries.generated.ts';
import type { CurrentLesson, CurrentSubLesson } from '../types.ts';
import {
  CreateLessonUserDocument,
  EditLessonUserCompletedDocument,
} from './mutations.generated.ts';

export type LessonProps = {
  lesson: CurrentLesson | CurrentSubLesson;
  lessonCompletionMode: LessonCompletionMode;
};

export const Lesson: FunctionComponent<LessonProps> = ({ lesson, lessonCompletionMode }) => {
  const { user, loadingUser } = useOptionalCurrentUser();
  const { onError, ErrorMessage } = useErrorHandler();
  const { data: lessonComponentsData, loading: lessonComponentsLoading } = useQuery(
    LessonComponentsDocument,
    {
      onError,
      variables: { id: lesson.id },
    },
  );
  const [createLessonUser, { loading: createLessonUserLoading }] = useMutation(
    CreateLessonUserDocument,
    {
      onError,
      onCompleted: (data) => {
        if (data.createLessonUser.__typename === 'GenericUserError') {
          onError(data.createLessonUser);
        }
      },
    },
  );
  const [editLessonUserCompleted, { loading: editLessonUserCompletedLoading }] = useMutation(
    EditLessonUserCompletedDocument,
    {
      onError,
      onCompleted: (data) => {
        if (data.editLessonUser.__typename === 'GenericUserError') {
          onError(data.editLessonUser);
        }
      },
    },
  );
  const { lessonUser } = lesson;
  useEffect(() => {
    if (user && !lessonUser) {
      void createLessonUser({
        variables: {
          lessonId: lesson.id,
          userId: user.id,
        },
      });
    }
  }, [user, lesson.id, lessonUser, createLessonUser]);

  if (
    (!user && loadingUser) ||
    (!lessonComponentsData && lessonComponentsLoading) ||
    createLessonUserLoading
  ) {
    return <Spinner py="3" />;
  }

  return (
    <Fragment>
      <ErrorMessage />
      <ParseAndRenderComponents componentsJSON={lessonComponentsData?.lesson.components} />
      {user && lessonUser && lessonCompletionMode === LessonCompletionMode.Manual && (
        <Flex justify="center" mt="5">
          {lessonUser.completedAt ? (
            <Flex gap="2">
              <SvgTaskAlt className="fill-green-9" />
              <Text size="2" weight="medium">
                Lesson completed
              </Text>
            </Flex>
          ) : (
            <Button
              disabled={editLessonUserCompletedLoading}
              color="green"
              onClick={() => {
                void editLessonUserCompleted({
                  variables: {
                    lessonUserId: lessonUser.id,
                    userId: user.id,
                    completed: true,
                  },
                });
              }}
            >
              Mark complete{editLessonUserCompletedLoading && <Spinner ml="2" />}
            </Button>
          )}
        </Flex>
      )}
    </Fragment>
  );
};
