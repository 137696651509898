import type { FunctionComponent } from 'react';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';

export const NotFound: FunctionComponent = () => (
  <Fragment>
    <Helmet>
      <title>Not Found</title>
    </Helmet>
    <p className="font-bold m-2">
      No page was found here. It’s possible that you don’t have permission to view this page.
    </p>
  </Fragment>
);
