import { isNumber, isString } from 'lodash-es';

// Returns value if it is a number, or null otherwise.
export const numberOrDefaultNull = (value: unknown): number | null =>
  isNumber(value) ? value : null;

// A number has at least one digit at the beginning, after an optional dash, and may also have a decimal point and then
// more digits.
const numberRegex = /^-?\d+(\.\d+)?$/;

export const isValidNumber = (value: string): boolean => numberRegex.test(value);

const currencyRegex = /^\d+.?\d{0,2}$/;

export const isValidCurrency = (value: string): boolean => currencyRegex.test(value);

// An integer has at least one digit and only digits after an optional dash at the beginning.
const integerRegex = /^-?\d+$/;

export const isValidInteger = (value: unknown): boolean =>
  isString(value) && integerRegex.test(value);
