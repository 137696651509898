import { noop } from 'lodash-es';
import type { Dispatch, FunctionComponent, PropsWithChildren, SetStateAction } from 'react';
import { useMemo, useState, createContext, useContext } from 'react';

export enum SessionStatus {
  Active,
  Expired,
  SignedOut,
}

export type SessionStatusContext = {
  status: SessionStatus;
  setStatus: Dispatch<SetStateAction<SessionStatus>>;
  errorMessage: string | null;
  setErrorMessage: Dispatch<SetStateAction<string | null>>;
};

const context = createContext<SessionStatusContext>({
  status: SessionStatus.SignedOut,
  setStatus: noop,
  errorMessage: null,
  setErrorMessage: noop,
});

export const SessionStatusContextProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [sessionStatus, setSessionStatus] = useState<SessionStatus>(SessionStatus.SignedOut);
  const [sessionErrorMessage, setSessionErrorMessage] = useState<string | null>(null);

  const sessionStatusContext = useMemo<SessionStatusContext>(
    () => ({
      status: sessionStatus,
      setStatus: setSessionStatus,
      errorMessage: sessionErrorMessage,
      setErrorMessage: setSessionErrorMessage,
    }),
    [sessionErrorMessage, sessionStatus],
  );

  return <context.Provider value={sessionStatusContext}>{children}</context.Provider>;
};

export const useSessionStatusContext = (): SessionStatusContext => useContext(context);
