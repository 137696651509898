import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { VerifiedEmailAddressGuardComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { Button } from 'primereact/button';
import type { FunctionComponent } from 'react';
import { useCurrentUser } from '../../../contexts/CurrentUserContext/CurrentUserContext.tsx';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { useSendVerificationEmail } from '../../../hooks/useSendVerificationEmail/useSendVerificationEmail.ts';
import { RenderMixerChildren } from '../../RenderMixerChildren.tsx';

export const VerifiedEmailAddressGuard: FunctionComponent<VerifiedEmailAddressGuardComponent> = (
  props,
) => {
  const { user } = useCurrentUser();
  const { onError, ErrorMessage } = useErrorHandler();
  const { sendVerificationEmail, isSending, verificationEmailSent } =
    useSendVerificationEmail(onError);

  if (user.emailVerified) {
    return <RenderMixerChildren>{props.children}</RenderMixerChildren>;
  }

  return (
    <div className={componentClassName(props)}>
      <ErrorMessage />
      <div className="flex align-items-center flex-column text-center">
        <p className="text-lg">
          {user.firstName}, before you can get started here, we need to verify your email address.
          Please click the button below to get a verification email.
        </p>
        {verificationEmailSent ? (
          <p className="text-lg">
            Email sent! Please check your inbox and click the link you were provided.
          </p>
        ) : (
          <Button
            label="Send verification email"
            className="mt-2"
            disabled={isSending}
            onClick={() => {
              void sendVerificationEmail();
            }}
          />
        )}
      </div>
    </div>
  );
};
