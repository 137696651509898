import type { FunctionComponent } from 'react';

type PlayProps = {
  fill?: string;
  width?: string;
  height?: string;
  pathClassName?: string;
};

export const Play: FunctionComponent<PlayProps> = ({ fill, width, height, pathClassName }) => (
  <svg
    width={width}
    height={height}
    version="1.1"
    viewBox="0 0 16 18"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m14.3489 9-12.6977 9v-18z" fill={fill} className={pathClassName} />
  </svg>
);
