import type { FetchResult } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useCurrentUser } from '../../../../contexts/CurrentUserContext/CurrentUserContext.tsx';
import type { ErrorHandler } from '../../../../hooks/useErrorHandler.tsx';
import type { EditUserPlanMutation } from './mutations.generated.ts';
import { EditUserPlanDocument } from './mutations.generated.ts';

type EditUserPlanLive = {
  editUserPlan: (
    planId: string,
    stageBlueprintId: string,
  ) => Promise<FetchResult<EditUserPlanMutation>>;
  saving: boolean;
};

// The "Live" part of the name refers to the way the mutation result selection is meant for the mutation to be
// used by someone editing their own UserPlan that they're on.
export const useEditUserPlanLive = (onError: ErrorHandler['onError']): EditUserPlanLive => {
  const { user } = useCurrentUser();
  const [edit, { loading: saving }] = useMutation(EditUserPlanDocument, {
    onError,
  });

  const editUserPlan = useCallback<EditUserPlanLive['editUserPlan']>(
    (planId, stageBlueprintId) =>
      edit({
        variables: {
          userId: user.id,
          planId,
          stageBlueprintId,
        },
      }),
    [edit, user.id],
  );

  return {
    editUserPlan,
    saving,
  };
};
