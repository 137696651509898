import type { Component, Components } from './components.ts';
import { ComponentType } from './components.ts';

export const componentTypesWithChildren = [
  ComponentType.AgreementsGuard,
  ComponentType.BodyContainer,
  ComponentType.Box,
  ComponentType.CollapsiblePanel,
  ComponentType.ConditionalLogic,
  ComponentType.CourseSubSection,
  ComponentType.Dashboard,
  ComponentType.DataInputTable,
  ComponentType.Link,
  ComponentType.Padding,
  ComponentType.PhoneLink,
  ComponentType.Popover,
  ComponentType.ProvideProps,
  ComponentType.RadioGroupInput,
  ComponentType.RadioGroupInputButton,
  ComponentType.SetUpSiteGuard,
  ComponentType.SignInGuard,
  ComponentType.Stage,
  ComponentType.SubscriptionTagGuard,
  ComponentType.VerifiedEmailAddressGuard,
  ComponentType.WidthContainer,
] as const;

export const componentTypesWithDefaultBooleanValue = [
  ComponentType.BooleanInput,
  ComponentType.CompletableVideoInput,
] as const;

export const componentTypesWithDefaultStringValue = [
  ComponentType.TextareaInput,
  ComponentType.TextInput,
  ComponentType.RadioGroupInput,
] as const;

export const componentTypesWithElementId = [ComponentType.Box, ComponentType.Padding] as const;

const componentTypesWithLabel = [
  ComponentType.BooleanInput,
  ComponentType.Button,
  ComponentType.CompletableVideoInput,
  ComponentType.DataInputTable,
  ComponentType.DateInput,
  ComponentType.DropdownInput,
  ComponentType.LessonNotesField,
  ComponentType.MultipleChoiceInput,
  ComponentType.MultiSelectInput,
  ComponentType.NumberInput,
  ComponentType.TextInput,
  ComponentType.TextareaInput,
  ComponentType.ViewPlanStagesButton,
] as const;

const componentTypesWithName = [
  ComponentType.BooleanInput,
  ComponentType.Button,
  ComponentType.CompletableVideoInput,
  ComponentType.DataInputTable,
  ComponentType.DateInput,
  ComponentType.DropdownInput,
  ComponentType.MultipleChoiceInput,
  ComponentType.MultiSelectInput,
  ComponentType.NumberInput,
  ComponentType.RadioGroupInput,
  ComponentType.StageFileInput,
  ComponentType.TextareaInput,
  ComponentType.TextInput,
] as const;

const componentTypesWithMaxLength = [ComponentType.TextInput, ComponentType.TextareaInput] as const;

const componentTypesWithRequired = [
  ComponentType.BooleanInput,
  ComponentType.CompletableVideoInput,
  ComponentType.DateInput,
  ComponentType.DropdownInput,
  ComponentType.NumberInput,
  ComponentType.RadioGroupInput,
  ComponentType.TextInput,
  ComponentType.TextareaInput,
] as const;

const componentTypesWithSlot = [
  ComponentType.ConditionalLogic,
  ComponentType.SubscriptionTagGuard,
] as const;

const componentTypesWithStringValue = [ComponentType.RadioGroupInputButton] as const;

const componentTypesWithoutWrapper = [
  ComponentType.ConditionalLogic,
  ComponentType.Dashboard,
  ComponentType.Design,
  ComponentType.FormConfirmationMessage,
  ComponentType.FormStep,
  ComponentType.OrganizationPrivacyPolicy,
  ComponentType.OrganizationTermsOfUse,
  ComponentType.Page,
  ComponentType.ProvideProps,
  ComponentType.SignInGuard,
  ComponentType.SitePrivacyPolicy,
  ComponentType.SiteTermsOfUse,
  ComponentType.Stage,
  ComponentType.Text,
] as const;

const componentTypesWithCompletableState = [
  ComponentType.CompletableVideoInput,
  ComponentType.MultipleChoiceInput,
  ComponentType.MultiSelectInput,
];

export const componentTypesWithConditionalStyles = [ComponentType.RadioGroupInputButton] as const;

export type ComponentTypeWithChildren = (typeof componentTypesWithChildren)[number];

export type ComponentWithChildren = Components[ComponentTypeWithChildren];

export const isComponentWithChildren = (component: Component): component is ComponentWithChildren =>
  componentTypesWithChildren.includes(component.type as never);

export const componentChildrenEnabled = (component: ComponentWithChildren): boolean => {
  switch (component.type) {
    case ComponentType.Stage:
      return !!component.enableWrappedStageBody;
    default:
      return true;
  }
};

export type ComponentTypeWithConditionalStyles =
  (typeof componentTypesWithConditionalStyles)[number];

export type ComponentWithConditionalStyles = Components[ComponentTypeWithConditionalStyles];

export const isComponentWithConditionalStyles = (
  component: Component,
): component is ComponentWithConditionalStyles =>
  componentTypesWithConditionalStyles.includes(component.type as never);

export type ComponentTypeWithDefaultBooleanValue =
  (typeof componentTypesWithDefaultBooleanValue)[number];

export type ComponentWithDefaultBooleanValue = Components[ComponentTypeWithDefaultBooleanValue];

export const isComponentWithDefaultBooleanValue = (
  component: Component,
): component is ComponentWithDefaultBooleanValue =>
  componentTypesWithDefaultBooleanValue.includes(component.type as never);

export type ComponentTypeWithDefaultStringValue =
  (typeof componentTypesWithDefaultStringValue)[number];

export type ComponentWithDefaultStringValue = Components[ComponentTypeWithDefaultStringValue];

export const isComponentWithDefaultStringValue = (
  component: Component,
): component is ComponentWithDefaultStringValue =>
  componentTypesWithDefaultStringValue.includes(component.type as never);

export type ComponentTypeWithElementId = (typeof componentTypesWithElementId)[number];

export type ComponentWithElementId = Components[ComponentTypeWithElementId];

export const isComponentWithElementId = (
  component: Component,
): component is ComponentWithElementId =>
  componentTypesWithElementId.includes(component.type as never);

export type ComponentTypeWithLabel = (typeof componentTypesWithLabel)[number];

export type ComponentWithLabel = Components[ComponentTypeWithLabel];

export const isComponentWithLabel = (component: Component): component is ComponentWithLabel =>
  componentTypesWithLabel.includes(component.type as never);

export type ComponentTypeWithMaxLength = (typeof componentTypesWithMaxLength)[number];

export type ComponentWithMaxLength = Components[ComponentTypeWithMaxLength];

export const isComponentWithMaxLength = (
  component: Component,
): component is ComponentWithMaxLength =>
  componentTypesWithMaxLength.includes(component.type as never);

export type ComponentTypeWithName = (typeof componentTypesWithName)[number];

export type ComponentWithName = Components[ComponentTypeWithName];

export const isComponentWithName = (component: Component): component is ComponentWithName =>
  componentTypesWithName.includes(component.type as never);

export type ComponentTypeWithRequired = (typeof componentTypesWithRequired)[number];

export type ComponentWithRequired = Components[ComponentTypeWithRequired];

export const isComponentWithRequired = (component: Component): component is ComponentWithRequired =>
  componentTypesWithRequired.includes(component.type as never);

export type ComponentTypeWithSlot = (typeof componentTypesWithSlot)[number];

export type ComponentWithSlot = Components[ComponentTypeWithSlot];

export const isComponentWithSlot = (component: Component): component is ComponentWithSlot =>
  componentTypesWithSlot.includes(component.type as never);

export type ComponentTypeWithStringValue = (typeof componentTypesWithStringValue)[number];

export type ComponentWithStringValue = Components[ComponentTypeWithStringValue];

export const isComponentWithStringValue = (
  component: Component,
): component is ComponentWithStringValue =>
  componentTypesWithStringValue.includes(component.type as never);

export type ComponentTypeWithoutWrapper = (typeof componentTypesWithoutWrapper)[number];

export type ComponentWithoutWrapper = Components[ComponentTypeWithoutWrapper];

export const isComponentWithoutWrapper = (
  component: Component,
): component is ComponentWithoutWrapper =>
  componentTypesWithoutWrapper.includes(component.type as never);

export type ComponentTypeWithCompletableState = (typeof componentTypesWithCompletableState)[number];

export type ComponentWithCompletableState = Components[ComponentTypeWithCompletableState];

export const isComponentWithCompletableState = (
  component: Component,
): component is ComponentWithCompletableState =>
  componentTypesWithCompletableState.includes(component.type as never);

// An input component is defined as a component that can have a name.
export type InputComponent = Components[ComponentTypeWithName];

export const isInputComponent = (component: Component): component is InputComponent =>
  isComponentWithName(component);
