import { useCallback, useState } from 'react';

type ForceRender = () => void;

export const useForceRender = (): ForceRender => {
  const [, setN] = useState(BigInt(0));
  return useCallback(() => {
    setN((n) => n + BigInt(1));
  }, []);
};
