import { useQuery } from '@apollo/client';
import { useSiteContext } from '../../contexts/SiteContext/SiteContext.tsx';
import type { ErrorHandler } from '../useErrorHandler.tsx';
import type { PublicSitePagesQuery } from './queries.generated.ts';
import { PublicSitePagesDocument } from './queries.generated.ts';

type PublicSitePages = {
  pages: NonNullable<PublicSitePagesQuery['publicSite']>['pages'] | undefined;
  loading: boolean;
};

export const usePublicSitePages = ({
  pathPrefix,
  onError,
}: {
  pathPrefix?: string;
  onError: ErrorHandler['onError'];
}): PublicSitePages => {
  const site = useSiteContext();
  const { data, loading } = useQuery(PublicSitePagesDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: {
      siteId: site.id,
      pathPrefix,
    },
  });

  return {
    pages: data?.publicSite.pages,
    loading,
  };
};
