import type { DocumentDownloadPrompt } from '@wirechunk/lib/api.ts';
import { useCallback } from 'react';
import { useDialog } from '../../../contexts/DialogContext/DialogContext.tsx';
import type { FileDownload } from '../../../hooks/useFileDownload.ts';
import { ParseAndRenderQuillDelta } from '../../RenderQuillDelta/parse-and-render-quill-delta.tsx';

type DocumentDownloadPromptDialog = (
  documentId: string,
  downloadUrl: string,
  downloadName: string,
  promptOptions: Pick<DocumentDownloadPrompt, 'promptHeader' | 'prompt' | 'acceptLabel'>,
) => void;

export const useDocumentDownloadPromptDialog = (
  download: FileDownload['download'],
): DocumentDownloadPromptDialog => {
  const dialog = useDialog();

  return useCallback<DocumentDownloadPromptDialog>(
    (documentId, downloadUrl, downloadName, { promptHeader, prompt, acceptLabel }) => {
      dialog({
        confirm: <ParseAndRenderQuillDelta delta={prompt.delta} />,
        props: {
          header: promptHeader,
          className: 'dialog-width-lg',
          onAccept: () => {
            download(documentId, downloadUrl, downloadName);
          },
          acceptLabel,
          rejectLabel: 'Cancel',
          rejectClassName: 'p-button-text text-color-body mr-3',
        },
      });
    },
    [dialog, download],
  );
};
