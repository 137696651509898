import { useQuery } from '@apollo/client';
import type { Form as ApiForm } from '@wirechunk/lib/api.ts';
import { FormsDocument } from '../../components/mixer/OrganizationSiteForms/queries.generated.ts';
import type { ErrorHandler } from '../useErrorHandler.tsx';

export type Form = Pick<ApiForm, 'id' | 'title'>;

type FormsMinimal = {
  forms: Form[] | null;
  loading: boolean;
};

export const useFormsMinimal = (siteId: string, onError: ErrorHandler['onError']): FormsMinimal => {
  const { data, loading } = useQuery(FormsDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: {
      siteId,
    },
  });

  return {
    forms: data?.forms || null,
    loading,
  };
};
