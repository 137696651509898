import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { VideoComponent } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';

export const Video: FunctionComponent<VideoComponent> = (props) => {
  if (!props.src) {
    return null;
  }

  return (
    <div className={componentClassName(props)}>
      <div className="video-embed-container">
        <iframe src={props.src} allowFullScreen />
      </div>
    </div>
  );
};
