import { RadioCards } from '@radix-ui/themes';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { RadioGroupInputButtonComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { DataSource } from '@wirechunk/lib/mixer/types/components.ts';
import { isString } from 'lodash-es';
import type { FunctionComponent } from 'react';
import { usePropsContext } from '../../../contexts/props-context.ts';
import { useConditionalStylesAttribute } from '../../../hooks/use-conditional-styles-attribute.ts';
import { useMixerContextData } from '../../../hooks/use-mixer-context-data.tsx';
import { RenderMixerChildren } from '../../RenderMixerChildren.tsx';

export const RadioGroupInputButton: FunctionComponent<RadioGroupInputButtonComponent> = (props) => {
  const propsContext = usePropsContext();
  const contextData = useMixerContextData();
  const conditionalStylesAttr = useConditionalStylesAttribute(props, contextData);

  const value =
    props.value?.type === DataSource.Direct
      ? props.value.value
      : props.value?.name && propsContext[props.value.name];

  if (!isString(value)) {
    return null;
  }

  return (
    <RadioCards.Item {...conditionalStylesAttr} className={componentClassName(props)} value={value}>
      <RenderMixerChildren>{props.children}</RenderMixerChildren>
    </RadioCards.Item>
  );
};
