import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { DropdownInputComponent } from '@wirechunk/lib/mixer/types/components.ts';
import type { ValidInputComponent } from '@wirechunk/lib/mixer/utils.ts';
import { stringOrDefaultNull } from '@wirechunk/lib/strings.ts';
import { clsx } from 'clsx';
import { isNumber, isString } from 'lodash-es';
import type { DropdownChangeEvent } from 'primereact/dropdown';
import { Dropdown } from 'primereact/dropdown';
import type { FunctionComponent } from 'react';
import { useCallback } from 'react';
import { useInputDataContext } from '../../../contexts/InputDataContext.tsx';
import { useInputId } from '../../../hooks/use-input-id.ts';
import { InputNotice, NoticeSeverity } from '../../InputNotice/InputNotice.tsx';
import { withValidInputComponent } from '../../mixer-hocs/with-valid-input-component.tsx';

const GuardedDropdownInput: FunctionComponent<ValidInputComponent<DropdownInputComponent>> = (
  props,
) => {
  const { getValue, setValue, getValidationError } = useInputDataContext(props);
  const inputId = useInputId(props);
  const onChange = useCallback(
    ({ value }: DropdownChangeEvent) => {
      if (isString(value) || isNumber(value) || value === null) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setValue(props, value);
      }
    },
    [props, setValue],
  );

  const validationError = getValidationError(props);

  // TODO: Support number values.
  const inputValue = stringOrDefaultNull(getValue(props));

  return (
    <div className={componentClassName(props)}>
      {props.label && (
        <label className="input-label block" htmlFor={inputId}>
          {props.label}
        </label>
      )}
      <Dropdown
        inputId={inputId}
        className={clsx('w-full', validationError && 'p-invalid')}
        options={props.options || []}
        optionLabel="value"
        optionValue="value"
        placeholder={props.placeholder || undefined}
        value={inputValue}
        onChange={onChange}
        showClear={!props.required && !!props.clearable}
      />
      {validationError && (
        <InputNotice severity={NoticeSeverity.Error}>{validationError}</InputNotice>
      )}
    </div>
  );
};

export const DropdownInput = withValidInputComponent<DropdownInputComponent>(GuardedDropdownInput);
