import { clsx } from 'clsx';
import type { FunctionComponent } from 'react';

type NoneLabelProps = {
  className?: string | null;
};

export const NoneLabel: FunctionComponent<NoneLabelProps> = ({ className }) => (
  <span className={clsx('text-color-muted', className)}>(None)</span>
);
