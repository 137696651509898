import { useEffect, useLayoutEffect, useRef } from 'react';

// delay is in milliseconds.
export const useInterval = (
  callback: (() => void | Promise<void>) | null,
  delay: number | null,
): void => {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return;
    }

    const id = setInterval(() => {
      void savedCallback.current?.();
    }, delay);

    return () => {
      clearInterval(id);
    };
  }, [delay]);
};
