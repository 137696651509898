import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { OrganizationAgencyPrimaryPhoneComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { PhoneNumberFormat } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent, ReactNode } from 'react';
import { Fragment } from 'react';
import { useSiteContext } from '../../contexts/SiteContext/SiteContext.tsx';

const formatPhoneNumber = (
  phoneNumberString: string,
  format: PhoneNumberFormat | null | undefined,
): ReactNode => {
  const digits = phoneNumberString.replace(/\D/g, '');
  if (digits.length === 10) {
    switch (format) {
      case PhoneNumberFormat.Verbose:
        return (
          <Fragment>
            ({digits.slice(0, 3)})&nbsp;{digits.slice(3, 6)}-{digits.slice(6, 10)}
          </Fragment>
        );
      case PhoneNumberFormat.Dashes:
        return `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
      case PhoneNumberFormat.Spaces:
        return (
          <Fragment>
            {digits.slice(0, 3)}&nbsp;{digits.slice(3, 6)}&nbsp;{digits.slice(6, 10)}
          </Fragment>
        );
      default:
      // Fall through.
    }
  }
  return phoneNumberString;
};

export const OrganizationAgencyPrimaryPhone: FunctionComponent<
  OrganizationAgencyPrimaryPhoneComponent
> = (props) => {
  const { agencyPrimaryPhone } = useSiteContext();

  return (
    <span className={componentClassName(props)}>
      {formatPhoneNumber(agencyPrimaryPhone || '', props.format)}
    </span>
  );
};
