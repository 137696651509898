import type { StageComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { parseComponents } from '@wirechunk/lib/mixer/utils.ts';
import type { FunctionComponent } from 'react';
import { Fragment, useMemo } from 'react';
import type { StageContext } from '../../../contexts/StageContext/StageContext.ts';
import {
  StagesRegistryContextProvider,
  useStagesRegistryContext,
} from '../../../contexts/StagesRegistryContext/stages-registry-context.tsx';
import { useCurrentDate } from '../../../hooks/useCurrentDate.ts';
import { useCurrentUserPlan } from '../../../hooks/useCurrentUserPlan/useCurrentUserPlan.ts';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { RenderMixerChildren } from '../../RenderMixerChildren.tsx';
import { Spinner } from '../../spinner/spinner.tsx';
import { Stage as RenderStage } from '../../Stage/Stage.tsx';

type GuardedStageProps = Omit<StageComponent, 'planId'> & {
  planId: string;
};

const GuardedStage: FunctionComponent<GuardedStageProps> = (props) => {
  const { onError, ErrorMessage } = useErrorHandler();
  const stagesRegistryContext = useStagesRegistryContext();
  const date = useCurrentDate();
  const { userPlan, loading } = useCurrentUserPlan(props.planId, onError);

  const stageBlueprint = useMemo<StageContext['stageBlueprint'] | null>(
    () =>
      userPlan
        ? {
            ...userPlan.stageBlueprint,
            components: parseComponents(userPlan.stageBlueprint.components),
          }
        : null,
    [userPlan],
  );

  if (loading) {
    return <Spinner py="3" />;
  }

  if (!userPlan || !stageBlueprint) {
    return null;
  }

  return (
    <Fragment>
      <ErrorMessage />
      {stagesRegistryContext ? (
        <RenderStage
          date={date}
          userPlan={userPlan}
          stageBlueprint={stageBlueprint}
          onError={onError}
        >
          {props.enableWrappedStageBody && props.children && (
            <RenderMixerChildren>{props.children}</RenderMixerChildren>
          )}
        </RenderStage>
      ) : (
        <StagesRegistryContextProvider>
          <RenderStage
            date={date}
            userPlan={userPlan}
            stageBlueprint={stageBlueprint}
            onError={onError}
          >
            {props.enableWrappedStageBody && props.children && (
              <RenderMixerChildren>{props.children}</RenderMixerChildren>
            )}
          </RenderStage>
        </StagesRegistryContextProvider>
      )}
    </Fragment>
  );
};

export const Stage: FunctionComponent<StageComponent> = (props) => {
  const { planId } = props;

  if (!planId) {
    return null;
  }

  return <GuardedStage {...props} planId={planId} />;
};
