import type { FunctionComponent } from 'react';
import { memo } from 'react';
import { useParsedMixerComponents } from '../hooks/useParsedMixerComponents.ts';
import { RenderComponentsStyled } from './RenderComponentsStyled.tsx';

type ParseAndRenderComponentsProps = {
  componentsJSON: string | null | undefined;
};

const Component: FunctionComponent<ParseAndRenderComponentsProps> = ({ componentsJSON }) => {
  const components = useParsedMixerComponents(componentsJSON);

  return <RenderComponentsStyled components={components} />;
};

export const ParseAndRenderComponents = memo(Component);
