import { useCallback, useState } from 'react';

export type FileDownload = {
  // URL is used for the download. The trackingId is only for tracking the pending state.
  download: (trackingId: string, url: string, downloadName: string) => void;
  downloadingFileIds: string[];
};

export const useFileDownload = (): FileDownload => {
  const [downloadingFileIds, setDownloadingFileIds] = useState<string[]>([]);

  const download = useCallback<FileDownload['download']>((trackingId, url, downloadName) => {
    // Download the specified file while staying on the same page (without opening a tab).
    // This is done by creating a hidden link element and clicking it.
    // The link element is removed after the download is complete.
    const link = document.createElement('a');
    link.href = url;
    link.download = downloadName;
    link.style.display = 'none';
    document.body.appendChild(link);
    setDownloadingFileIds((ids) => [...ids, trackingId]);
    link.click();
    document.body.removeChild(link);
    setDownloadingFileIds((ids) => ids.filter((i) => i !== trackingId));
  }, []);

  return {
    download,
    downloadingFileIds,
  };
};
