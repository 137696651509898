import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { CollapsiblePanelComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { Accordion, AccordionTab } from 'primereact/accordion';
import type { FunctionComponent } from 'react';
import { RenderComponent } from '../RenderComponent.tsx';

export const CollapsiblePanel: FunctionComponent<CollapsiblePanelComponent> = (props) => {
  if (!props.children) {
    // Don't render anything if there won't be anything to see when the panel is expanded.
    return null;
  }

  return (
    <Accordion className={componentClassName(props)}>
      <AccordionTab header={props.header || ''}>
        {props.children.map((child) => (
          <RenderComponent key={child.id} {...child} />
        ))}
      </AccordionTab>
    </Accordion>
  );
};
