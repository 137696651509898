import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { NumberInputComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { NumberInputFormat } from '@wirechunk/lib/mixer/types/components.ts';
import type { ValidInputComponent } from '@wirechunk/lib/mixer/utils.ts';
import { clsx } from 'clsx';
import { isNil, isNumber } from 'lodash-es';
import { InputNumber } from 'primereact/inputnumber';
import type { FunctionComponent } from 'react';
import { useInputDataContext } from '../../../contexts/InputDataContext.tsx';
import { useInputId } from '../../../hooks/use-input-id.ts';
import { InputNotice, NoticeSeverity } from '../../InputNotice/InputNotice.tsx';
import { withValidInputComponent } from '../../mixer-hocs/with-valid-input-component.tsx';

const GuardedNumberInput: FunctionComponent<ValidInputComponent<NumberInputComponent>> = (
  props,
) => {
  const { getValue, setValue, getValidationError } = useInputDataContext(props);
  const inputId = useInputId(props);

  const validationError = getValidationError(props);

  let inputValue = getValue(props);
  // When entering a negative number, the input value will be the string '-' temporarily.
  // TODO: Change this to use local state the way the DateInput3FieldsStyle component does.
  if (!isNumber(inputValue) && !isNil(inputValue) && inputValue !== '-') {
    inputValue = null;
  }

  // TODO: Rewrite this using a type=text input.
  return (
    <div className={componentClassName(props)}>
      {props.label && (
        <label className="input-label block" htmlFor={inputId}>
          {props.label}
        </label>
      )}
      <InputNumber
        inputId={inputId}
        className={clsx(validationError && 'p-invalid')}
        inputClassName="w-full"
        placeholder={props.placeholder || undefined}
        mode={props.format === NumberInputFormat.Currency ? 'currency' : 'decimal'}
        currency={props.format === NumberInputFormat.Currency ? 'USD' : undefined}
        maxFractionDigits={
          props.maxDecimalDigits ?? (props.format === NumberInputFormat.Currency ? 2 : 0)
        }
        // Technically, value can be the string '-' and this component accepts it.
        value={inputValue as number | null}
        onChange={(e) => {
          setValue(props, e.value);
        }}
      />
      {validationError && (
        <InputNotice severity={NoticeSeverity.Error}>{validationError}</InputNotice>
      )}
    </div>
  );
};

export const NumberInput = withValidInputComponent<NumberInputComponent>(GuardedNumberInput);
