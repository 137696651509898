import { isFunction, isPlainObject } from 'lodash-es';

type GoogleTagManagerData =
  // The typical event.
  | { event: string; [key: string]: unknown }
  // A dataLayer variable that our Tag Manager will recognize and use as the user ID.
  | { user_id: string };

type GoogleTagManager = (data: GoogleTagManagerData) => void;

type TikTokPixel = {
  track: (eventName: string, data: { content_id?: string } & Record<string, unknown>) => void;
};

const isTikTokPixel = (pixel: unknown): pixel is TikTokPixel =>
  isPlainObject(pixel) && isFunction((pixel as Record<string, unknown>).track);

type FacebookPixel = (
  track: 'track' | 'trackCustom',
  eventName: string,
  data: { content_ids?: string[] } & Record<string, unknown>,
) => void;

declare global {
  interface Window {
    ttq?: TikTokPixel;
    fbq?: FacebookPixel;
    dataLayer?: unknown[];
  }
}

export const triggerGoogleTagManagerEvent: GoogleTagManager = (data) => {
  if (Array.isArray(window.dataLayer)) {
    window.dataLayer.push(data);
  }
};

export const triggerTikTokEvent: TikTokPixel['track'] = (eventName, data) => {
  const { ttq } = window;
  if (isTikTokPixel(ttq)) {
    ttq.track(eventName, data);
  }
};

export const triggerFacebookEvent: FacebookPixel = (track, eventName, data) => {
  if (isFunction(window.fbq)) {
    window.fbq(track, eventName, data);
  }
};
