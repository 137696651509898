import type {
  DataTableRow,
  TimeDuration,
  UploadedFile,
} from '@wirechunk/schemas/context-data/context-data';
import { isNumber, isPlainObject, isString } from 'lodash-es';
import type { RemapPropertiesToUnknown } from '../util-types.ts';
import type { InputComponent } from './types/categories.ts';
import { isComponentWithChildren, isInputComponent } from './types/categories.ts';
import type { Component, Name, NonEmptyChildren, Styles } from './types/components.ts';
import {
  ComponentType,
  DataSource,
  PaddingAmount,
  TextInputComponentFormat,
  Width,
} from './types/components.ts';

export const componentTypes = Object.values(ComponentType);

export const isComponentType = (value: unknown): value is ComponentType =>
  componentTypes.includes(value as never);

export const componentHasChildren = (
  component: Component,
): component is Component & NonEmptyChildren =>
  isComponentWithChildren(component) && !!component.children?.length;

type UnknownTimeDurationValue = RemapPropertiesToUnknown<TimeDuration>;

export const isTimeDurationValue = (value: unknown): value is TimeDuration =>
  isPlainObject(value) &&
  ((value as UnknownTimeDurationValue).hours === null ||
    isNumber((value as UnknownTimeDurationValue).hours)) &&
  ((value as UnknownTimeDurationValue).minutes === null ||
    isNumber((value as UnknownTimeDurationValue).minutes));

const widths = Object.values(Width);

export const isWidth = (value: unknown): value is Width => widths.includes(value as never);

const textInputComponentFormats = Object.values(TextInputComponentFormat);

export const isTextInputComponentFormat = (value: unknown): value is TextInputComponentFormat =>
  textInputComponentFormats.includes(value as never);

export const isDataTableRow = (value: unknown): value is DataTableRow =>
  isPlainObject(value) &&
  isString((value as RemapPropertiesToUnknown<DataTableRow>).id) &&
  isPlainObject((value as RemapPropertiesToUnknown<DataTableRow>).data);

export const isDataTableRowArray = (value: unknown): value is DataTableRow[] =>
  Array.isArray(value) && value.every(isDataTableRow);

export const dataTableRowArrayOrDefaultEmpty = (value: unknown): DataTableRow[] =>
  isDataTableRowArray(value) ? value : [];

export const isUploadedFile = (value: unknown): value is UploadedFile =>
  isPlainObject(value) && isString((value as RemapPropertiesToUnknown<UploadedFile>).fileId);

export const replaceComponentById = (
  components: Component[],
  replacementComponent: Component,
): Component[] => {
  if (!components.length) {
    return [];
  }
  return components.map((component) => {
    if (component.id === replacementComponent.id) {
      return replacementComponent;
    }
    if (componentHasChildren(component)) {
      return {
        ...component,
        children: replaceComponentById(component.children, replacementComponent),
      };
    }
    return component;
  });
};

// Extracts the component specified by ID, searching through children recursively.
// Returns the new array with the specified component removed and the extracted component, if found.
export const pullComponentById = (
  components: Component[],
  id: string,
): [Component[], Component | null] => {
  let extractedComponent: Component | null = null;

  const newComponents = components
    .map((component) => {
      if (component.id === id) {
        extractedComponent = component;
        return null;
      }
      if (extractedComponent) {
        return component;
      }
      if (componentHasChildren(component)) {
        let newChildren: Component[];
        [newChildren, extractedComponent] = pullComponentById(component.children, id);
        if (extractedComponent) {
          return {
            ...component,
            children: newChildren,
          };
        }
      }
      return component;
    })
    .filter(Boolean) as Component[];

  return [newComponents, extractedComponent];
};

export const removeComponentById = (components: Component[], id: string): Component[] =>
  components
    .map((component) => {
      if (component.id === id) {
        return null;
      }
      if (componentHasChildren(component)) {
        return {
          ...component,
          children: removeComponentById(component.children, id),
        };
      }
      return component;
    })
    .filter(Boolean) as Component[];

// Find the reference beforeId component deeply (looking within children) and insert the new component before it.
// Return a new array with the new component inserted.
export const insertBeforeComponent = (
  components: Component[],
  beforeId: string,
  newComponent: Component,
): Component[] =>
  components.reduce<Component[]>((newComponents, component) => {
    if (component.id === beforeId) {
      newComponents.push(newComponent);
    }
    if (componentHasChildren(component)) {
      newComponents.push({
        ...component,
        children: insertBeforeComponent(component.children, beforeId, newComponent),
      });
    } else {
      newComponents.push(component);
    }
    return newComponents;
  }, []);

// Find the reference afterId component deeply (looking within children) and insert the new components after it.
// Return a new array with the new components inserted.
export const insertManyAfterComponent = (
  components: Component[],
  afterId: string,
  newComponents: Component[],
): Component[] =>
  components.reduce<Component[]>((acc, component) => {
    if (componentHasChildren(component)) {
      acc.push({
        ...component,
        children: insertManyAfterComponent(component.children, afterId, newComponents),
      });
    } else {
      acc.push(component);
    }
    if (component.id === afterId) {
      acc.push(...newComponents);
    }
    return acc;
  }, []);

// Find the reference afterId component deeply (looking within children) and insert the new component after it.
// Return a new array with the new component inserted.
export const insertAfterComponent = (
  components: Component[],
  afterId: string,
  newComponent: Component,
): Component[] => insertManyAfterComponent(components, afterId, [newComponent]);

export const insertChildIntoComponent = (
  components: Component[],
  parentId: string,
  child: Component,
): Component[] =>
  components.map((component): Component => {
    if (component.id === parentId && isComponentWithChildren(component)) {
      return {
        ...component,
        children: [...(component.children || []), child],
      };
    }
    if (componentHasChildren(component)) {
      return {
        ...component,
        children: insertChildIntoComponent(component.children, parentId, child),
      };
    }
    return component;
  });

// Inserts the child component as the first child of the component specified by parentId.
export const insertChildIntoComponentStart = (
  components: Component[],
  parentId: string,
  child: Component,
): Component[] =>
  components.map((component): Component => {
    if (component.id === parentId && isComponentWithChildren(component)) {
      return {
        ...component,
        children: [child, ...(component.children || [])],
      };
    }
    if (componentHasChildren(component)) {
      return {
        ...component,
        children: insertChildIntoComponentStart(component.children, parentId, child),
      };
    }
    return component;
  });

export const findComponentById = (components: Component[], id: string): Component | null =>
  components.reduce<Component | null>((foundComponent, component) => {
    if (foundComponent) {
      return foundComponent;
    }
    if (component.id === id) {
      return component;
    }
    if (componentHasChildren(component)) {
      return findComponentById(component.children, id);
    }
    return null;
  }, null);

export const findComponentByType = <C extends Component>(
  components: Component[],
  type: C['type'],
): C | null =>
  components.reduce<C | null>((foundComponent, component) => {
    if (foundComponent) {
      return foundComponent;
    }
    if (component.type === type) {
      return component as C;
    }
    if (componentHasChildren(component)) {
      return findComponentByType(component.children, type);
    }
    return null;
  }, null);

export const findRecursiveInComponents = (
  components: Component[],
  predicate: (comp: Component) => boolean,
): Component | null => {
  for (const comp of components) {
    if (predicate(comp)) {
      return comp;
    }
    if (comp.children) {
      const found = findRecursiveInComponents(comp.children, predicate);
      if (found) {
        return found;
      }
    }
  }
  return null;
};

const componentHasDeepChild = (components: Component[], id: string): boolean =>
  components.reduce<boolean>((hasChild, component) => {
    if (hasChild) {
      return hasChild;
    }
    if (component.id === id) {
      return true;
    }
    if (componentHasChildren(component)) {
      return componentHasDeepChild(component.children, id);
    }
    return false;
  }, false);

// Returns whether the component specified by childId is a deep child of the component specified by parentId.
export const componentIsDeepChildOf = (
  components: Component[],
  parentId: string,
  childId: string,
): boolean => {
  const parent = findComponentById(components, parentId);
  if (parent && componentHasChildren(parent)) {
    return componentHasDeepChild(parent.children, childId);
  }
  return false;
};

export type ValidInputComponent<T extends InputComponent = InputComponent> = T & {
  name: NonNullable<Name['name']>;
};

// isValidInputComponent returns true if and only if the given component is an InputComponent that
// has a name (not just defined but non-empty).
export const isValidInputComponent = (component: Component): component is ValidInputComponent =>
  isInputComponent(component) && !!component.name;

const isMixerComponent = (value: unknown): value is Component =>
  isPlainObject(value) && isString((value as RemapPropertiesToUnknown<Component>).type);

export const isMixerComponentsArray = (value: unknown): value is Component[] =>
  Array.isArray(value) && value.every(isMixerComponent);

// TODO: This is too simplistic.
export const isStyles = (value: unknown): value is Styles => isPlainObject(value);

/**
 * Parses and returns the JSON string if it is a valid components array but otherwise returns an empty array.
 * Note the string must not be empty or an error will be thrown.
 */
export const parseComponents = (json: string): Component[] => {
  const cs = JSON.parse(json) as unknown;
  if (isMixerComponentsArray(cs)) {
    return cs;
  }
  return [];
};

/**
 * Parses and returns the JSON string if it is a valid components array but otherwise returns null.
 */
export const parseOptionalComponents = (json: string | null | undefined): Component[] | null => {
  if (json) {
    const cs = JSON.parse(json) as unknown;
    if (isMixerComponentsArray(cs)) {
      return cs;
    }
  }
  return null;
};

export const parseStyles = (json: string): Styles => {
  if (json) {
    const styles = JSON.parse(json) as unknown;
    if (isStyles(styles)) {
      return styles;
    }
  }
  return {};
};

const paddingAmounts = Object.values(PaddingAmount);

export const isPaddingAmount = (value: unknown): value is PaddingAmount =>
  paddingAmounts.includes(value as never);

export const isDataSource = (value: unknown): value is DataSource =>
  Object.values(DataSource).includes(value as never);
