/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.ts';

export type EditSiteMutationVariables = Types.Exact<{
  input: Types.EditSiteInput;
}>;

export type EditSiteMutation = {
  editSite:
    | { __typename: 'AuthorizationError'; message: string }
    | {
        __typename: 'EditSiteSuccessResult';
        site: {
          __typename: 'Site';
          id: string;
          domain: string;
          name: string;
          orgId?: string | null;
          logoUrl?: string | null;
          headEndCode?: string | null;
          googlePlaceId?: string | null;
          googleTagManagerId?: string | null;
          metaPixelId?: string | null;
          agentFirstName?: string | null;
          agentLastName?: string | null;
          agentPhotoUrl?: string | null;
          agencyPrimaryPhone?: string | null;
          addressLine1?: string | null;
          addressLine2?: string | null;
          addressCity?: string | null;
          addressState?: string | null;
          addressZip?: string | null;
          useCustomPrivacyPolicy: boolean;
          useCustomTermsOfUse: boolean;
          privacyPolicy?: { __typename: 'Delta'; delta: string } | null;
          termsOfUse?: { __typename: 'Delta'; delta: string } | null;
        };
      }
    | { __typename: 'GenericInternalError'; message: string }
    | { __typename: 'GenericUserError'; message: string };
};

export const EditSiteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditSite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EditSiteInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editSite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EditSiteSuccessResult' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'site' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'orgId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'headEndCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'googlePlaceId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'googleTagManagerId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'metaPixelId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'agentFirstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'agentLastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'agentPhotoUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'agencyPrimaryPhone' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'addressLine1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'addressLine2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'addressCity' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'addressState' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'addressZip' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'privacyPolicy' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'delta' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'useCustomPrivacyPolicy' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'termsOfUse' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'delta' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'useCustomTermsOfUse' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Error' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditSiteMutation, EditSiteMutationVariables>;
