import { useMutation } from '@apollo/client';
import type { ContextData } from '@wirechunk/schemas/context-data/context-data';
import { Button } from 'primereact/button';
import type { FunctionComponent } from 'react';
import { Fragment, useMemo } from 'react';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { useParsedMixerComponents } from '../../../hooks/useParsedMixerComponents.ts';
import { tryParseObject } from '../../../util/json.ts';
import { EditProps } from '../../edit-props.tsx';
import { EditPagePropsDocument } from './mutations.generated.ts';

type CustomizePageDesignProps = {
  pageId: string;
  props: string | null | undefined;
  propsSetupComponents: string;
  onEdited: () => void;
  onCancel: () => void;
};

export const CustomizePageDesign: FunctionComponent<CustomizePageDesignProps> = ({
  pageId,
  props,
  propsSetupComponents,
  onEdited,
  onCancel,
}) => {
  const { onError, ErrorMessage } = useErrorHandler();
  const setupComponents = useParsedMixerComponents(propsSetupComponents);
  const [editPageProps] = useMutation(EditPagePropsDocument, {
    onError,
    onCompleted: () => {
      onEdited();
    },
  });
  const existingParsedProps = useMemo<ContextData | undefined>(() => {
    if (props) {
      return tryParseObject(props) as ContextData;
    }
    return undefined;
  }, [props]);

  return (
    <Fragment>
      <ErrorMessage />
      <EditProps
        setupComponents={setupComponents}
        existingProps={existingParsedProps}
        submitButtonLabel="Save"
        onSubmit={(props) => {
          void editPageProps({
            variables: {
              pageId,
              props: JSON.stringify(props),
            },
          });
        }}
        cancelButton={
          <Button label="Cancel" className="p-button-text text-color-body" onClick={onCancel} />
        }
      />
    </Fragment>
  );
};
