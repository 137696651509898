import { useQuery } from '@apollo/client';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { MultiOrgViewComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { sortBy } from 'lodash-es';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import type { FunctionComponent } from 'react';
import { useMemo } from 'react';
import { useCurrentUser } from '../../../contexts/CurrentUserContext/CurrentUserContext.tsx';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { useTableRowExpansion } from '../../../hooks/useTableRowExpansion.ts';
import { OrgDetails } from './org-details.tsx';
import type { MultiOrgViewQuery } from './queries.generated.ts';
import { MultiOrgViewDocument } from './queries.generated.ts';

type Agency = MultiOrgViewQuery['multiOrgView'][number];

export const MultiOrgView: FunctionComponent<MultiOrgViewComponent> = (props) => {
  const { user } = useCurrentUser();
  const organizationId = user.org.id;
  const { onError, ErrorMessage } = useErrorHandler();
  const { data, loading } = useQuery(MultiOrgViewDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: {
      organizationId,
    },
  });
  const { expandedRows, onRowToggle } = useTableRowExpansion();

  const agencies = useMemo<Agency[]>(() => {
    if (data) {
      // The tilde '~' with the localeCompare will place agencies with no primary user at the end.
      return sortBy(data.multiOrgView, (org) => org.primaryUser?.displayName || '~').filter(
        (org) => org.id !== organizationId,
      );
    }
    return [];
  }, [data, organizationId]);

  const sequenceIdsToShowProgress =
    props.showUserTrainingProgress && !!props.userTrainingProgressContentPlanIds?.length
      ? props.userTrainingProgressContentPlanIds
      : null;
  const courseIdsToShowProgress =
    props.showUserTrainingProgress && props.userTrainingProgressCourseIds?.length
      ? props.userTrainingProgressCourseIds
      : null;

  return (
    <div className={componentClassName(props)}>
      <ErrorMessage />
      <DataTable
        dataKey="id"
        value={agencies}
        loading={loading}
        tableClassName="font-size-base"
        expandedRows={expandedRows}
        rowExpansionTemplate={(agency) => (
          <OrgDetails
            agency={agency}
            sequenceIdsToShowProgress={sequenceIdsToShowProgress}
            courseIdsToShowProgress={courseIdsToShowProgress}
          />
        )}
        onRowToggle={onRowToggle}
        emptyMessage="No data found"
        lazy
      >
        {(courseIdsToShowProgress || sequenceIdsToShowProgress) && (
          <Column expander className="table-left-action-column" />
        )}
        <Column
          header="Agent"
          body={(agency: Agency) => {
            if (agency.primaryUser) {
              return (
                <div className="flex flex-column gap-1">
                  <span>{agency.primaryUser.displayName}</span>
                  <span className="text-sm text-color-muted">{agency.primaryUser.email}</span>
                </div>
              );
            }
            return '(No primary user)';
          }}
        />
        <Column header="Active members" body={(agency: Agency) => agency.members.length} />
      </DataTable>
    </div>
  );
};
