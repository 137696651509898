// The GraphQL API may include a `code` property in an `extensions` object. These are some of the codes
// that the server may set in the extensions object in addition to the built-in ApolloServerErrorCode values.
// We don't want this enum to get large as it's meant to include only the common codes that a client may
// want to handle specially.
export enum ApiErrorCode {
  EmailNotVerified = 'EMAIL_NOT_VERIFIED',
  NotFound = 'NOT_FOUND',
  Unauthenticated = 'UNAUTHENTICATED',
  Unauthorized = 'UNAUTHORIZED',
}
