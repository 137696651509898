// Mobile first... these sizes are lower-bounds.
// These correspond to Radix Themes breakpoints, except for xxl.
// See https://www.radix-ui.com/themes/docs/theme/breakpoints
export const breakpoints = Object.freeze({
  xxl: 2000,
  xl: 1640,
  lg: 1280,
  md: 1024,
  sm: 768,
});

export type Breakpoint = keyof typeof breakpoints;
