export type Address = {
  addressLine1: string | null | undefined;
  addressLine2: string | null | undefined;
  addressCity: string | null | undefined;
  addressState: string | null | undefined;
  addressZip: string | null | undefined;
};

// Returns an address with as many fields as are defined in the object that is passed in. Returns null if all fields are
// empty or null or undefined.
export const serializeAddress = ({
  addressLine1,
  addressLine2,
  addressCity,
  addressState,
  addressZip,
}: Address): string | null => {
  let address = addressLine1 || '';
  if (addressLine2) {
    if (address) {
      address += `, ${addressLine2}`;
    } else {
      address = addressLine2;
    }
  }
  if (addressCity) {
    if (address) {
      address += `, ${addressCity}`;
    } else {
      address = addressCity;
    }
  }
  if (addressState) {
    if (address) {
      address += `, ${addressState}`;
    } else {
      address = addressState;
    }
  }
  if (addressZip) {
    if (address) {
      address += `, ${addressZip}`;
    } else {
      address = addressZip;
    }
  }
  return address || null;
};
