import type { QueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { Flex } from '@radix-ui/themes';
import { FormSubmissionActionType } from '@wirechunk/lib/api.ts';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import type { FunctionComponent } from 'react';
import { Fragment, useEffect, useRef, useState } from 'react';
import type { FormSubmissionActionFieldsFragment } from '../../hooks/use-form-submission-actions/fragments.generated.ts';
import { useFormSubmissionActions } from '../../hooks/use-form-submission-actions/use-form-submission-actions.ts';
import { useErrorHandler } from '../../hooks/useErrorHandler.tsx';
import { BasicIconButton } from '../basic-icon-button/basic-icon-button.tsx';
import { Spinner } from '../spinner/spinner.tsx';
import {
  CreateSubmissionActionDocument,
  DeleteSubmissionActionDocument,
  EditSubmissionActionDocument,
} from './mutations.generated.ts';

type EmailSubmissionActionFormProps = {
  action?: FormSubmissionActionFieldsFragment;
  saving: boolean;
  onSave: (data: { emailAddress: string; emailSubject: string }) => void;
  onCancel: () => void;
};

const EmailSubmissionActionForm: FunctionComponent<EmailSubmissionActionFormProps> = ({
  action,
  saving,
  onSave,
  onCancel,
}) => {
  const [emailAddress, setEmailAddress] = useState(action?.emailAddress || '');
  const [emailSubject, setEmailSubject] = useState(action?.emailSubject || '');

  useEffect(() => {
    setEmailAddress(action?.emailAddress || '');
    setEmailSubject(action?.emailSubject || '');
  }, [action]);

  const handleSave = () => {
    // TODO: Validate.
    onSave({ emailAddress, emailSubject });
  };

  return (
    <Fragment>
      <div className="input-field">
        <label htmlFor={`submissionActionEmailAddress${action?.id || 'new'}`}>Email address</label>
        <InputText
          id={`submissionActionEmailAddress${action?.id || 'new'}`}
          className="w-full"
          value={emailAddress}
          onChange={(e) => {
            setEmailAddress(e.target.value.trim());
          }}
        />
      </div>
      <div className="flex justify-content-end gap-3">
        <Button label="Cancel" severity="secondary" disabled={saving} onClick={onCancel} />
        <Button label="Save" disabled={saving} onClick={handleSave} />
      </div>
    </Fragment>
  );
};

type EmailSubmissionActionProps = {
  action: FormSubmissionActionFieldsFragment;
  mode: 'view' | 'edit';
  saving: boolean;
  onSave: (data: { emailAddress: string; emailSubject: string }) => void;
  onCancel: () => void;
};

const EmailSubmissionAction: FunctionComponent<EmailSubmissionActionProps> = ({
  action,
  mode,
  saving,
  onSave,
  onCancel,
}) => {
  if (mode === 'view') {
    return (
      <Fragment>
        <div>
          <span>Email address:</span> <span className="font-medium">{action.emailAddress}</span>
        </div>
        {action.emailSubject && (
          <div>
            <span>Email subject:</span> <span className="font-medium">{action.emailSubject}</span>
          </div>
        )}
      </Fragment>
    );
  }

  return (
    <EmailSubmissionActionForm
      action={action}
      saving={saving}
      onSave={onSave}
      onCancel={onCancel}
    />
  );
};

type WebhookSubmissionActionFormProps = {
  action?: FormSubmissionActionFieldsFragment;
  saving: boolean;
  onSave: (data: { webhookUrl: string }) => void;
  onCancel: () => void;
};

const WebhookSubmissionActionForm: FunctionComponent<WebhookSubmissionActionFormProps> = ({
  action,
  saving,
  onSave,
  onCancel,
}) => {
  const [webhookUrl, setWebhookUrl] = useState(action?.webhookUrl || '');

  useEffect(() => {
    setWebhookUrl(action?.webhookUrl || '');
  }, [action]);

  const handleSave = () => {
    // TODO: Validate.
    onSave({ webhookUrl });
  };

  return (
    <Fragment>
      <div className="input-field">
        <label htmlFor={`submissionActionWebhookUrl${action?.id || 'new'}`}>Webhook URL</label>
        <InputText
          id={`submissionActionWebhookUrl${action?.id || 'new'}`}
          className="w-full"
          value={webhookUrl}
          onChange={(e) => {
            setWebhookUrl(e.target.value.trim());
          }}
        />
      </div>
      <div className="flex justify-content-end gap-3">
        <Button label="Cancel" severity="secondary" disabled={saving} onClick={onCancel} />
        <Button label="Save" disabled={saving} onClick={handleSave} />
      </div>
    </Fragment>
  );
};

type WebhookSubmissionActionProps = {
  action: FormSubmissionActionFieldsFragment;
  mode: 'view' | 'edit';
  saving: boolean;
  onSave: (data: { webhookUrl: string }) => void;
  onCancel: () => void;
};

const WebhookSubmissionAction: FunctionComponent<WebhookSubmissionActionProps> = ({
  action,
  mode,
  saving,
  onSave,
  onCancel,
}) => {
  if (mode === 'view') {
    return (
      <Fragment>
        <div>
          <span>Webhook URL:</span> <span className="font-medium">{action.webhookUrl}</span>
        </div>
      </Fragment>
    );
  }

  return (
    <WebhookSubmissionActionForm
      action={action}
      saving={saving}
      onSave={onSave}
      onCancel={onCancel}
    />
  );
};

type CreateNotificationProps = {
  formId: string;
  type: FormSubmissionActionType;
  // The queries to refetch after creating a submission action.
  refetchQueries: QueryOptions[];
  onSaved: () => void;
  onCancel: () => void;
};

const CreateNotification: FunctionComponent<CreateNotificationProps> = ({
  formId,
  type,
  refetchQueries,
  onSaved,
  onCancel,
}) => {
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const [createSubmissionAction, { loading: creating }] = useMutation(
    CreateSubmissionActionDocument,
    {
      onError,
      onCompleted: onSaved,
      refetchQueries,
    },
  );

  return (
    <Fragment>
      <ErrorMessage />
      {type === FormSubmissionActionType.Email ? (
        <EmailSubmissionActionForm
          saving={creating}
          onSave={(data) => {
            clearMessages();
            void createSubmissionAction({
              variables: {
                formId,
                type: FormSubmissionActionType.Email,
                ...data,
              },
            });
          }}
          onCancel={onCancel}
        />
      ) : (
        <WebhookSubmissionActionForm
          saving={creating}
          onSave={(data) => {
            clearMessages();
            void createSubmissionAction({
              variables: {
                formId,
                type: FormSubmissionActionType.Webhook,
                ...data,
              },
            });
          }}
          onCancel={onCancel}
        />
      )}
    </Fragment>
  );
};

type SubmissionActionRowProps = {
  action: FormSubmissionActionFieldsFragment;
  // The queries to refetch after deleting the submission action.
  refetchQueries: QueryOptions[];
};

const SubmissionActionRow: FunctionComponent<SubmissionActionRowProps> = ({
  action,
  refetchQueries,
}) => {
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const menu = useRef<Menu>(null);
  const [mode, setMode] = useState<'view' | 'edit'>('view');

  const [editSubmissionAction, { loading: editing }] = useMutation(EditSubmissionActionDocument, {
    onError,
    onCompleted: () => {
      setMode('view');
    },
  });
  const [deleteSubmissionAction, { loading: deleting }] = useMutation(
    DeleteSubmissionActionDocument,
    { onError, refetchQueries },
  );

  return (
    <Fragment>
      <div className="border-top-1 py-3 flex justify-content-between align-items-start gap-3">
        <div className="flex-grow-1">
          <ErrorMessage />
          {action.type === FormSubmissionActionType.Email ? (
            <EmailSubmissionAction
              action={action}
              mode={mode}
              saving={editing}
              onSave={(data) => {
                clearMessages();
                void editSubmissionAction({
                  variables: { submissionActionId: action.id, type: action.type, ...data },
                });
              }}
              onCancel={() => {
                setMode('view');
              }}
            />
          ) : (
            <WebhookSubmissionAction
              action={action}
              mode={mode}
              saving={editing}
              onSave={(data) => {
                clearMessages();
                void editSubmissionAction({
                  variables: { submissionActionId: action.id, type: action.type, ...data },
                });
              }}
              onCancel={() => {
                setMode('view');
              }}
            />
          )}
        </div>
        <Flex align="center" gap="3">
          <BasicIconButton
            icon={PrimeIcons.PENCIL}
            disabled={editing || deleting || mode === 'edit'}
            onClick={() => {
              setMode('edit');
            }}
          />
          <BasicIconButton
            icon={PrimeIcons.TRASH}
            disabled={editing || deleting}
            onClick={(evt) => {
              menu.current?.show(evt);
            }}
          />
        </Flex>
      </div>
      <Menu
        // overflow-hidden because of rounded corners and gray background on hover.
        className="py-0 overflow-hidden"
        ref={menu}
        popup
        model={[
          {
            label: 'Confirm delete',
            command: () => {
              void deleteSubmissionAction({ variables: { submissionActionId: action.id } });
            },
            disabled: deleting,
          },
        ]}
      />
    </Fragment>
  );
};

type FormNotificationsProps = {
  form: { id: string; title: string };
};

export const FormNotifications: FunctionComponent<FormNotificationsProps> = ({ form }) => {
  const { onError, ErrorMessage } = useErrorHandler();
  const { formSubmissionActions, loadingFormSubmissionActions, formSubmissionActionsQuery } =
    useFormSubmissionActions(form.id, onError);

  const [showCreateEmailAction, setShowCreateEmailAction] = useState(false);
  const [showCreateWebhookAction, setShowCreateWebhookAction] = useState(false);

  const emailNotifications = formSubmissionActions?.filter(
    ({ type }) => type === FormSubmissionActionType.Email,
  );
  const webhookNotifications = formSubmissionActions?.filter(
    ({ type }) => type === FormSubmissionActionType.Webhook,
  );

  return (
    <Fragment>
      <ErrorMessage />
      {loadingFormSubmissionActions ? (
        <Spinner py="3" />
      ) : (
        formSubmissionActions && (
          <Fragment>
            <div className="px-3 surface-ground border-1 border-round">
              <div className="flex justify-content-between align-items-center gap-2 my-3">
                <h3 className="text-lg">Emails</h3>
                <Button
                  label="Add"
                  disabled={showCreateEmailAction}
                  onClick={() => {
                    setShowCreateEmailAction(true);
                  }}
                />
              </div>
              {showCreateEmailAction && (
                <div className="mb-3">
                  <CreateNotification
                    formId={form.id}
                    type={FormSubmissionActionType.Email}
                    refetchQueries={[formSubmissionActionsQuery]}
                    onSaved={() => {
                      setShowCreateEmailAction(false);
                    }}
                    onCancel={() => {
                      setShowCreateEmailAction(false);
                    }}
                  />
                </div>
              )}
              {!emailNotifications?.length && (
                <p className="font-italic mt-2 mb-3">
                  This form does not have any email notifications set up
                </p>
              )}
              {emailNotifications?.map((action) => (
                <SubmissionActionRow
                  key={action.id}
                  action={action}
                  refetchQueries={[formSubmissionActionsQuery]}
                />
              ))}
            </div>
            <div className="px-3 surface-ground border-1 border-round mt-3">
              <div className="flex justify-content-between align-items-center gap-2 my-3">
                <h3 className="text-lg">Webhooks</h3>
                <Button
                  label="Add"
                  disabled={showCreateWebhookAction}
                  onClick={() => {
                    setShowCreateWebhookAction(true);
                  }}
                />
              </div>
              {showCreateWebhookAction && (
                <div className="mb-3">
                  <CreateNotification
                    formId={form.id}
                    type={FormSubmissionActionType.Webhook}
                    refetchQueries={[formSubmissionActionsQuery]}
                    onSaved={() => {
                      setShowCreateWebhookAction(false);
                    }}
                    onCancel={() => {
                      setShowCreateWebhookAction(false);
                    }}
                  />
                </div>
              )}
              {!webhookNotifications?.length && (
                <p className="font-italic mt-2 mb-3">
                  This form does not have any webhook notifications set up
                </p>
              )}
              {webhookNotifications?.map((action) => (
                <SubmissionActionRow
                  key={action.id}
                  action={action}
                  refetchQueries={[formSubmissionActionsQuery]}
                />
              ))}
            </div>
          </Fragment>
        )
      )}
    </Fragment>
  );
};
