import { useMutation, useQuery } from '@apollo/client';
import { HelpTicketType } from '@wirechunk/lib/help-tickets.ts';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { ComponentSpec, ComponentType } from '@wirechunk/lib/mixer/types/components.ts';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import type { FunctionComponent } from 'react';
import { Fragment, useState } from 'react';
import { useCurrentUser } from '../../../contexts/CurrentUserContext/CurrentUserContext.tsx';
import { useSiteContext } from '../../../contexts/SiteContext/SiteContext.tsx';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { helpTicketTypeToHumanReadable } from '../../../util/helpTickets.ts';
import { Spinner } from '../../spinner/spinner.tsx';
import { CreateHelpTicketDocument } from './mutations.generated.ts';
import type { HelpTicketsQuery } from './queries.generated.ts';
import { HelpTicketsDocument } from './queries.generated.ts';

const typeOptions: Array<{ label: string; value: HelpTicketType }> = [
  { label: 'Billing question', value: HelpTicketType.Billing },
  { label: 'Feature request', value: HelpTicketType.FeatureRequest },
  { label: 'Something else', value: HelpTicketType.Other },
];

type Row = HelpTicketsQuery['helpTickets'][number];

export const HelpTicketsPortal: FunctionComponent<
  ComponentSpec<ComponentType.HelpTicketsPortal>
> = (props) => {
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const { user } = useCurrentUser();
  // Here we get the platformId from the SiteContext, not from the ViewingPlatformContext, because this component
  // can be rendered in a customer site.
  const { platformId } = useSiteContext();
  const {
    data,
    loading: isLoadingTickets,
    refetch,
  } = useQuery(HelpTicketsDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: { platformId, organizationId: user.org.id },
  });
  const [createHelpTicket, { loading: isCreatingTicket }] = useMutation(CreateHelpTicketDocument, {
    onError,
  });

  const [ticketCreated, setTicketCreated] = useState(false);
  const [type, setType] = useState<HelpTicketType | null>(null);
  const [message, setMessage] = useState('');
  const [customDomain, setCustomDomain] = useState('');
  const [customSiteHeaderCode, setCustomSiteHeaderCode] = useState('');
  const [customSiteBodyStartCode, setCustomSiteBodyStartCode] = useState('');
  const [customSiteBodyEndCode, setCustomSiteBodyEndCode] = useState('');

  return (
    <div className={componentClassName(props)}>
      <ErrorMessage />
      {ticketCreated ? (
        <Fragment>
          <p className="text-lg">Thanks—we got your message!</p>
          <Button
            label="Send another message"
            className="p-button-outlined"
            onClick={() => {
              setTicketCreated(false);
            }}
          />
        </Fragment>
      ) : (
        <Fragment>
          <div className="input-field">
            <label htmlFor="createHelpTicketType">Your request type</label>
            <Dropdown
              inputId="createHelpTicketType"
              className="w-full"
              placeholder="Select"
              options={typeOptions}
              value={type}
              onChange={(e) => {
                setType(e.value as HelpTicketType);
              }}
            />
          </div>
          {(type === HelpTicketType.Other ||
            type === HelpTicketType.Billing ||
            type === HelpTicketType.FeatureRequest) && (
            <div className="input-field">
              <label htmlFor="createHelpTicketMessage">Your message</label>
              <InputTextarea
                id="createHelpTicketMessage"
                className="w-full max-w-full"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
            </div>
          )}
          {type === HelpTicketType.SiteCustomDomain && (
            <Fragment>
              <div className="input-field">
                <label htmlFor="createHelpTicketCustomDomain">Your custom domain</label>
                <InputText
                  id="createHelpTicketCustomDomain"
                  className="w-full"
                  value={customDomain}
                  onChange={(e) => {
                    setCustomDomain(e.target.value);
                  }}
                />
              </div>
              <div className="input-field">
                <label htmlFor="createHelpTicketMessage">Additional comments (optional)</label>
                <InputTextarea
                  id="createHelpTicketMessage"
                  className="w-full max-w-full"
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                />
              </div>
            </Fragment>
          )}
          {type === HelpTicketType.SiteCustomCode && (
            <Fragment>
              <p>
                <span className="font-medium">Please note:</span> We carefully review all requests
                to install custom code to ensure that it is safe and will not affect the performance
                of your website. We will add your code or contact you within two business days if we
                have any questions or concerns about the code that you’d like to add.
              </p>
              <div className="input-field">
                <label htmlFor="createHelpTicketCustomSiteHeaderCode">
                  Header code (HTML {'<head>'} element)
                </label>
                <InputTextarea
                  id="createHelpTicketCustomSiteHeaderCode"
                  className="w-full max-w-full"
                  value={customSiteHeaderCode}
                  onChange={(e) => {
                    setCustomSiteHeaderCode(e.target.value);
                  }}
                />
              </div>
              <div className="input-field">
                <label htmlFor="createHelpTicketCustomSiteBodyStartCode">
                  Page body start code (after opening {'<body>'} tag)
                </label>
                <InputTextarea
                  id="createHelpTicketCustomSiteBodyStartCode"
                  className="w-full max-w-full"
                  value={customSiteBodyStartCode}
                  onChange={(e) => {
                    setCustomSiteBodyStartCode(e.target.value);
                  }}
                />
              </div>
              <div className="input-field">
                <label htmlFor="createHelpTicketCustomSiteBodyEndCode">
                  Page body end code (before closing {'</body>'} tag)
                </label>
                <InputTextarea
                  id="createHelpTicketCustomSiteBodyEndCode"
                  className="w-full max-w-full"
                  value={customSiteBodyEndCode}
                  onChange={(e) => {
                    setCustomSiteBodyEndCode(e.target.value);
                  }}
                />
              </div>
            </Fragment>
          )}
          <Button
            label="Submit"
            className="mt-3"
            disabled={isCreatingTicket || !type}
            onClick={() => {
              clearMessages();
              if (type) {
                void createHelpTicket({
                  variables: {
                    type,
                    message,
                    customDomain,
                    customSiteHeaderCode,
                    customSiteBodyStartCode,
                    customSiteBodyEndCode,
                  },
                  onCompleted: () => {
                    setTicketCreated(true);
                    setType(null);
                    setMessage('');
                    setCustomDomain('');
                    setCustomSiteHeaderCode('');
                    setCustomSiteBodyStartCode('');
                    setCustomSiteBodyEndCode('');
                    void refetch();
                  },
                });
              }
            }}
          />
        </Fragment>
      )}
      <h3 className="mt-6 pt-4 border-top-1">Your help tickets</h3>
      {isLoadingTickets ? (
        <Spinner py="3" />
      ) : (
        <DataTable
          className="mt-3"
          emptyMessage="No tickets yet. Tickets you create will appear here."
          value={data?.helpTickets || []}
        >
          <Column
            header="Ticket type"
            field="type"
            body={(row: Row) => {
              return helpTicketTypeToHumanReadable(row.type);
            }}
          />
          <Column
            header="Created on"
            field="createdAt"
            body={(row: Row) => new Date(row.createdAt).toLocaleDateString()}
          />
          <Column header="Status" field="status" />
        </DataTable>
      )}
    </div>
  );
};
