import { isDelta } from '@wirechunk/lib/rich-text.ts';
import type { Delta } from '@wirechunk/schemas/custom-field/rich-text';

// Parse the given JSON string and check that it has the structure of a Delta object.
// In case of an error, this function returns throws an error.
export const parseDelta = (json: string): Delta['delta'] => {
  const parsed: unknown = JSON.parse(json);
  if (isDelta(parsed)) {
    return parsed;
  }
  throw new Error('Invalid Delta JSON');
};
