import { createContext, useContext } from 'react';

type InputValidationErrorMessageContext = string | null;

const context = createContext<InputValidationErrorMessageContext>(null);

export const InputValidationErrorMessageProvider = context.Provider;

export const useInputValidationErrorMessage = (): InputValidationErrorMessageContext =>
  useContext(context);
