/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.ts';

export type PublicSitePagesQueryVariables = Types.Exact<{
  siteId: Types.Scalars['String']['input'];
  pathPrefix?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type PublicSitePagesQuery = {
  publicSite: {
    __typename: 'PublicSite';
    id: string;
    pages: Array<{
      __typename: 'Page';
      id: string;
      title: string;
      path: string;
      featuredImageUrl?: string | null;
      caption?: string | null;
    }>;
  };
};

export const PublicSitePagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PublicSitePages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'siteId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pathPrefix' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publicSite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'siteId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pages' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'pathPrefix' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pathPrefix' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'featuredImageUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PublicSitePagesQuery, PublicSitePagesQueryVariables>;
