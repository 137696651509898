export enum SiteOptionKey {
  AuthenticatedMainNavItems = 'AuthenticatedMainNavItems',
  GoogleTagManagerId = 'GoogleTagManagerId',
  LogoIconUrl = 'LogoIconUrl',
  LogoUrl = 'LogoUrl',
  MetaPixelId = 'MetaPixelId',
  MixpanelToken = 'MixpanelToken',
  Name = 'Name',
  // TODO: Move to Agreement with rich text
  PrivacyPolicy = 'PrivacyPolicy',
  TermsOfUse = 'TermsOfUse',
  SidebarExtraItems = 'SidebarExtraItems',
  StripePublishableKey = 'StripePublishableKey',
  ZendeskChatKey = 'ZendeskChatKey',

  // TODO: Refactor these to be dynamic fields (using site or page props).
  AddressCity = 'AddressCity',
  AddressLine1 = 'AddressLine1',
  AddressLine2 = 'AddressLine2',
  AddressState = 'AddressState',
  AddressZip = 'AddressZip',
  AgentFirstName = 'AgentFirstName',
  AgentLastName = 'AgentLastName',
  AgentPhotoUrl = 'AgentPhotoUrl',
  GooglePlaceId = 'GooglePlaceId',
  PrimaryPhone = 'PrimaryPhone',
  UseCustomPrivacyPolicy = 'UseCustomPrivacyPolicy',
  UseCustomTermsOfUse = 'UseCustomTermsOfUse',
}

export enum TempSiteCustomFieldKey {
  AddressCity = 'addressCity',
  AddressLine1 = 'addressLine1',
  AddressLine2 = 'addressLine2',
  AddressState = 'addressState',
  AddressZip = 'addressZip',
  AgentFirstName = 'agentFirstName',
  AgentLastName = 'agentLastName',
  AgentPhotoUrl = 'agentPhotoUrl',
  GooglePlaceId = 'googlePlaceId',
  PrimaryPhone = 'primaryPhone',
  UseCustomPrivacyPolicy = 'useCustomPrivacyPolicy',
  UseCustomTermsOfUse = 'useCustomTermsOfUse',
}

// temporary
export const siteOptionKeyToTempSiteCustomFieldKey = (
  key:
    | SiteOptionKey.AddressCity
    | SiteOptionKey.AddressLine1
    | SiteOptionKey.AddressLine2
    | SiteOptionKey.AddressState
    | SiteOptionKey.AddressZip
    | SiteOptionKey.AgentFirstName
    | SiteOptionKey.AgentLastName
    | SiteOptionKey.AgentPhotoUrl
    | SiteOptionKey.GooglePlaceId
    | SiteOptionKey.PrimaryPhone
    | SiteOptionKey.UseCustomPrivacyPolicy
    | SiteOptionKey.UseCustomTermsOfUse,
): TempSiteCustomFieldKey => {
  const firstLetter = (key[0] as string).toLowerCase();
  const rest = key.slice(1);
  return `${firstLetter}${rest}` as TempSiteCustomFieldKey;
};

export const tempSiteCustomFieldKeyToSiteOptionKey = (
  key: TempSiteCustomFieldKey,
): SiteOptionKey => {
  const firstLetter = (key[0] as string).toUpperCase();
  const rest = key.slice(1);
  return `${firstLetter}${rest}` as SiteOptionKey;
};
