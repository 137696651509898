/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.ts';

export type CourseForUserQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  userId: Types.Scalars['String']['input'];
}>;

export type CourseForUserQuery = {
  course: {
    __typename: 'Course';
    id: string;
    title: string;
    enableNotes: boolean;
    emptyNotesMessage?: string | null;
    maxDepth: number;
    lessonCompletionMode: Types.LessonCompletionMode;
    lessons: {
      __typename: 'LessonsList';
      lessons: Array<{
        __typename: 'Lesson';
        id: string;
        title: string;
        enableContent: boolean;
        lessonUser?: {
          __typename: 'LessonUser';
          id: string;
          state?: string | null;
          notes?: string | null;
          completedAt?: string | null;
        } | null;
        subLessons: {
          __typename: 'LessonsList';
          lessons: Array<{
            __typename: 'Lesson';
            id: string;
            title: string;
            lessonUser?: {
              __typename: 'LessonUser';
              id: string;
              state?: string | null;
              notes?: string | null;
              completedAt?: string | null;
            } | null;
          }>;
        };
      }>;
    };
  };
};

export type LessonComponentsQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type LessonComponentsQuery = {
  lesson: { __typename: 'Lesson'; id: string; components: string };
};

export const CourseForUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CourseForUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enableNotes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emptyNotesMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxDepth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lessonCompletionMode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lessons' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lessons' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'enableContent' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lessonUser' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'userId' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'LessonUser' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subLessons' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lessons' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'lessonUser' },
                                          arguments: [
                                            {
                                              kind: 'Argument',
                                              name: { kind: 'Name', value: 'userId' },
                                              value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'userId' },
                                              },
                                            },
                                          ],
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'LessonUser' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LessonUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LessonUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CourseForUserQuery, CourseForUserQueryVariables>;
export const LessonComponentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LessonComponents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lesson' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'components' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LessonComponentsQuery, LessonComponentsQueryVariables>;
