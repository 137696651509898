import { useQuery } from '@apollo/client';
import type { Component } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';
import { useErrorCollector } from '../../../contexts/error-collector-context.tsx';
import { useSiteContext } from '../../../contexts/SiteContext/SiteContext.tsx';
import { ParseAndRenderQuillDelta } from '../../RenderQuillDelta/parse-and-render-quill-delta.tsx';
import { Spinner } from '../../spinner/spinner.tsx';
import { defaultTermsOfUse } from '../../VisualBuilder/components/defaultTermsOfUse.tsx';
import { CurrentSiteTermsOfUseDocument } from './queries.generated.ts';

export const SiteTermsOfUse: FunctionComponent<Component> = () => {
  const { onError } = useErrorCollector();
  const { name } = useSiteContext();
  const { data, error, loading } = useQuery(CurrentSiteTermsOfUseDocument, { onError });

  if (loading) {
    return <Spinner py="3" />;
  }

  if (data?.currentSite.useCustomTermsOfUse) {
    return data.currentSite.termsOfUse ? (
      <ParseAndRenderQuillDelta delta={data.currentSite.termsOfUse.delta} />
    ) : null;
  }

  if (error) {
    return null;
  }

  return <div dangerouslySetInnerHTML={{ __html: defaultTermsOfUse(name) }} />;
};
