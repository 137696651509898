import { useQuery } from '@apollo/client';
import { max } from 'lodash-es';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import type { FunctionComponent } from 'react';
import { Fragment, useMemo, useState } from 'react';
import { useCurrentUser } from '../../../contexts/CurrentUserContext/CurrentUserContext.tsx';
import { useDialog } from '../../../contexts/DialogContext/DialogContext.tsx';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { Spinner } from '../../spinner/spinner.tsx';
import { useEditUserPlanLive } from '../MoveUserPlan/useEditUserPlanLive/useEditUserPlanLive.ts';
import { PlanStageBlueprintsDocument } from './queries.generated.ts';

enum SortMode {
  PositionAsc,
  CompletedAtAsc,
  CompletedAtDesc,
}

export const defaultStageNameTableHeader = 'Lesson';

type PlanStagesProps = {
  planId: string;
  stageNameTableHeader: string | null | undefined;
};

export const PlanStages: FunctionComponent<PlanStagesProps> = ({
  planId,
  stageNameTableHeader,
}) => {
  const dialog = useDialog();
  const { onError, ErrorMessage } = useErrorHandler();
  const { user } = useCurrentUser();
  const { data, loading } = useQuery(PlanStageBlueprintsDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: {
      id: planId,
      userId: user.id,
    },
  });
  const { editUserPlan, saving } = useEditUserPlanLive(onError);

  const [sort, setSort] = useState<SortMode>(SortMode.PositionAsc);

  const stageBlueprints = useMemo(() => {
    if (data) {
      const mappedStageBlueprints = data.sequence.stageBlueprints.map(
        ({ id, name, completedAt }) => {
          const maxCompletedAtString = max(completedAt);
          const maxCompletedAt = maxCompletedAtString && new Date(maxCompletedAtString);

          return {
            id,
            name,
            maxCompletedAt,
            completedAtLocaleDate: maxCompletedAt && new Date(maxCompletedAt).toLocaleDateString(),
          };
        },
      );

      if (sort === SortMode.PositionAsc) {
        return mappedStageBlueprints;
      }

      if (sort === SortMode.CompletedAtAsc) {
        return mappedStageBlueprints.sort((a, b) => {
          if (!a.maxCompletedAt) {
            return b.maxCompletedAt ? -1 : 0;
          }
          if (!b.maxCompletedAt) {
            return 1;
          }
          return a.maxCompletedAt.getTime() - b.maxCompletedAt.getTime();
        });
      }

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (sort === SortMode.CompletedAtDesc) {
        return mappedStageBlueprints.sort((a, b) => {
          if (!a.maxCompletedAt) {
            return 1;
          }
          if (!b.maxCompletedAt) {
            return -1;
          }
          return b.maxCompletedAt.getTime() - a.maxCompletedAt.getTime();
        });
      }
    }
    return null;
  }, [data, sort]);

  return (
    <Fragment>
      <ErrorMessage />
      {loading ? (
        <Spinner py="3" />
      ) : (
        stageBlueprints && (
          <div className="w-full">
            <div className="w-full flex flex-row gap-2 px-4 py-3 border-bottom-2">
              <div className="flex-grow-1">
                <Button
                  className="p-button-text font-bold text-color-body py-1 px-0 hover:background-white border-0 focus:border-0"
                  onClick={() => {
                    setSort(SortMode.PositionAsc);
                  }}
                  label={stageNameTableHeader || defaultStageNameTableHeader}
                  icon={PrimeIcons.SORT_UP}
                  iconPos="right"
                  pt={{
                    icon: {
                      className: sort === SortMode.PositionAsc ? undefined : 'text-color-muted',
                    },
                  }}
                />
              </div>
              <div className="w-9rem min-w-9rem max-w-9-rem">
                <Button
                  className="p-button-text font-bold text-color-body py-1 px-0 hover:background-white border-0 focus:border-0"
                  onClick={() => {
                    setSort((sort) =>
                      sort === SortMode.PositionAsc
                        ? SortMode.CompletedAtAsc
                        : sort === SortMode.CompletedAtAsc
                          ? SortMode.CompletedAtDesc
                          : SortMode.CompletedAtAsc,
                    );
                  }}
                  label="Last completed"
                  icon={
                    sort === SortMode.CompletedAtAsc
                      ? PrimeIcons.SORT_UP
                      : sort === SortMode.CompletedAtDesc
                        ? PrimeIcons.SORT_DOWN
                        : PrimeIcons.SORT
                  }
                  iconPos="right"
                  pt={{
                    icon: {
                      className:
                        sort === SortMode.CompletedAtAsc || sort === SortMode.CompletedAtDesc
                          ? undefined
                          : 'text-color-muted',
                    },
                  }}
                />
              </div>
            </div>
            <div className="pb-1 w-full flex flex-column align-items-stretch gap-2">
              {stageBlueprints.map(({ id, name, completedAtLocaleDate }) => (
                <button
                  key={id}
                  className="text-left px-4 py-3 font-medium background-white border-x-none border-top-none border-bottom-1 cursor-pointer flex"
                  disabled={saving}
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={async () => {
                    const { data } = await editUserPlan(planId, id);
                    if (data) {
                      dialog(null);
                    }
                  }}
                >
                  <div className="flex-grow-1 font-normal">{name}</div>
                  <div className="w-9rem min-w-9rem max-w-9rem font-normal">
                    {completedAtLocaleDate || <span className="text-color-muted">—</span>}
                  </div>
                </button>
              ))}
            </div>
          </div>
        )
      )}
    </Fragment>
  );
};
