import { createContext, useContext } from 'react';
import type { PublicSiteContextFragment } from '../../components/CurrentSiteProvider/fragments.generated.ts';

export type SiteContext = Omit<PublicSiteContextFragment, '__typename'>;

// @ts-expect-error -- We always provide a SiteContext at the root of the app.
const Context = createContext<SiteContext>(null);

export const SiteContextProvider = Context.Provider;

export const useSiteContext = (): SiteContext => useContext(Context);
