import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { ButtonComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { ButtonIconPosition, MixerEventType } from '@wirechunk/lib/mixer/types/components.ts';
import { Button as PrimeReactButton } from 'primereact/button';
import type { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from '../../contexts/FormContext/form-context.tsx';
import type { MixerEventDetailForType } from '../../util/mixer/events';
import { mixerQualifiedEventType } from '../../util/mixer/events';

export const Button: FunctionComponent<ButtonComponent> = (props) => {
  const formContext = useFormContext();
  const navigate = useNavigate();

  let type: 'button' | 'submit' | 'reset' = 'button';
  if (props.onClick?.type === 'submitForm') {
    type = 'submit';
  } else if (props.onClick?.type === 'resetForm') {
    type = 'reset';
  }

  const submittingForm = !!formContext?.submitting;

  return (
    <PrimeReactButton
      className={componentClassName(props)}
      type={type}
      label={props.label || props.text || ''}
      icon={props.icon || undefined}
      iconPos={props.iconPosition || ButtonIconPosition.Left}
      tooltip={props.tooltip || undefined}
      tooltipOptions={props.tooltipOptions || undefined}
      disabled={submittingForm}
      onClick={(e) => {
        switch (props.onClick?.type) {
          case 'submitForm':
          case 'resetForm':
            // Do the default action.
            break;
          case 'navigate':
            if (props.onClick.url) {
              if (props.onClick.isExternal) {
                if (props.onClick.openInNewTab) {
                  window.open(props.onClick.url, '_blank');
                } else {
                  window.open(props.onClick.url);
                }
              } else {
                navigate(props.onClick.url);
              }
            }
            break;
          default:
            e.preventDefault();
        }
        const mixerEvent = new CustomEvent<MixerEventDetailForType<MixerEventType.Click>>(
          mixerQualifiedEventType(MixerEventType.Click),
          {
            detail: {
              type: MixerEventType.Click,
              component: props,
            },
          },
        );
        document.dispatchEvent(mixerEvent);
      }}
    />
  );
};
