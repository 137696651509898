/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.ts';

export type LessonUserFragment = {
  __typename: 'LessonUser';
  id: string;
  state?: string | null;
  notes?: string | null;
  completedAt?: string | null;
};

export const LessonUserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LessonUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LessonUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LessonUserFragment, unknown>;
