/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.ts';

export type MeQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MeQuery = {
  me?: {
    __typename: 'User';
    id: string;
    email: string;
    emailVerified: boolean;
    firstName: string;
    lastName: string;
    displayName: string;
    role: Types.Role;
    currentAgreementsSigned: Array<Types.AgreementType>;
    productItems: Array<string>;
    platformPermissions: Array<{
      __typename: 'UserPlatformPermissions';
      userId: string;
      platformId: string;
      permissions: Array<Types.Permission>;
      platform: {
        __typename: 'Platform';
        id: string;
        name: string;
        handle: string;
        mainSiteId?: string | null;
        enableScoreMyCall: boolean;
        enablePagePurposes: boolean;
        enableComponentClasses: boolean;
      };
    }>;
    org: { __typename: 'Organization'; id: string; name?: string | null };
  } | null;
};

export const MeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Me' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Me' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Me' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailVerified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platformPermissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'platformId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'permissions' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'platform' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mainSiteId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'enableScoreMyCall' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'enablePagePurposes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'enableComponentClasses' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAgreementsSigned' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productItems' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'org' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MeQuery, MeQueryVariables>;
