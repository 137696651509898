/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.ts';

export type CreateSubmissionActionMutationVariables = Types.Exact<{
  formId: Types.Scalars['String']['input'];
  type: Types.FormSubmissionActionType;
  emailAddress?: Types.InputMaybe<Types.Scalars['String']['input']>;
  emailSubject?: Types.InputMaybe<Types.Scalars['String']['input']>;
  webhookUrl?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type CreateSubmissionActionMutation = {
  createFormSubmissionAction: {
    __typename: 'FormSubmissionAction';
    id: string;
    createdAt: string;
    type: Types.FormSubmissionActionType;
    emailAddress?: string | null;
    emailSubject?: string | null;
    webhookUrl?: string | null;
  };
};

export type EditSubmissionActionMutationVariables = Types.Exact<{
  submissionActionId: Types.Scalars['String']['input'];
  type: Types.FormSubmissionActionType;
  emailAddress?: Types.InputMaybe<Types.Scalars['String']['input']>;
  emailSubject?: Types.InputMaybe<Types.Scalars['String']['input']>;
  webhookUrl?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type EditSubmissionActionMutation = {
  editFormSubmissionAction: {
    __typename: 'FormSubmissionAction';
    id: string;
    createdAt: string;
    type: Types.FormSubmissionActionType;
    emailAddress?: string | null;
    emailSubject?: string | null;
    webhookUrl?: string | null;
  };
};

export type DeleteSubmissionActionMutationVariables = Types.Exact<{
  submissionActionId: Types.Scalars['String']['input'];
}>;

export type DeleteSubmissionActionMutation = { deleteFormSubmissionAction: boolean };

export const CreateSubmissionActionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSubmissionAction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'formId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FormSubmissionActionType' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emailAddress' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emailSubject' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'webhookUrl' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFormSubmissionAction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'formId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'formId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'emailAddress' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'emailAddress' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'emailSubject' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'emailSubject' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'webhookUrl' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'webhookUrl' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FormSubmissionActionFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FormSubmissionActionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FormSubmissionAction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailSubject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'webhookUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSubmissionActionMutation,
  CreateSubmissionActionMutationVariables
>;
export const EditSubmissionActionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditSubmissionAction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'submissionActionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FormSubmissionActionType' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emailAddress' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emailSubject' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'webhookUrl' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editFormSubmissionAction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'submissionActionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'submissionActionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'emailAddress' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'emailAddress' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'emailSubject' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'emailSubject' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'webhookUrl' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'webhookUrl' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FormSubmissionActionFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FormSubmissionActionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FormSubmissionAction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailSubject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'webhookUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditSubmissionActionMutation, EditSubmissionActionMutationVariables>;
export const DeleteSubmissionActionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteSubmissionAction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'submissionActionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFormSubmissionAction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'submissionActionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'submissionActionId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteSubmissionActionMutation,
  DeleteSubmissionActionMutationVariables
>;
