export const placeToDescription = (place: google.maps.places.PlaceResult): string => {
  let description = place.name || '';
  if (place.formatted_address) {
    if (description) {
      description += ` (${place.formatted_address})`;
    } else {
      description = place.formatted_address;
    }
  }
  if (!description) {
    description = `Place ID: ${place.place_id || 'None'}`;
  }
  return description;
};
