import { useQuery } from '@apollo/client';
import type { ContextData } from '@wirechunk/schemas/context-data/context-data';
import type { FunctionComponent } from 'react';
import { Fragment, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import type { PageContext } from '../../contexts/PageContext/PageContext.ts';
import { PageContextProvider, ViewMode } from '../../contexts/PageContext/PageContext.ts';
import { PropsContextProvider } from '../../contexts/props-context.ts';
import { useSiteContext } from '../../contexts/SiteContext/SiteContext.tsx';
import { useErrorHandler } from '../../hooks/useErrorHandler.tsx';
import { tryParseObject } from '../../util/json.ts';
import { ParseAndRenderPage } from '../ParseAndRenderPage.tsx';
import { Spinner } from '../spinner/spinner.tsx';
import { PublicSitePageDocument } from './queries.generated.ts';

export const Page: FunctionComponent = () => {
  const { pathname } = useLocation();
  const { onError, ErrorMessage } = useErrorHandler();
  const site = useSiteContext();
  const { data, loading } = useQuery(PublicSitePageDocument, {
    onError,
    variables: {
      siteId: site.id,
      path: pathname,
    },
  });

  const pageContext = useMemo<PageContext | null>(
    () =>
      data?.publicSite.page
        ? {
            id: data.publicSite.page.id,
            title: data.publicSite.page.title,
            viewMode: ViewMode.Live,
          }
        : null,
    [data],
  );
  const propsContext = useMemo<ContextData>(
    () =>
      data?.publicSite.page?.props
        ? (tryParseObject(data.publicSite.page.props) as ContextData)
        : {},
    [data],
  );

  const page = data?.publicSite.page;

  return (
    <Fragment>
      <Helmet>
        <title>{page?.metaTitle || page?.title || site.name}</title>
        {page?.metaDescription ? <meta name="description" content={page.metaDescription} /> : null}
        {page?.metaRobots?.length ? (
          <meta name="robots" content={page.metaRobots.join(', ')} />
        ) : null}
      </Helmet>
      <ErrorMessage />
      {page && pageContext ? (
        <PageContextProvider value={pageContext}>
          <PropsContextProvider value={propsContext}>
            <ParseAndRenderPage componentsJSON={page.components} bodyStylesJSON={page.bodyStyles} />
          </PropsContextProvider>
        </PageContextProvider>
      ) : loading ? (
        <Spinner py="3" />
      ) : (
        <div className="p-3 xl:p-4">
          No page was found here. It’s possible that you don’t have permission to view this page.
        </div>
      )}
    </Fragment>
  );
};
