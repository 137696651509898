import type { HeadingProps } from '@radix-ui/themes';
import { Heading } from '@radix-ui/themes';
import type { FunctionComponent, PropsWithChildren } from 'react';

type ActionCardTitleProps = PropsWithChildren & HeadingProps;

export const ActionCardTitle: FunctionComponent<ActionCardTitleProps> = ({
  children,
  ...props
}) => (
  <Heading as="h3" size="4" mt="0" mb="3" {...props}>
    {children}
  </Heading>
);
