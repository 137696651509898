import { evaluateBooleanExpression } from '@wirechunk/lib/expression-builder/evaluator.ts';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { PopoverComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { MixerEventType } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';
import { useRef } from 'react';
import { useOptionalCurrentUser } from '../../../contexts/CurrentUserContext/CurrentUserContext.tsx';
import { useMixerEventListener } from '../../../hooks/useMixerEventListener/useMixerEventListener.ts';
import { buildContextData } from '../../../util/mixer/context-data.ts';
import { RenderMixerChildren } from '../../RenderMixerChildren.tsx';

export const Popover: FunctionComponent<PopoverComponent> = (props) => {
  const { user } = useOptionalCurrentUser();
  const op = useRef<HTMLDivElement>(null);
  const { showTriggers, hideTriggers } = props;
  // TODO: Improve the UI of designing an expression to not display input data operators for triggers. Same for props.
  // TODO: Abstract this away into a hook. This is a crazy amount of duplication.
  useMixerEventListener(MixerEventType.Click, (event) => {
    // First process hideTriggers and then showTriggers to ensure that showTriggers take precedence.
    if (
      op.current &&
      hideTriggers?.some(
        (t) =>
          t.type === MixerEventType.Click &&
          (!t.condition ||
            evaluateBooleanExpression(
              t.condition,
              buildContextData({
                user,
                eventSourceComponentName: event.component.name,
                eventSourceComponentType: event.component.type,
              }),
            )),
      )
    ) {
      op.current.hidePopover();
    }
    if (
      op.current &&
      showTriggers?.some(
        (t) =>
          t.type === MixerEventType.Click &&
          (!t.condition ||
            evaluateBooleanExpression(
              t.condition,
              buildContextData({
                user,
                eventSourceComponentName: event.component.name,
                eventSourceComponentType: event.component.type,
              }),
            )),
      )
    ) {
      op.current.showPopover();
    }
  });
  useMixerEventListener(MixerEventType.InputChange, (event) => {
    // First process hideTriggers and then showTriggers to ensure that showTriggers take precedence.
    if (
      op.current &&
      hideTriggers?.some(
        (t) =>
          t.type === MixerEventType.InputChange &&
          (!t.condition ||
            evaluateBooleanExpression(
              t.condition,
              buildContextData({
                user,
                eventSourceComponentName: event.component.name,
                eventSourceComponentType: event.component.type,
                inputChangeEventValue: event.value,
              }),
            )),
      )
    ) {
      op.current.hidePopover();
    }
    if (
      op.current &&
      showTriggers?.some(
        (t) =>
          t.type === MixerEventType.InputChange &&
          (!t.condition ||
            evaluateBooleanExpression(
              t.condition,
              buildContextData({
                user,
                eventSourceComponentName: event.component.name,
                eventSourceComponentType: event.component.type,
                inputChangeEventValue: event.value,
              }),
            )),
      )
    ) {
      op.current.showPopover();
    }
  });

  return (
    <div
      ref={op}
      // @ts-expect-error -- popover
      popover="auto"
      className={componentClassName(props)}
    >
      <RenderMixerChildren>{props.children}</RenderMixerChildren>
    </div>
  );
};
