import { clsx } from 'clsx';
import type { FunctionComponent } from 'react';

export const RenderQuestionExplanation: FunctionComponent<{
  explanation: NonNullable<Question['explanation']>;
}> = ({ explanation }) => (
  <ul className="my-0 pl-4">
    {explanation.map(({ heading, text }, index) => (
      <li key={index} className={clsx('line-height-3', index < explanation.length - 1 && 'mb-2')}>
        <span className="font-medium">{heading}:</span> {text}
      </li>
    ))}
  </ul>
);

export type Question = {
  label: string;
  // HTML name.
  name: string;
  // explanation?: ReactNode;
  explanation?: Array<{
    heading: string;
    text: string;
  }>;
};

export type StepHeading = {
  heading: string;
};

export const questions: Array<Question | StepHeading> = [
  {
    heading: 'Step 1: The Hook',
  },
  {
    label:
      'The prospect said “Yes” three times in the first 30 seconds of the call when I was confirming their information.',
    name: 'saidYesThreeTimes',
  },
  {
    heading: 'Step 2: Building Value vs Selling On Price (Homeowners)',
  },
  {
    label:
      'I went through the Eligibility questions and made sure the Home360 is accurate to the best of my knowledge.',
    name: 'wentThroughEligibility',
  },
  {
    label:
      'When discussing homeowners insurance, I went over what a STANDARD homeowners policy covers, and what we do differently.',
    name: 'discussedStandardHomeownersPolicy',
  },
  {
    heading: 'Step 3: Multi-lining (Auto)',
  },
  {
    label:
      'I discussed 2-3 similarities (things that will remain the same) and 2-3 differences between their current auto coverage and what they’ll have when they’re insured with us.',
    name: 'discussedSimilaritiesAndDifferences',
  },
  {
    label:
      'When sharing my examples on how we’re different, the prospect said, “Wow, that’s great that you guys do that!” (or something very similar to that).',
    name: 'prospectSaidWow',
  },
  {
    label: 'I gave the prospect my “WHY STATEMENT.”',
    name: 'gaveWhyStatement',
  },
  {
    heading: 'Step 4: Cost Presentation',
  },
  {
    label: 'I ran quotes through all necessary quoting platforms before sharing the price.',
    name: 'ranQuotesBeforeSharingPrice',
  },
  {
    label: 'I didn’t pause after revealing the price but went straight into asking for the sale.',
    name: 'didNotPauseAfterPrice',
  },
  {
    heading: 'Step 5: Asking For The Sale',
  },
  {
    label: 'I asked for payment information.',
    name: 'askedForPayment',
  },
  {
    label:
      'When the prospect gave me an objection, I emailed the quote, overcame the objection, and then asked for the sale again.',
    name: 'overcameObjection',
  },
  {
    heading: 'Other Key Performance Indicators',
  },
  {
    label: 'I asked for a referral.',
    name: 'askedForReferral',
  },
  {
    label: 'I discussed life insurance.',
    name: 'discussedLifeInsurance',
  },
  {
    label: 'There was never a period of “dead air” (5+ seconds of silence) throughout the call.',
    name: 'noDeadAir',
  },
  {
    label:
      'I asked at least two open-ended questions about their life that were unrelated to insurance.',
    name: 'askedTwoOpenEndedQuestions',
  },
  {
    label: 'I had an upbeat and enthusiastic tone throughout the entire call.',
    name: 'upbeatAndEnthusiastic',
  },
];
