import type { DashboardComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { Menu } from 'primereact/menu';
import type { MenuItem as PRMenuItemType } from 'primereact/menuitem';
import type { FunctionComponent } from 'react';
import { useRef, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useCurrentUser } from '../../contexts/CurrentUserContext/CurrentUserContext.tsx';
import { useSiteContext } from '../../contexts/SiteContext/SiteContext.tsx';
import { DashboardLayout } from '../DashboardLayout/DashboardLayout.tsx';
import { hrefIsExternal, MenuItem } from '../DashboardLayout/Sidebar/MenuItem/MenuItem.tsx';
import { RenderMixerChildren } from '../RenderMixerChildren.tsx';

export const Dashboard: FunctionComponent<DashboardComponent> = ({
  mainNavItems,
  extraNavItems,
  children,
}) => {
  const { user } = useCurrentUser();
  const { authenticatedMainNavItems, sidebarExtraItems } = useSiteContext();
  const extrasMenu = useRef<Menu>(null);

  return (
    <DashboardLayout
      sidebarBody={(onHide) => {
        if (mainNavItems) {
          return (
            <Fragment>
              {mainNavItems
                .filter(
                  ({ productItem, roles }) =>
                    !(
                      (productItem && !user.productItems.includes(productItem)) ||
                      (roles.length && !roles.includes(user.role))
                    ),
                )
                .map(({ id, label, url }) => (
                  <MenuItem key={id} title={label} to={url} onHide={onHide} />
                ))}
            </Fragment>
          );
        }
        return (
          <Fragment>
            {authenticatedMainNavItems
              ?.filter(
                ({ featureTag, roles }) =>
                  !(
                    (featureTag && !user.productItems.includes(featureTag)) ||
                    (roles?.length && !roles.includes(user.role))
                  ),
              )
              .map(({ id, label, url }) => (
                <MenuItem key={id} title={label} to={url} onHide={onHide} />
              ))}
          </Fragment>
        );
      }}
      sidebarFooter={(onHide) => {
        const link = (to: string, label: string, target: string | undefined, external: boolean) =>
          external ? (
            <a
              href={to}
              role="menuitem"
              className="p-menuitem-link"
              target={target}
              onClick={(evt) => {
                onHide();
                extrasMenu.current?.hide(evt);
              }}
            >
              <span className="p-menuitem-text">{label}</span>
            </a>
          ) : (
            <Link
              to={to}
              role="menuitem"
              className="p-menuitem-link"
              target={target}
              onClick={(evt) => {
                onHide();
                extrasMenu.current?.hide(evt);
              }}
            >
              <span className="p-menuitem-text">{label}</span>
            </Link>
          );
        let items: PRMenuItemType[] | null = null;
        if (extraNavItems) {
          items = extraNavItems
            .filter(
              ({ productItem, roles }) =>
                !(
                  (productItem && !user.productItems.includes(productItem)) ||
                  (roles.length && !roles.includes(user.role))
                ),
            )
            .map(({ id, label, url }) => ({
              id,
              template: (
                <div className="p-menuitem-content">
                  {link(
                    url,
                    label,
                    hrefIsExternal(url) ? '_blank' : undefined,
                    hrefIsExternal(url),
                  )}
                </div>
              ),
            }));
        } else if (sidebarExtraItems) {
          items = sidebarExtraItems
            .filter(
              ({ featureTag, roles }) =>
                !(
                  (featureTag && !user.productItems.includes(featureTag)) ||
                  (roles?.length && !roles.includes(user.role))
                ),
            )
            .map(({ id, label, url }) => ({
              id,
              template: (
                <div className="p-menuitem-content">
                  {link(
                    url,
                    label,
                    hrefIsExternal(url) ? '_blank' : undefined,
                    hrefIsExternal(url),
                  )}
                </div>
              ),
            }));
        }
        if (items?.length) {
          return (
            <Fragment>
              <footer className="border-top-1 py-3 px-4">
                <button
                  type="button"
                  className="text-color-body text-sm font-medium button-not-styled line-height-1 p-0"
                  onClick={(event) => {
                    if (extrasMenu.current) {
                      extrasMenu.current.show(event);
                    }
                  }}
                >
                  Help and more
                </button>
              </footer>
              <Menu ref={extrasMenu} model={items} popup />
            </Fragment>
          );
        }
        return null;
      }}
    >
      <RenderMixerChildren>{children}</RenderMixerChildren>
    </DashboardLayout>
  );
};
