import type { ApolloClient } from '@apollo/client';
import { isError } from 'lodash-es';
import { MeZendeskChatTokenDocument } from './queries.generated.ts';

declare global {
  interface Window {
    zE?: (method: 'messenger' | 'messenger:on', ...args: unknown[]) => void;
  }
}

export const openMessenger = (): void => {
  if (!import.meta.env.SSR && window.zE) {
    window.zE('messenger', 'open');
  }
};

export const authenticateMessenger = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apolloClient: ApolloClient<any>,
): void => {
  if (!import.meta.env.SSR && window.zE) {
    window.zE('messenger', 'loginUser', async (callback: (token: string | null) => void) => {
      try {
        const {
          data: { me },
        } = await apolloClient.query({
          query: MeZendeskChatTokenDocument,
          fetchPolicy: 'no-cache',
        });
        if (me?.zendeskChatToken) {
          callback(me.zendeskChatToken);
          return;
        }
      } catch (err) {
        const errMessage = isError(err) ? err.message : 'Unknown error';
        console.error(`Could not authenticate Zendesk messenger: ${errMessage}`);
      }
      callback(null);
    });
  }
};

export const signOutMessenger = (): void => {
  if (!import.meta.env.SSR && window.zE) {
    window.zE('messenger', 'logoutUser');
  }
};
