import { useMutation } from '@apollo/client';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import type { FunctionComponent } from 'react';
import { Fragment, useState } from 'react';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { sitePageUrl } from '../../../util/site-page-url.ts';
import { InputNotice } from '../../InputNotice/InputNotice.tsx';
import { EditPageMetadataDocument } from './mutations.generated.ts';
import type { PagesQuery } from './queries.generated.ts';

type EditPagePathProps = {
  site: {
    id: string;
    domain: string;
  };
  page: PagesQuery['site']['pages'][number];
  onEdited: () => void;
  onCancel: () => void;
};

export const EditPagePath: FunctionComponent<EditPagePathProps> = ({
  site,
  page,
  onEdited,
  onCancel,
}) => {
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const [editPageMeta, { loading }] = useMutation(EditPageMetadataDocument, {
    onError,
  });

  const [path, setPath] = useState(page.path);

  const onEdit = () => {
    clearMessages();
    void editPageMeta({
      variables: {
        pageId: page.id,
        path,
        title: page.title,
        metaTitle: page.metaTitle || '',
      },
      onCompleted: onEdited,
    });
  };

  return (
    <Fragment>
      <ErrorMessage />
      <div className="input-field">
        <label htmlFor="pagePath">Path</label>
        <InputText
          id="pagePath"
          className="w-full"
          value={path}
          onChange={(e) => {
            setPath(e.target.value);
          }}
        />
        <InputNotice>Full URL: {sitePageUrl(site.domain, path)}</InputNotice>
      </div>
      <div className="flex gap-3 justify-content-end mt-5">
        <Button label="Cancel" className="p-button-secondary" onClick={onCancel} />
        <Button label="Save" onClick={onEdit} disabled={loading} />
      </div>
    </Fragment>
  );
};
