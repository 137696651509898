import { HelpTicketStatus } from './api.ts';

export enum HelpTicketType {
  Billing = 'Billing',
  FeatureRequest = 'FeatureRequest',
  Other = 'Other',
  SiteCustomCode = 'SiteCustomCode',
  SiteCustomDomain = 'SiteCustomDomain',
}

const helpTicketStatuses = Object.values(HelpTicketStatus);

const isHelpTicketStatus = (value: unknown): value is HelpTicketStatus =>
  helpTicketStatuses.includes(value as never);

export const toHelpTicketStatus = (value: unknown): HelpTicketStatus =>
  isHelpTicketStatus(value) ? value : HelpTicketStatus.Open;
