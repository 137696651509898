import type { FunctionComponent, PropsWithChildren } from 'react';
import { createContext, useContext, useMemo } from 'react';
import type { MeQuery } from './queries.generated.ts';

export type CurrentUser = NonNullable<MeQuery['me']>;

type CurrentUserContext = {
  user: CurrentUser | null;
  loadingUser: boolean;
};

type RequiredCurrentUserContext = {
  user: CurrentUser;
  loadingUser: boolean;
};

const isRequiredCurrentUserContext = (
  context: CurrentUserContext,
): context is RequiredCurrentUserContext => context.user !== null;

const Context = createContext<CurrentUserContext>({
  user: null,
  loadingUser: false,
});

type CurrentUserProviderProps = PropsWithChildren<CurrentUserContext>;

export const CurrentUserProvider: FunctionComponent<CurrentUserProviderProps> = ({
  user,
  loadingUser,
  children,
}) => {
  const currentUserContext = useMemo<CurrentUserContext>(
    (): CurrentUserContext => ({
      user,
      loadingUser,
    }),
    [user, loadingUser],
  );

  return <Context.Provider value={currentUserContext}>{children}</Context.Provider>;
};

export const useCurrentUser = (): RequiredCurrentUserContext => {
  const currentUserContext = useContext(Context);

  if (isRequiredCurrentUserContext(currentUserContext)) {
    return currentUserContext;
  }

  throw new Error('There is no user authenticated.');
};

export const useOptionalCurrentUser = (): CurrentUserContext => useContext(Context);
