import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { HTMLComponent } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';

export const HTML: FunctionComponent<HTMLComponent> = (props) => (
  <div
    className={componentClassName(props)}
    dangerouslySetInnerHTML={{ __html: props.html || '' }}
  />
);
