import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { Component } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';
import { useStageContext } from '../../../contexts/StageContext/StageContext.ts';

export const StageName: FunctionComponent<Component> = (props) => {
  const stageContext = useStageContext();

  if (stageContext) {
    return <div className={componentClassName(props)}>{stageContext.stageBlueprint.name}</div>;
  }

  return null;
};
