import type { DataTableExpandedRows, DataTableRowToggleEvent } from 'primereact/datatable';
import { useCallback, useState } from 'react';

type TableRowExpansion = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- This is the type of DataTable['expandedRows'].
  expandedRows: DataTableExpandedRows | any[];
  onRowToggle: (e: DataTableRowToggleEvent) => void;
};

export const useTableRowExpansion = (): TableRowExpansion => {
  const [expandedRows, setExpandedRows] = useState<TableRowExpansion['expandedRows']>({});

  const onRowToggle = useCallback((e: DataTableRowToggleEvent) => {
    setExpandedRows(e.data);
  }, []);

  return {
    expandedRows,
    onRowToggle,
  };
};
