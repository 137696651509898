import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { PaddingComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { DataSource, PaddingAmount } from '@wirechunk/lib/mixer/types/components.ts';
import { isPaddingAmount } from '@wirechunk/lib/mixer/utils.ts';
import { clsx } from 'clsx';
import type { FunctionComponent } from 'react';
import { useMemo } from 'react';
import { usePropsContext } from '../../../contexts/props-context.ts';
import { RenderMixerChildren } from '../../RenderMixerChildren.tsx';

export const Padding: FunctionComponent<PaddingComponent> = (props) => {
  const propsContext = usePropsContext();

  const padding = useMemo<PaddingAmount>(() => {
    if (props.padding) {
      if (props.padding.type === DataSource.Direct) {
        return props.padding.padding || PaddingAmount.StandardPageBody;
      }
      if (props.padding.name) {
        const prop = propsContext[props.padding.name];
        if (isPaddingAmount(prop)) {
          return prop;
        }
      }
    }
    return PaddingAmount.StandardPageBody;
  }, [props.padding, propsContext]);

  return (
    <div
      id={props.elementId || undefined}
      className={clsx(
        componentClassName(props),
        padding === PaddingAmount.StandardPageBody && 'p-3 xl:p-4',
        padding === PaddingAmount.Narrow && 'p-2',
        padding === PaddingAmount.Medium && 'p-3',
        padding === PaddingAmount.Wide && 'p-4',
        padding === PaddingAmount.XWide && 'p-5',
      )}
    >
      <RenderMixerChildren>{props.children}</RenderMixerChildren>
    </div>
  );
};
