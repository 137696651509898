import { evaluateBooleanExpression } from '@wirechunk/lib/expression-builder/evaluator.ts';
import type { ConditionalLogicComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { ConditionalLogicSlotKey } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';
import { useMixerContextData } from '../../../hooks/use-mixer-context-data.tsx';
import { RenderMixerChildren } from '../../RenderMixerChildren.tsx';

export const ConditionalLogic: FunctionComponent<ConditionalLogicComponent> = (props) => {
  const contextData = useMixerContextData();

  const result = props.condition ? evaluateBooleanExpression(props.condition, contextData) : false;

  if (result) {
    return (
      <RenderMixerChildren>
        {props.children?.filter((child) => child.slot === ConditionalLogicSlotKey.True)}
      </RenderMixerChildren>
    );
  }

  return (
    <RenderMixerChildren>
      {props.children?.filter((child) => child.slot === ConditionalLogicSlotKey.False)}
    </RenderMixerChildren>
  );
};
