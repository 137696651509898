import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { Component } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';
import { useSiteContext } from '../../contexts/SiteContext/SiteContext.tsx';

export const OrganizationAgentFirstName: FunctionComponent<Component> = (props) => {
  const { agentFirstName } = useSiteContext();

  return <span className={componentClassName(props)}>{agentFirstName || ''}</span>;
};
