import { TextArea } from '@radix-ui/themes';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { LessonNotesFieldComponent } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';
import { useId } from 'react';
import { useLessonUserContext } from '../course/lesson-user-context.ts';

export const LessonNotesField: FunctionComponent<LessonNotesFieldComponent> = (props) => {
  const lessonUserContext = useLessonUserContext();
  const inputId = useId();

  if (!lessonUserContext) {
    return null;
  }

  return (
    <div className={`${componentClassName(props)} input-field`}>
      <label className="input-label block" htmlFor={inputId}>
        {props.label || 'Notes'}
      </label>
      <TextArea
        id={inputId}
        className="w-full"
        value={lessonUserContext.notes}
        onChange={(e) => {
          lessonUserContext.setNotes(e.target.value);
        }}
      />
    </div>
  );
};
