import type { FunctionComponent, PropsWithChildren } from 'react';
import { useOptionalCurrentUser } from '../contexts/CurrentUserContext/CurrentUserContext.tsx';
import {
  SessionStatus,
  useSessionStatusContext,
} from '../contexts/SessionStatusContext/session-status-context.tsx';
import { AuthForm } from './AuthForm/AuthForm.tsx';

export const AuthenticationGuard: FunctionComponent<
  PropsWithChildren & {
    // Display input fields and the sign in button in a large variant.
    lg?: boolean;
  }
> = ({ lg, children }) => {
  const { user, loadingUser } = useOptionalCurrentUser();
  const sessionStatus = useSessionStatusContext();
  if (user && sessionStatus.status === SessionStatus.Active) {
    return children;
  }
  return <AuthForm loadingUser={loadingUser} lg={lg} />;
};
