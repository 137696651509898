export const defaultTermsOfUse = (
  orgLegalName: string,
): string => `<p>These Terms and Conditions (“Terms of Use”)
  together with our Privacy Policy are a legally binding agreement between you the user (“User” or “you”) of this
  website and ${orgLegalName} (the “Company”, “we”, or “us”).</p>
<p>By accessing this website and all its subdomains (the
  “Site”, “Website”) we assume you accept these terms and conditions in full. If you do not agree to these terms and
  conditions in their entirety, you are not authorized to use the Website in any manner or form whatsoever.</p>
<p>We respect your privacy and the use and protection of your non-public, personal information. Your submission of
  personal information through the Website is governed by
  our <a href="/privacy-policy" target="_blank">Privacy Policy</a>.
  Company reserves the right to modify its Privacy Policy in its reasonable discretion from
  time-to-time. Our Privacy Policy is incorporated into this Agreement by reference.</p>
<p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and any
  or all Agreements: “Client”, “You” and “Your” refers to you, the person accessing this website and accepting the
  Company’s terms and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our Company.
  “Party”, “Parties”, or “Us”, refers to both the Client and ourselves, or either the Client or ourselves. All
  terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our
  assistance to the Client in the most appropriate manner, whether by formal meetings of a fixed duration, or any
  other means, for the express purpose of meeting the Client’s needs in respect of provision of the Company’s
  stated services/products, in accordance with and subject to, prevailing law of United States. Any use of the
  above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as
  interchangeable and therefore as referring to same.</p>
<p><strong>Cookies</strong></p>
<p>We employ the use of cookies. By using Company’s website you consent to the use of cookies in accordance with
  Company’s privacy policy.</p>
<p>Most of the modern day interactive web sites use cookies to enable us to retrieve user details for each visit.
  Cookies are used in some areas of our site to enable the functionality of this area and ease of use for those
  people visiting. Some of our advertising partners may also use cookies.</p>
<p><strong>License</strong></p>
<p>Unless otherwise stated, the Company and/or its licensors own the intellectual property rights for all material
  on the Site. Intellectual property rights are reserved.</p>
<p>You must not republish, sell, rent, sub-license, reproduce, duplicate, or copy material, or redistribute content
  from the Site (unless content is specifically made for redistribution).</p>
<p><strong>User Comments</strong></p>
<p>Certain parts of this website offer the opportunity for users to post and exchange opinions, information,
  material and data (‘Comments’) in areas of the website. Company does not screen, edit, publish or review
  Comments prior to their appearance on the website and Comments do not reflect the views or opinions of Company,
  its agents or affiliates. Comments reflect the view and opinion of the person who posts such view or opinion. To
  the extent permitted by applicable laws Company&nbsp;shall not be responsible or liable for the Comments or for
  any loss cost, liability, damages or expenses caused and or suffered as a result of any use of and/or posting of
  and/or appearance of the Comments on this website.</p>
<p>Company reserves the right to monitor all Comments and to remove any Comments which it considers in its absolute
  discretion to be inappropriate, offensive or otherwise in breach of these Terms and Conditions.</p>
<p>You warrant and represent that:</p>
  <ul>
  <li>You are entitled to post the Comments on our website and have all necessary licenses and consents to do
    so;
  </li>
  <li>The Comments do not infringe any intellectual property right, including without limitation copyright, patent
    or trademark, or other proprietary right of any third party;
  </li>
  <li>The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material or
    material which is an invasion of privacy
  </li>
  <li>The Comments will not be used to solicit or promote business or custom or present commercial activities or
    unlawful activity.
  </li>
  <li>You hereby grant to Company a non-exclusive royalty-free license to use, reproduce, edit and authorize
    others to use, reproduce and edit any of your Comments in any and all forms, formats or media.
  </li>
  </ul>
<p><strong>Hyperlinking to our Content</strong></p>
<p>The following organizations may link to our Web site without prior written approval:</p>
  <ul>
  <li>Government agencies;</li>
  <li>Search engines;</li>
  <li>News organizations;</li>
  <li>Online directory distributors when they list us in the directory may link to our Web site in the same manner
    as they hyperlink to the Web sites of other listed businesses; and
  </li>
  <li>Systemwide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and
    charity fundraising groups which may not hyperlink to our Web site.
  </li>
  </ul>
<p>These organizations may link to our home page, to publications or to other Web site information so long as the
  link: (a) is not in any way misleading; (b) does not falsely imply sponsorship, endorsement or approval of the
  linking party and its products or services; and (c) fits within the context of the linking party’s site.</p>
<p>We may consider and approve in our sole discretion other link requests from the following types of
  organizations:</p>
  <ul>
  <li>commonly-known consumer and/or business information sources such as Chambers of Commerce, American
    Automobile Association, AARP and Consumers Union;
  </li>
  <li>dot.com community sites;</li>
  <li>associations or other groups representing charities, including charity giving sites,</li>
  <li>online directory distributors;</li>
  <li>internet portals;</li>
  <li>accounting, law and consulting firms whose primary clients are businesses; and</li>
  <li>educational institutions and trade associations.</li>
  </ul>
<p>We will approve link requests from these organizations if we determine that: (a) the link would not reflect
  unfavorably on us or our accredited businesses (for example, trade associations or other organizations
  representing inherently suspect types of business, such as work-at-home opportunities, shall not be allowed to
  link); (b)the organization does not have an unsatisfactory record with us; (c) the benefit to us from the
  visibility associated with the hyperlink outweighs the absence of Company; and (d) where the link is in the
  context of general resource information or is otherwise consistent with editorial content in a newsletter or
  similar product furthering the mission of the organization.</p>
<p>These organizations may link to our home page, to publications or to other Web site information so long as the
  link: (a) is not in any way misleading; (b) does not falsely imply sponsorship, endorsement or approval of the
  linking party and it products or services; and (c) fits within the context of the linking party’s site.</p>
<p>If you are among the organizations listed in paragraphs above and are interested in linking to our website, you
  must notify us by sending an email.&nbsp;Please include your name, your organization name, contact information
  (such as a phone number and/or e-mail address) as well as the URL of your site, a list of any URLs from which
  you intend to link to our Web site, and a list of the URL(s) on our site to which you would like to link. Allow
  2-3 weeks for a response.</p>
<p>Approved organizations may hyperlink to our Web site as follows:</p>
  <ul>
  <li>By use of our corporate name; or</li>
  <li>By use of the uniform resource locator (Web address) being linked to; or</li>
  <li>By use of any other description of our Web site or material being linked to that makes sense within the
    context and format of content on the linking party’s site.
  </li>
  </ul>
<p>No use of (cname)’s logo or other artwork will be allowed for linking absent a trademark license agreement.</p>
<p><strong>Iframes</strong></p>
<p>Without prior approval and express written permission, you may not create frames around our Web pages or use
  other techniques that alter in any way the visual presentation or appearance of our Web site.</p>
<p><strong>Content Liability</strong></p>
<p>We shall have no responsibility or liability for any content appearing on your Web site. You agree to indemnify
  and defend us against all claims arising out of or based upon your Website. No link(s) may appear on any page on
  your Web site or within any context containing content or materials that may be interpreted as libelous, obscene
  or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any
  third party rights.</p>
<p><strong>Reservation of Rights</strong></p>
<p>We reserve the right at any time and in its sole discretion to request that you remove all links or any
  particular link to our Web site. You agree to immediately remove all links to our Web site upon such request. We
  also reserve the right to amend these terms and conditions and its linking policy at any time. By continuing to
  link to our Web site, you agree to be bound to and abide by these linking terms and conditions.</p>
<p>If you send comments or suggestions about the Site to the Company, including, but not limited to, notes, text,
  drawings, images, designs or computer programs, such submissions shall become, and shall remain, the sole
  property of the Company. No submission shall be subject to any obligation of confidence on the part of the
  Company. The Company shall exclusively own all rights to (including intellectual property rights thereto), and
  shall be entitled to unrestricted use, publication, and dissemination as to all such submissions for any
  purpose, commercial or otherwise without any acknowledgment or compensation to you.</p>
<p><strong>Content and Behavior Rules</strong></p>
<p>You may not access or use the Services or create an account for unlawful purposes. Your use of the Services and
  behavior on our platform must comply with applicable local or national laws or regulations of the United States.
  You are solely responsible for the knowledge of and compliance with such laws and regulations that are
  applicable to you. You may not access our Services if you are from a territory where U.S. businesses are
  prohibited from engaging in business or if you have been designated a Specially Designated National, Denied
  Person, or Denied Entity by the U.S. government.</p>
<p>Company has discretion in enforcing the Safety Guidelines. We may terminate or suspend your permission to use our
  platform and Services or ban your account at any time, with or without notice, for any violation of these Terms,
  if you fail to pay any fees when due, upon the request of law enforcement or government agencies, for extended
  periods of inactivity, for unexpected technical issues or problems, or if we suspect that you engage in
  fraudulent or illegal activities. Upon any such termination, we may delete your account and content, and we may
  prevent you from further access to the platforms and use of our Services. You agree that we will have no
  liability to you or any third party for termination of your account, removal of your content, or blocking of
  your access to our platforms and services.</p>
<p><strong>Testimonials, Reviews, And Pictures/videos</strong></p>
<p>Company is pleased to hear from users and customers and welcomes your comments regarding our services and
  products. Company may use testimonials and/or product reviews in whole or in part together with the name, city,
  and state of the person submitting it. Testimonials may be used for any form of activity relating to Company’
  services or products, in printed and online media, as Company determines in its sole and exclusive discretion.
  Testimonials represent the unique experience of the participants and customers submitting the testimonial, and
  do not necessarily reflect the experience that you and your business may have using our services or products. As
  set forth above in Section 11, your business’ results will vary depending upon a variety of factors unique to
  your business and market forces beyond Company’ control. Note that testimonials, photographs, and other
  information that you provide to us will be treated as non-confidential and nonproprietary, and, by providing
  them, you grant Company a royalty-free, worldwide, perpetual, non-exclusive and irrevocable license to use
  them.</p>
<p>Additionally, Company reserves the right to correct grammatical and typing errors, to shorten testimonials prior
  to publication or use, and to review all testimonials prior to publication or use. Company shall be under no
  obligation to use any, or any part of, any testimonial or product review submitted.</p>
<p><strong>Permission to Contact</strong></p>
<p>By inputting your contact information into any form, including your telephone number, email address, or street address, you provide express written consent for the Company to send you future information, contact you, and monitor and record these communications to assure the quality of service, including for marketing purposes. This means we may contact you via email, postal mail, residential number, mobile number, and/or text messaging (including the use of an automatic telephone dialing system and pre-recorded calls) even if your name is on the Federal “Do-not-call List.” You also consent to receive any notices, announcements, agreements, disclosures, reports, documents, communications concerning new products or services, or other records or correspondence from the Company via email, even if you previously opted out of receiving marketing emails from the Company.</p>
<p>Without limiting anything in the Company’s Privacy Policy, you authorize the Company, and their affiliated service providers, to conduct all necessary research with your information, including checking your credit history, if applicable, for purposes of providing you the service you requested.</p>
<p>You may opt out of receiving calls and text messages from the Company at any time by taking any of the following actions: (1) sending an email expressly stating that you no longer wish to be contacted and are revoking your consent; (2) informing a Company customer service representative that you are revoking your consent to be contacted; or (3) replying to any SMS, mobile, or text message from the Company with “STOP” or “UNSUBSCRIBE.”</p>
<p><strong>Removal of links from our website</strong></p>
<p>If you find any link on our Web site or any linked web site objectionable for any reason, you may contact us
  about this. We will consider requests to remove links but will have no obligation to do so or to respond
  directly to you.</p>
<p>Whilst we endeavor to ensure that the information on this website is correct, we do not warrant its completeness
  or accuracy; nor do we commit to ensuring that the website remains available or that the material on the website
  is kept up to date.</p>
<p><strong>Disclaimer</strong></p>
<p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions
  relating to our website and the use of this website (including, without limitation, any warranties implied by
  law in respect of satisfactory quality, fitness for purpose and/or the use of reasonable care and skill).
  Nothing in this disclaimer will:</p>
  <ul>
  <li>limit or exclude your liability for death or personal injury resulting from negligence;</li>
  <li>limit or exclude your liability for fraud or fraudulent misrepresentation;</li>
  <li>limit any of your liabilities in any way that is not permitted under applicable law; or</li>
  <li>exclude any of your liabilities that may not be excluded under applicable law.</li>
  </ul>
<p>To the extent that the website and the information and services on the website are provided free of charge, we
  will not be liable for any loss or damage of any nature.</p>
<p>THE COMPANY SHALL NOT BE RESPONSIBLE FOR ANY PERFORMANCE OR SERVICE PROBLEMS CAUSED BY ANY THIRD PARTY WEBSITE OR
  THIRD PARTY SERVICE PROVIDER (INCLUDING, FOR EXAMPLE, VIDEO PLAYER SERVICEs, EMAIL SERVICES, CONTENT DELIVERY
  NETWORKS, DOCUMENT DISPLAY AND DELIVERY SERVICES, ELEARNING PLATFORMS, ONLINE FORMS SERVICES, SIGNATURE
  CAPTURING SERVICES, PAYMENT PROCESSING SERVICES, AND/OR ANY UPDATES OR UPGRADES TO THOSE PLATFORMS). ANY SUCH
  PROBLEM SHALL BE GOVERNED SOLELY BY THE AGREEMENT BETWEEN YOU AND THAT PROVIDER. THE COMPANY RESERVES THE RIGHT
  TO DETERMINE, IN ITS SOLE DISCRETION, WHETHER THE COMPANY IS RESPONSIBLE FOR ANY SUCH MALFUNCTION OR DISRUPTION.
  THE COMPANY ALSO RESERVES THE RIGHT TO LIMIT YOUR USE OF THE SITE AND/OR THE CONTENT OR TO TERMINATE YOUR
  ACCOUNT SHOULD THE COMPANY DETERMINE THAT YOU HAVE VIOLATED THESE TERMS OF USE, OR THAT YOU HAVE VIOLATED ANY
  OTHER RULES OR CONDITIONS OF THE COMPANY. THE COMPANY RESERVES THE RIGHT TO REFUSE ACCESS TO THE SITE AND/OR THE
  COMPANY’S CONTENT, PRODUCTS AND/OR SERVICES TO ANYONE IN ITS SOLE DISCRETION. THE COMPANY RESERVES THE RIGHT TO
  DETERMINE, IN ITS SOLE DISCRETION, WHETHER THE COMPANY IS RESPONSIBLE FOR ANY SUCH MALFUNCTION OR DISRUPTION.
  THE COMPANY MAY, IN ITS SOLE DISCRETION, REFUND THE INITIAL FEE CHARGED FOR ANY USE OF THE SITE AND/OR ANY
  CONTENT OR A PRO-RATA PORTION THEREOF CONSISTENT WITH THE COMPANY’S REFUND POLICY. THE COMPANY SHALL REFUSE ANY
  REFUND THIRTY (30) DAYS AFTER YOUR PAYMENT FOR USE OF THE SITE AND/OR ANY CONTENT, EITHER PURSUANT TO THE
  COMPANY’S CUSTOMER LICENSE AGREEMENT OR OTHERWISE, REGARDLESS OF THE REASON FOR DISRUPTION.</p>
<p>IN NO EVENT SHALL THE COMPANY BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT, PUNITIVE, RELIANCE OR
  CONSEQUENTIAL DAMAGES, WHETHER FORESEEABLE OR NOT, INCLUDING, BUT NOT LIMITED TO, DAMAGE OR LOSS OF PROPERTY,
  EQUIPMENT, INFORMATION OR DATA, LOSS OF PROFITS, REVENUE OR GOODWILL, COST OF CAPITAL, COST OF REPLACEMENT
  SERVICES, OR CLAIMS FOR SERVICE INTERRUPTIONS OR TRANSMISSION PROBLEMS, OCCASIONED BY ANY DEFECT IN THE SITE,
  THE CONTENT, AND/OR RELATED MATERIALS, THE INABILITY TO USE SERVICES PROVIDED HEREUNDER OR ANY OTHER CAUSE
  WHATSOEVER WITH RESPECT THERETO, REGARDLESS OF THEORY OF LIABILITY. THIS LIMITATION WILL APPLY EVEN IF THE
  COMPANY HAS BEEN ADVISED OR IS AWARE OF THE POSSIBILITY OF SUCH DAMAGES.</p>
<p><strong>No Waiver</strong></p>
<p>No failure or delay on the part of Company in exercising any right, power, or remedy under this Agreement may
  operate as a waiver, nor may any single or partial exercise of any such right, power, or remedy preclude any
  other or further exercise of such right, power, or remedy, or the exercise of any other rights, power, or remedy
  under this Agreement. A waiver of any right or obligation under this Agreement shall only be effective if in
  writing and signed by Company.</p>
<p><strong>Jurisdiction</strong></p>
<p>This agreement shall be governed by the laws of the state where the company is incorporated without regard to principles of conflict of laws. Any legal claim arising from this agreement shall be brought and heard in a court of that state.</p>
<p><strong>Changes</strong></p>
<p>These Terms of Use may be revised from time to time by updating this posting. You are bound by any such revisions
  and should therefore periodically visit this page to review the then current Terms of Use to which you are
  bound.</p>`;
