import type { FunctionComponent } from 'react';
import { Fragment } from 'react';

export const DefaultPrivacyPolicy: FunctionComponent<{ orgLegalName: string }> = ({
  orgLegalName,
}) => (
  <Fragment>
    <p>
      {orgLegalName} (the “Company”) respects the privacy concerns of the users of its website and
      the services provided therein (the “Site”).
    </p>
    <p>
      The Company thus provides this privacy statement to better serve those who are concerned with
      how their ‘Personally Identifiable Information’ (PII) is being used online. PII, as described
      in US privacy law and information security, is information that can be used on its own or with
      other information to identify, contact, or locate a single person, or to identify an
      individual in context. Please read our privacy policy carefully to get a clear understanding
      of how we collect, use, protect or otherwise handle your Personally Identifiable Information
      in accordance with our website.
    </p>
    <p>
      <strong>
        What personal information do we collect from the people that visit our blog, website or
        apps?
      </strong>
    </p>
    <p>
      When ordering or registering on our site, as appropriate, you may be asked to enter your name,
      email address, mailing address, phone number, credit card information, Team Member Name or
      other details to help you with your experience.
    </p>
    <p>
      <strong>When do we collect information?</strong>
    </p>
    <p>
      We collect information from you when you register on our site, place an order, subscribe to a
      newsletter, respond to a survey, fill out a form, Use Live Chat, Open a Support Ticket, or
      enter information on our site.
    </p>
    <p>
      <strong>How do we use your information?</strong>
    </p>
    <p>
      As a general policy, no personally identifiable information, such as your name, address, or
      e-mail address, is automatically collected from your visit to the Site. However, certain
      non-personal information is recorded by the standard operation of the Company’s internet
      servers.
    </p>
    <p>
      We may use the information we collect from you when you register, make a purchase, sign up for
      our newsletter, respond to a survey or marketing communication, surf the website, or use
      certain other site features in the following ways:
    </p>
    <p>
      • To personalize your experience and to allow us to deliver the type of content and product
      offerings in which you are most interested.
    </p>
    <p> • To improve our website in order to better serve you.</p>
    <p> • To allow us to better service you in responding to your customer service requests.</p>
    <p> • To administer a contest, promotion, survey or other site feature.</p>
    <p> • To quickly process your transactions.</p>
    <p> • To ask for ratings and reviews of services or products</p>
    <p> • To follow up with them after correspondence (live chat, email or phone inquiries)</p>
    <p>
      <strong>How do we protect your information?</strong>
    </p>
    <p>
      Our website is scanned on a regular basis for security holes and known vulnerabilities in
      order to make your visit to our site as safe as possible.
    </p>
    <p>
      Neither the Company nor the Site knowingly permit the use of malware, spyware, viruses, and/or
      other similar types of software.
    </p>
    <p>
      Your personal information is contained behind secured networks and is only accessible by a
      limited number of persons who have special access rights to such systems, and are required to
      keep the information confidential. In addition, all sensitive/credit information you supply is
      encrypted via Secure Socket Layer (SSL) technology.
    </p>
    <p>
      We implement a variety of security measures when a user places an order enters, submits, or
      accesses their information to maintain the safety of your personal information.
    </p>
    <p>
      All transactions are processed through a gateway provider and are not stored or processed on
      our servers.
    </p>
    <p>
      <strong>Do we use ‘cookies’?</strong>
    </p>
    <p>
      Yes. Cookies are small files that a site or its service provider transfers to your computer’s
      hard drive through your Web browser (if you allow) that enables the site’s or service
      provider’s systems to recognize your browser and capture and remember certain information. For
      instance, we use cookies to help us remember and process the items in your shopping cart. They
      are also used to help us understand your preferences based on previous or current site
      activity, which enables us to provide you with improved services. We also use cookies to help
      us compile aggregate data about site traffic and site interaction so that we can offer better
      site experiences and tools in the future.
    </p>
    <p>We use cookies to:</p>
    <p> • Help remember and process the items in the shopping cart.</p>
    <p> • Understand and save user’s preferences for future visits.</p>
    <p> • Keep track of advertisements.</p>
    <p>
      • Compile aggregate data about site traffic and site interactions in order to offer better
      site experiences and tools in the future. We may also use trusted third-party services that
      track this information on our behalf.
    </p>
    <p>
      You can choose to have your computer warn you each time a cookie is being sent, or you can
      choose to turn off all cookies. You do this through your browser settings. Since browser is a
      little different, look at your browser’s Help Menu to learn the correct way to modify your
      cookies.
    </p>
    <p>
      If you turn cookies off, some features will be disabled. It won’t affect the user’s experience
      that make your site experience more efficient and may not function properly. However, you will
      still be able to place orders.
    </p>
    <p>
      <strong>Third-party disclosure</strong>
    </p>
    <p>
      We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable
      Information unless we provide users with advance notice. This does not include website hosting
      partners and other parties who assist us in operating our website, conducting our business, or
      serving our users, so long as those parties agree to keep this information confidential. We
      may also release information when it’s release is appropriate to comply with the law, enforce
      our site policies, or protect ours or others’ rights, property or safety.
    </p>
    <p>
      However, non-personally identifiable visitor information may be provided to other parties for
      marketing, advertising, or other uses.
    </p>
    <p>
      <strong>Third-party links</strong>
    </p>
    <p>
      Occasionally, at our discretion, we may include or offer third-party products or services on
      our website. These third-party sites have separate and independent privacy policies. We
      therefore have no responsibility or liability for the content and activities of these linked
      sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback
      about these sites.
    </p>
    <p>
      <strong>Google</strong>
    </p>
    <p>
      Google’s advertising requirements can be summed up by Google’s Advertising Principles. They
      are put in place to provide a positive experience for users.
      https://support.google.com/adwordspolicy/answer/1316548?hl=en
    </p>
    <p>
      Google, as a third-party vendor, uses cookies to serve ads on our site. Google’s use of the
      DART cookie enables it to serve ads to our users based on previous visits to our site and
      other sites on the Internet. Users may opt-out of the use of the DART cookie by visiting the
      Google Ad and Content Network privacy policy.
    </p>
    <p>We have implemented the following:</p>
    <p> • Remarketing with Google AdSense</p>
    <p> • Google Display Network Impression Reporting</p>
    <p> • Demographics and Interests Reporting</p>
    <p>
      We, along with third-party vendors such as Google use first-party cookies (such as the Google
      Analytics cookies) and third-party cookies (such as the DoubleClick cookie) or other
      third-party identifiers together to compile data regarding user interactions with ad
      impressions and other ad service functions as they relate to our website.
    </p>
    <p>
      Opting out: Users can set preferences for how Google advertises to you using the Google Ad
      Settings page. Alternatively, you can opt out by visiting the Network Advertising Initiative
      Opt Out page or by using the Google Analytics Opt Out Browser add on.
    </p>
    <p>
      <strong>Bulletin Boards and Chat Areas</strong>
    </p>
    <p>
      Guests of the Site are solely responsible for the content of messages they post on the
      Company’s forums, such as chat rooms and bulletin boards. Users should be aware that when they
      voluntarily disclose personal information (e.g., user name, e-mail address, phone number) on
      the bulletin boards or in the chat areas, that information can be collected and used by others
      and may result in unsolicited messages from other people. You are responsible for the personal
      information you choose to submit in these instances. Please take care when using these
      features. Choice/Opt-Out: The Site may provide you the opportunity to opt-in to receive
      communications from us at the point where we request information about you. You always have
      the option of removing your name from any e-mail list in order to discontinue any such future
      communications. In order to ensure immediate removal from any list, please follow the specific
      instructions set forth within the communications you receive from the Company which you no
      longer wish to receive. If you are unsuccessful in completing the instructions specified in
      any such communication, please e-mail us at [INSERT LEGAL EMAIL ADDRESS], including a copy of
      the undesired email attached to the request, and state you wish to be removed from the mailing
      list.
    </p>
    <p>
      <strong>California Online Privacy Protection Act</strong>
    </p>
    <p>
      CalOPPA is the first state law in the nation to require commercial websites and online
      services to post a privacy policy. The law’s reach stretches well beyond California to require
      any person or company in the United States (and conceivably the world) that operates websites
      collecting Personally Identifiable Information from California consumers to post a conspicuous
      privacy policy on its website stating exactly the information being collected and those
      individuals or companies with whom it is being shared. – See more at:
      http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
    </p>
    <p>According to CalOPPA, we agree to the following:</p>
    <p>Users can visit our site anonymously.</p>
    <p>
      Once this privacy policy is created, we will add a link to it on our home page or as a
      minimum, on the first significant page after entering our website.
    </p>
    <p>
      Our Privacy Policy link includes the word ‘Privacy’ and can be easily be found on the page
      specified above.
    </p>
    <p>You will be notified of any Privacy Policy changes:</p>
    <p> • On our Privacy Policy Page</p>
    <p>Can change your personal information by one of the following:</p>
    <p> • By emailing us</p>
    <p> • By calling us</p>
    <p> • By logging in to your account</p>
    <p> • By chatting with us or by sending us a support ticket</p>
    <p>
      <strong>How does our site handle Do Not Track signals?</strong>
    </p>
    <p>
      We honor Do Not Track signals and Do Not Track, plant cookies, or use advertising when a Do
      Not Track (DNT) browser mechanism is in place.
    </p>
    <p>We allow third-party behavioral tracking.</p>
    <p>
      <strong>COPPA (Children Online Privacy Protection Act)</strong>
    </p>
    <p>
      When it comes to the collection of personal information from children under the age of 13
      years old, the Children’s Online Privacy Protection Act (COPPA) puts parents in control. The
      Federal Trade Commission, United States’ consumer protection agency, enforces the COPPA Rule,
      which spells out what operators of websites and online services must do to protect children’s
      privacy and safety online.
    </p>
    <p>We do not specifically market to children under the age of 13 years old.</p>
    <p>
      <strong>Fair Information Practices</strong>
    </p>
    <p>
      The Fair Information Practices Principles form the backbone of privacy law in the United
      States and the concepts they include have played a significant role in the development of data
      protection laws around the globe. Understanding the Fair Information Practice Principles and
      how they should be implemented is critical to comply with the various privacy laws that
      protect personal information.
    </p>
    <p>
      In order to be in line with Fair Information Practices we will take the following responsive
      action, should a data breach occur, we will notify you via email within 7 business days.
    </p>
    <p>
      We also agree to the Individual Redress Principle which requires that individuals have the
      right to legally pursue enforceable rights against data collectors and processors who fail to
      adhere to the law. This principle requires not only that individuals have enforceable rights
      against data users, but also that individuals have recourse to courts or government agencies
      to investigate and/or prosecute non-compliance by data processors.
    </p>
    <p>
      <strong>CAN-SPAM Act</strong>
    </p>
    <p>
      The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements
      for commercial messages, gives recipients the right to have emails stopped from being sent to
      them, and spells out tough penalties for violations.
    </p>
    <p>We collect your email address in order to:</p>
    <p>• Send information, respond to inquiries, and/or other requests or questions</p>
    <p>• Process orders and to send information and updates pertaining to orders.</p>
    <p>• Send you additional information related to your product and/or service</p>
    <p>
      • Market to our mailing list or continue to send emails to our clients after the original
      transaction has occurred.
    </p>
    <p>To be in accordance with CAN-SPAM, we agree to the following:</p>
    <p> • Not use false or misleading subjects or email addresses.</p>
    <p> • Identify the message as an advertisement in some reasonable way.</p>
    <p> • Include the physical address of our business or site headquarters.</p>
    <p> • Monitor third-party email marketing services for compliance, if one is used.</p>
    <p> • Honor opt-out/unsubscribe requests quickly.</p>
    <p> • Allow users to unsubscribe by using the link at the bottom of each email.</p>
    <p>
      If at any time you would like to unsubscribe from receiving future emails, you can follow the
      instructions at the bottom of each email and we will promptly remove you from all
      correspondence.
    </p>
    <p>
      <strong>Permission to Contact</strong>
    </p>
    <p>
      By inputting your contact information into any form, including your telephone number, email
      address, or street address, you provide express written consent for the Company to send you
      future information, contact you, and monitor and record these communications to assure the
      quality of service, including for marketing purposes. This means we may contact you via email,
      postal mail, residential number, mobile number, and/or text messaging (including the use of an
      automatic telephone dialing system and pre-recorded calls) even if your name is on the Federal
      “Do-not-call List.” You also consent to receive any notices, announcements, agreements,
      disclosures, reports, documents, communications concerning new products or services, or other
      records or correspondence from the Company via email, even if you previously opted out of
      receiving marketing emails from the Company.
    </p>
    <p>
      Without limiting anything in the Company’s Privacy Policy, you authorize the Company, and
      their affiliated service providers, to conduct all necessary research with your information,
      including checking your credit history, if applicable, for purposes of providing you the
      service you requested.
    </p>
    <p>
      You may opt out of receiving calls and text messages from the Company at any time by taking
      any of the following actions: (1) sending an email expressly stating that you no longer wish
      to be contacted and are revoking your consent; (2) informing a Company customer service
      representative that you are revoking your consent to be contacted; or (3) replying to any SMS,
      mobile, or text message from the Company with “STOP” or “UNSUBSCRIBE.”
    </p>
    <p>
      <strong>Giveaway Eligibility</strong>
    </p>
    <p>
      Open only to persons who are 18 years of age or older. Employees of the company are not
      eligible to enter or win a prize. All applicable federal, state, and local laws and
      regulations apply. Void where prohibited or restricted by law. NO PURCHASE NECESSARY. A
      PURCHASE WILL NOT IMPROVE YOUR CHANCES OF WINNING.
    </p>
    <p>
      <strong>Contacting Us</strong>
    </p>
    <p>
      If there are any questions regarding this privacy policy, you may contact us using the contact
      form on this site.
    </p>
    <p>
      <strong>Your Acceptance of These Terms</strong>
    </p>
    <p>
      By using the Site, you accept the policies and restrictions set forth in this Online Privacy
      Policy. If you do not agree to this policy, please do not use the Site. This Online Privacy
      Policy may be revised from time to time by updating this posting. You are bound by any such
      revisions and should therefore periodically visit this page to review the then current online
      privacy policy to which you are bound.
    </p>
  </Fragment>
);
