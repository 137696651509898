import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import type { FunctionComponent } from 'react';
import {
  CurrentUserProvider,
  useCurrentUser,
} from '../../../contexts/CurrentUserContext/CurrentUserContext.tsx';
import { useDialog } from '../../../contexts/DialogContext/DialogContext.tsx';
import type { SiteContext } from '../../../contexts/SiteContext/SiteContext.tsx';
import { PageTemplatesView } from './PageTemplatesView.tsx';

type EmptyStateProps = {
  site: SiteContext;
  allowEdit: boolean;
};

export const EmptyState: FunctionComponent<EmptyStateProps> = ({ site, allowEdit }) => {
  const dialog = useDialog();
  const { user } = useCurrentUser();

  return (
    <div className="flex flex-column xl:flex-row align-items-center gap-3 md:gap-7">
      <img
        src="https://storage.googleapis.com/isl-client-assets/app-graphics/empty-state-landing-page.png"
        className="md:w-28rem w-15rem shadow-2 border-round"
        alt="Landing page example"
      />
      <div>
        <div className="font-medium text-color-body text-lg">
          You don’t have any landing pages activated yet
        </div>
        <ul className="max-w-30rem pl-4 mt-3 mb-1">
          <li className="mb-2">
            Create a landing page to give people a way to easily request an insurance quote from you
          </li>
          <li className="mb-2">
            Share your pages on social media and in your marketing to generate leads
          </li>
          <li>
            Utilize professional-looking landing pages quickly and easily, without the need for
            external help or expertise
          </li>
        </ul>
        {allowEdit && (
          <Button
            className="px-4 mt-3"
            label="Activate a landing page"
            icon={PrimeIcons.ANGLE_RIGHT}
            iconPos="right"
            onClick={() => {
              dialog({
                // The current user needs to be provided. A child component uses this.
                content: (
                  <CurrentUserProvider user={user} loadingUser={false}>
                    <PageTemplatesView site={site} />
                  </CurrentUserProvider>
                ),
                props: {
                  className: 'dialog-width-xl',
                  header: 'Activate landing pages',
                  contentClassName: 'p-0 overflow-y-auto',
                },
              });
            }}
          />
        )}
      </div>
    </div>
  );
};
