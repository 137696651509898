import type { ComponentWithChildren } from '@wirechunk/lib/mixer/types/categories.ts';
import type { FunctionComponent } from 'react';
import { Fragment } from 'react';
import { RenderComponent } from './RenderComponent.tsx';

export const RenderMixerChildren: FunctionComponent<Pick<ComponentWithChildren, 'children'>> = ({
  children,
}) => (
  <Fragment>{children?.map((child) => <RenderComponent key={child.id} {...child} />)}</Fragment>
);
