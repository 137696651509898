import type { ApolloQueryResult } from '@apollo/client';
import { useQuery } from '@apollo/client';
import type { PageSpecialPurpose } from '@wirechunk/lib/api.ts';
import type { ErrorHandler } from '../useErrorHandler.tsx';
import type {
  PagesBySpecialPurposeQuery,
  PagesBySpecialPurposeQueryVariables,
} from './queries.generated.ts';
import { PagesBySpecialPurposeDocument } from './queries.generated.ts';

type SpecialPages = {
  pages: PagesBySpecialPurposeQuery['site']['pages'] | null;
  loading: boolean;
  refetch: (
    variables?: PagesBySpecialPurposeQueryVariables,
  ) => Promise<ApolloQueryResult<PagesBySpecialPurposeQuery>>;
};

// Returns published pages with a specified PageSpecialPurpose.
export const useSpecialPages = (
  siteId: string,
  specialPurposes: PageSpecialPurpose[],
  onError: ErrorHandler['onError'],
): SpecialPages => {
  const { data, loading, refetch } = useQuery(PagesBySpecialPurposeDocument, {
    onError,
    variables: {
      siteId,
      specialPurposes,
    },
  });

  return {
    pages: data?.site.pages || null,
    loading,
    refetch,
  };
};
