import { clsx } from 'clsx';
import { PrimeIcons } from 'primereact/api';
import type { FunctionComponent, ReactNode } from 'react';
import styles from './Sidebar.module.css';

export type SidebarInner = (onHide: () => void) => ReactNode;

type SidebarProps = {
  className?: string;
  body: SidebarInner;
  footer?: SidebarInner | undefined;
  show: boolean;
  onHide: () => void;
};

export const Sidebar: FunctionComponent<SidebarProps> = ({
  className,
  body,
  footer,
  show,
  onHide,
}: SidebarProps) => (
  <div className={clsx(className, styles.sidebar, 'surface-ground', show && styles.sidebarVisible)}>
    <div className={styles.sidebarTop}>
      <button type="button" onClick={onHide} className={styles.closeButton}>
        <i className={PrimeIcons.TIMES} />
      </button>
    </div>
    <nav
      className={`${styles.wrapper} pt-3 pb-2 flex flex-column gap-1 flex-grow-1 overflow-y-auto`}
    >
      {body(onHide)}
    </nav>
    {footer?.(onHide)}
  </div>
);
