/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.ts';

export type CurrentSitePrivacyPolicyQueryVariables = Types.Exact<{ [key: string]: never }>;

export type CurrentSitePrivacyPolicyQuery = {
  currentSite: {
    __typename: 'Site';
    id: string;
    useCustomPrivacyPolicy: boolean;
    privacyPolicy?: { __typename: 'Delta'; delta: string } | null;
  };
};

export const CurrentSitePrivacyPolicyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CurrentSitePrivacyPolicy' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSite' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'privacyPolicy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'delta' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'useCustomPrivacyPolicy' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CurrentSitePrivacyPolicyQuery, CurrentSitePrivacyPolicyQueryVariables>;
