import type { Component } from '@wirechunk/lib/mixer/types/components.ts';
import type { ContextData } from '@wirechunk/schemas/context-data/context-data';
import { isEmpty } from 'lodash-es';
import { Button } from 'primereact/button';
import type { FormEventHandler, FunctionComponent, ReactElement } from 'react';
import { useCallback } from 'react';
import {
  InputDataContextProvider,
  useInputDataContextValue,
} from '../contexts/InputDataContext.tsx';
import { RenderComponentsStyled } from './RenderComponentsStyled.tsx';

type EditPropsProps = {
  setupComponents: Component[];
  existingProps?: ContextData | null;
  submitButtonLabel: string;
  cancelButton?: ReactElement;
  onSubmit: (props: ContextData) => void;
};

export const EditProps: FunctionComponent<EditPropsProps> = ({
  setupComponents,
  existingProps = {},
  submitButtonLabel,
  cancelButton,
  onSubmit,
}) => {
  const inputDataContextValue = useInputDataContextValue(existingProps ?? undefined);
  const onSubmitWrapped = useCallback<FormEventHandler>(
    (evt) => {
      evt.preventDefault();
      const validationErrors = inputDataContextValue.validate();
      if (isEmpty(validationErrors)) {
        onSubmit(inputDataContextValue.data.visible);
      }
    },
    [inputDataContextValue, onSubmit],
  );

  return (
    <form onSubmit={onSubmitWrapped}>
      <InputDataContextProvider value={inputDataContextValue}>
        <RenderComponentsStyled components={setupComponents} />
      </InputDataContextProvider>
      <div className="flex gap-3 justify-content-end mt-4">
        {cancelButton}
        <Button type="submit" label={submitButtonLabel} />
      </div>
    </form>
  );
};
