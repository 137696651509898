/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.ts';

export type CreateSiteMutationVariables = Types.Exact<{
  input: Types.CreateSiteInput;
}>;

export type CreateSiteMutation = {
  createSite:
    | { __typename: 'AuthorizationError'; message: string }
    | {
        __typename: 'CreateSiteSuccessResult';
        site: {
          __typename: 'Site';
          id: string;
          domain: string;
          name: string;
          agentFirstName?: string | null;
          agentLastName?: string | null;
          agencyPrimaryPhone?: string | null;
          agentPhotoUrl?: string | null;
          addressLine1?: string | null;
          addressLine2?: string | null;
          addressCity?: string | null;
          addressState?: string | null;
          addressZip?: string | null;
          googlePlaceId?: string | null;
          logoUrl?: string | null;
        };
      }
    | { __typename: 'GenericInternalError'; message: string }
    | { __typename: 'GenericUserError'; message: string };
};

export const CreateSiteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateSiteInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CreateSiteSuccessResult' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'site' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'SiteSetupProperties' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Error' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SiteSetupProperties' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Site' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentFirstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentLastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agencyPrimaryPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentPhotoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressCity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressZip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'googlePlaceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSiteMutation, CreateSiteMutationVariables>;
