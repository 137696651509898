import {
  dayOfMonthOptions,
  isValidDay,
  isValidMonth,
  isValidYear,
  monthOptions,
} from '@wirechunk/lib/dates.ts';
import type { WorkingPlainDate } from '@wirechunk/lib/plain-date-time.ts';
import { clsx } from 'clsx';
import { isNumber } from 'lodash-es';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import type { FunctionComponent } from 'react';

type DateInput3FieldsProps = {
  inputId?: string;
  value: WorkingPlainDate | null;
  monthPlaceholder?: string | undefined;
  dayPlaceholder?: string | undefined;
  yearPlaceholder?: string | undefined;
  valid?: boolean;
  showClear?: boolean;
  minDate?: Date | undefined;
  maxDate?: Date | undefined;
  onChange: (date: WorkingPlainDate) => void;
};

export const DateInput3Fields: FunctionComponent<DateInput3FieldsProps> = ({
  inputId,
  value,
  monthPlaceholder,
  dayPlaceholder,
  yearPlaceholder,
  valid = true,
  showClear = false,
  onChange,
}) => {
  return (
    <div className="flex gap-2 align-items-center">
      <Dropdown
        className={clsx('flex-grow-1', !valid && 'p-invalid')}
        inputId={inputId}
        options={monthOptions}
        value={value?.month ?? null}
        showClear={showClear}
        placeholder={monthPlaceholder || undefined}
        onChange={({ value: month }) => {
          onChange({
            year: value?.year ?? null,
            month: isNumber(month) && isValidMonth(month) ? month : null,
            day: value?.day ?? null,
          });
        }}
      />
      <Dropdown
        className={clsx('w-6rem', !valid && 'p-invalid')}
        value={value?.day ?? null}
        options={dayOfMonthOptions}
        placeholder={dayPlaceholder || undefined}
        onChange={({ value: day }) => {
          onChange({
            year: value?.year ?? null,
            month: value?.month ?? null,
            day: isNumber(day) && isValidDay(day) ? day : null,
          });
        }}
      />
      <InputNumber
        value={value?.year ?? null}
        inputClassName={clsx('w-4rem', !valid && 'p-invalid')}
        placeholder={yearPlaceholder || undefined}
        maxLength={4}
        format={false}
        onChange={({ value: year }) => {
          onChange({
            year: isNumber(year) && isValidYear(year) ? year : null,
            month: value?.month ?? null,
            day: value?.day ?? null,
          });
        }}
      />
    </div>
  );
};
