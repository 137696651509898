import type { StageBodyComponent } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';
import { useStageContext } from '../../../contexts/StageContext/StageContext.ts';
import { RenderComponentsStyled } from '../../RenderComponentsStyled.tsx';

export const StageBody: FunctionComponent<StageBodyComponent> = () => {
  const stageContext = useStageContext();

  if (stageContext) {
    return <RenderComponentsStyled components={stageContext.stageBlueprint.components} />;
  }

  return null;
};
